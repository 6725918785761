import styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const Card = styled(Grid)(() => ({
  // boxShadow:
  //   "0px 0px 15px 2px rgba(0,0,0,0.1), 0px 0px 1px 0px rgba(0,0,0,0.14)",
  borderRadius: "8px",
  border: "1px solid #aaa4",
  backgroundColor: "white",
}));
