import { BlobServiceClient } from "@azure/storage-blob";

// const sasToken = process.env.storagesastoken || ""; // Fill string with your SAS token
// const containerName = `containerName`;
// const storageAccountName =
//   process.env.storageresourcename || "storageAccountName"; // Fill string with your Storage resource name
const sasToken = process.env.REACT_APP_SAS_TOKEN;
const containerName = `meppdfs`;
const storageAccountName =
  process.env.storageresourcename || "ecodatabase"; // Fill string with your Storage resource name

// Feature flag - disable storage feature to app if not configured
export const isStorageConfigured = () => {
  return !(!storageAccountName || !sasToken);
};

const createBlobInContainer = async (containerClient, file) => {
  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(file.name);

  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { blobContentType: file.type } };

  // upload file
  return await blobClient.uploadBrowserData(file, options);
};

const uploadFileToBlob = async (file) => {
  if (!file) return [];

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
  // get Container - full public read access
  const containerClient = blobService.getContainerClient(containerName);

  // upload file
  return await createBlobInContainer(containerClient, file);
};

export default uploadFileToBlob;

export const getBlobLink = (blogaddress) => {
  return `${blogaddress}?${process.env.REACT_APP_READ_ONLY_SAS_TOKEN}`
}
