import React, { useRef } from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import {
  CheckBox,
  CheckBoxOutlineBlank,
} from "@mui/icons-material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

const Description = styled(Typography)({
  fontColor: "#444",
  fontSize: "14px",
  whiteSpace: "pre-line",
  textAlign: "start",
});

export const Question = (props) => {
  const { desc = ``, onChange, isChecked } = props;
  const prevVal = useRef();

  const setChecked = (_checked) => {
    console.log("checked", _checked);
    if (typeof onChange === "function" && prevVal.current !== _checked) {
      onChange(_checked);
    }
    prevVal.current = _checked;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
      <Description>{desc}</Description>
      <ButtonGroup
        key={isChecked}
        variant="contained"
        color="success"
        aria-label="outlined success button group"
        sx={{
          height: "40px",
          // margin: "0 1rem",
          "& button": {
            padding: "0.5rem 2rem",
          },
        }}
      >
        <Button
          color={"success"}
          endIcon={
            isChecked === true ? <CheckBox /> : <CheckBoxOutlineBlank />
          }
          onClick={() => setChecked(true)}
        >
          YES
        </Button>
        <Button
          color={"error"}
          endIcon={
            isChecked === false ? <CheckBox /> : <CheckBoxOutlineBlank />
          }
          onClick={() => setChecked(false)}
        >
          NO
        </Button>
      </ButtonGroup>
    </Box>
  );
};
