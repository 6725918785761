import React from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import FormControlLabel from '@mui/material/FormControlLabel';
const Description = styled(Typography)({
  fontColor: "#444",
  fontSize: "14px",
  fontWeight: '600',
  whiteSpace: "pre-line",
  textAlign: "start",
});

export const Question = (props) => {
  const { desc = ``, onChange, isChecked } = props;

  const handleChange = (event) => {
    const value = event.target.value === "true" ? true : event.target.value === "false" ? false : event.target.value
    if (typeof onChange === "function") {
      onChange(value);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginTop: '-0.5rem' }}>
      <Description>{desc}</Description>

      <RadioGroup
        key={isChecked}
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={isChecked}
        onChange={handleChange}
        sx={{ paddingLeft: '1rem' }}
      >
        <FormControlLabel value={true} control={<Radio />} label="Yes" />
        <FormControlLabel value={false} control={<Radio />} label="No" />
      </RadioGroup>
    </Box>
  );
};
