import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";

import Chip from "@mui/material/Chip";
import MUIAutocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const Description = styled(Typography)({
  fontColor: "#444",
  fontSize: "14px",
  whiteSpace: "pre-line",
  textAlign: "start",
});

export const Autocomplete = (props) => {
  const {
    desc = ``,
    onChange,
    options = [],
    selectedOptions = [],
    disabled,
    freeSolo,
    multiple,
    placeholder,
  } = props;

  const handleSelect = (selectedValues) => {
    if (typeof onChange === "function") {
      onChange(selectedValues);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Description>{desc}</Description>

      <MUIAutocomplete
        freeSolo={freeSolo}
        disabled={disabled}
        size={"small"}
        multiple={multiple}
        fullWidth
        options={options}
        getOptionLabel={(option) => option}
        onChange={
          (multiple || !freeSolo) && ((e, values) => handleSelect(values))
        }
        onInputChange={
          !multiple && freeSolo && ((e, value) => handleSelect(value))
        }
        value={selectedOptions}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={index}
              size={"small"}
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            size={"small"}
            placeholder={placeholder}
          />
        )}
      />
    </Box>
  );
};
export default Autocomplete;
