import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Card } from "../../Common/Card";
import {
  AIAMaterialsPledgeSurvey_EarlyDesign,
  AIAMaterialsPledgeSurvey_MidDesign,
  AIAMaterialsPledgeSurvey_LateDesignConstruction,
} from "../ProjectEditor.config";

import { Question } from "../../Common/Question/Question";
import { Question as QuestionV2 } from "../../Common/QuestionV2/Question";
import { Label } from "../../Common/Label/Label";
import { MultiSelect } from "../../Common/MultiSelect/MultiSelect";
import {
  FormContainer,
  FormRow,
  InputLabel,
  ProjectInfo,
} from "../Common/ProjectInfo";
import Autocomplete from "../../Common/Autocomplete";
import { CloudUpload, Info, InfoOutlined } from "@mui/icons-material";
import SectionNotes from "../../Common/SectionNotes/SectionNotes";
import { VisuallyHiddenInput } from "../MEP2040/MEP2040";
import uploadFileToBlob, { getBlobLink } from "../../../services/azureBlobService";

const EarlyDesign = (props) => {
  const { newProject, setNewProject, disableEditing } = props;

  const handleChange = (key, value) => {
    const otherFileds = {};
    if (key === "isProjectPursuingSustainabilityCertification" && !value) {
      newProject.projectPursuingSustainabilityCertification = null
    }
    setNewProject({
      ...newProject,
      ...otherFileds,
      [key]: value,
    });
  };

  return (
    <FormContainer
      container
      style={disableEditing ? { opacity: 0.4, pointerEvents: "none" } : {}}
    >
      {AIAMaterialsPledgeSurvey_EarlyDesign.map((config, index) => (
        <FormRow item xs={12} sm={config?.sm || 4} key={config.key + index}>
          <Box
            component={"span"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <InputLabel required={config.required} htmlFor={config.key}>
              {config.label}
            </InputLabel>
            {config.type === "question" ? (
              <QuestionV2
                isChecked={newProject[config.key]}
                desc={config.desc}
                onChange={(checked) => handleChange(config.key, checked)}
              />
            ) : config.type === "text" ? (
              <TextField
                sx={{ width: "100%" }}
                id={config.key}
                type={config.inputType || config.type}
                value={newProject[config.key]}
                onChange={(e) => handleChange(config.key, e.target.value)}
                placeholder={config.placeholder}
                required={true}
                size={"small"}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              />
            ) : config.type === "label" ? (
              <Label desc={config.desc} />
            ) : config.type === "select" ? (
              <Select
                sx={{ width: "100%", textAlign: "start" }}
                id={config.key}
                value={newProject[config.key]}
                key={newProject[config.key]}
                onChange={(e) => handleChange(config.key, e.target.value)}
                size={"small"}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              >
                {config?.options.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            ) : config.type === "autocomplete" ? (
              <Autocomplete
                placeholder={config.placeholder}
                freeSolo={config.freeSolo}
                multiple={config.multiple}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
                options={config.options}
                selectedOptions={
                  config.multiple
                    ? JSON.parse(newProject[config.key] || "[]")
                    : newProject[config.key] || ""
                }
                onChange={(selectedOptions) =>
                  handleChange(
                    config.key,
                    config.multiple
                      ? JSON.stringify(selectedOptions)
                      : selectedOptions
                  )
                }
              />
            ) : (
              <></>
            )}
          </Box>
        </FormRow>
      ))}
    </FormContainer>
  );
};

const MidDesign = (props) => {
  const { newProject, setNewProject, disableEditing } = props;

  const handleChange = (key, value) => {
    if (key === "willSalvagedReusedMaterialsInProject" && !value) {
      newProject.salvagedReusedMaterialsInProject = null
    }
    setNewProject({
      ...newProject,
      [key]: value,
    });
  };

  return (
    <FormContainer
      container
      style={disableEditing ? { opacity: 0.4, pointerEvents: "none" } : {}}
    >
      {AIAMaterialsPledgeSurvey_MidDesign.map((config, index) => (
        <FormRow item xs={12} sm={config?.sm || 4} key={config.key + index}>
          <Box
            component={"span"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: "1rem",
              ...config?.sx,
            }}
          >
            <InputLabel required={config.required} htmlFor={config.key}>
              {config.label}
            </InputLabel>
            {config.type === "question" ? (
              <QuestionV2
                isChecked={newProject[config.key]}
                desc={config.desc}
                onChange={(checked) => handleChange(config.key, checked)}
              />
            ) : config.type === "text" ? (
              <TextField
                sx={{ width: "100%" }}
                id={config.key}
                type={config.inputType || config.type}
                value={newProject[config.key]}
                onChange={(e) => handleChange(config.key, e.target.value)}
                placeholder={config.placeholder}
                required={true}
                size={"small"}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              />
            ) : config.type === "multiselect" ? (
              <MultiSelect
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
                options={config.options}
                selectedOptions={JSON.parse(newProject[config.key] || "[]")}
                onChange={(selectedOptions) =>
                  handleChange(config.key, JSON.stringify(selectedOptions))
                }
                yesOrNo={config.yesOrNo}
              />
            ) : config.type === "autocomplete" ? (
              <Autocomplete
                placeholder={config.placeholder}
                freeSolo={config.freeSolo}
                multiple={config.multiple}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
                options={config.options}
                selectedOptions={
                  config.multiple
                    ? JSON.parse(newProject[config.key] || "[]")
                    : newProject[config.key] || ""
                }
                onChange={(selectedOptions) =>
                  handleChange(
                    config.key,
                    config.multiple
                      ? JSON.stringify(selectedOptions)
                      : selectedOptions
                  )
                }
              />
            ) : config.type === "label" ? (
              <Label desc={config.desc} />
            ) : (
              <></>
            )}
          </Box>
        </FormRow>
      ))}
    </FormContainer>
  );
};

const LateDesign = (props) => {
  const { newProject, setNewProject, disableEditing } = props;

  const handleChange = (key, value) => {
    setNewProject({
      ...newProject,
      [key]: value,
    });
  };

  const handleUpload = (e, key) => {
    const file = e.target.files?.[0];

    if (file.name) uploadFileToBlob(file);
    handleChange(key, `https://ecodatabase.blob.core.windows.net/meppdfs/${file.name}`)
  };

  return (
    <FormContainer
      container
      style={disableEditing ? { opacity: 0.4, pointerEvents: "none" } : {}}
    >
      {AIAMaterialsPledgeSurvey_LateDesignConstruction.map((config, index) => (
        <FormRow item xs={12} sm={config?.sm || 4} key={config.key + index}>
          <Box
            component={"span"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: "1rem",
              ...config?.sx,
            }}
          >
            <InputLabel required={config.required} htmlFor={config.key}>
              {config.label}
            </InputLabel>
            {config.type === "question" ? (
              <QuestionV2
                isChecked={newProject[config.key]}
                desc={config.desc}
                onChange={(checked) => handleChange(config.key, checked)}
              />
            ) : config.type === "text" ? (
              <TextField
                sx={{ width: "100%" }}
                id={config.key}
                type={config.inputType || config.type}
                value={newProject[config.key]}
                onChange={(e) => handleChange(config.key, e.target.value)}
                placeholder={config.placeholder}
                required={true}
                size={"small"}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              />
            ) : config.type === "multiselect" ? (
              <MultiSelect
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
                options={config.options}
                selectedOptions={JSON.parse(newProject[config.key] || "[]")}
                onChange={(selectedOptions) =>
                  handleChange(config.key, JSON.stringify(selectedOptions))
                }
                yesOrNo={config.yesOrNo}
              />
            ) : config.type === "autocomplete" ? (
              <Autocomplete
                placeholder={config.placeholder}
                freeSolo={config.freeSolo}
                multiple={config.multiple}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
                options={config.options}
                selectedOptions={
                  config.multiple
                    ? JSON.parse(newProject[config.key] || "[]")
                    : newProject[config.key] || ""
                }
                onChange={(selectedOptions) =>
                  handleChange(
                    config.key,
                    config.multiple
                      ? JSON.stringify(selectedOptions)
                      : selectedOptions
                  )
                }
              />
            ) : config.type === "label" ? (
              <Label desc={config.desc} />
            ) : config.type === 'file' ? (
              <>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUpload />}
                  disabled={
                    (config.disableCondition &&
                      !!newProject[config.disableCondition[0]] ==
                      config.disableCondition[1])
                  }
                >
                  Upload file
                  <VisuallyHiddenInput type="file" onChange={(e) => handleUpload(e, config.key)} />
                </Button>
                {newProject[config.key] && (
                  <Link
                    href={getBlobLink(newProject[config.key])}
                    underline="hover"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {newProject[config.key].split("/").at(-1)}
                  </Link>
                )}
              </>
            ) : (
              <></>
            )}
          </Box>
        </FormRow>
      ))}
    </FormContainer>
  );
};

const COMP_MAPPING = {
  projectInfo: ProjectInfo,
  earlyDesign: EarlyDesign,
  midDesign: MidDesign,
  lateDesign: LateDesign,
};

const AIAMaterialsPledgeSurvey = (props) => {
  const {
    subSections,
    newProject,
    setNewProject,
    optns,
    projectsMetaData,
    isNewProject,
    projectInfo,
    setProjectInfo,
    disabledEditing,
  } = props;

  const [expandedSections, setExpandedSections] = useState({});

  const handleExpand = (section) => {
    setExpandedSections((_expandedSections) => ({
      ..._expandedSections,
      [section.type]: !_expandedSections[section.type],
    }));
  };

  return subSections?.map((section) => {
    const SubSection = COMP_MAPPING[section.type];
    return (
      <Card sx={{ mt: 2 }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "1rem",
              backgroundColor: "#fff5",
              width: "100%",
            }}
          >
            <Box
              component={Typography}
              variant="h6"
              sx={{ fontFamily: "Albert sans" }}
            >
              {section?.label}
              <IconButton
                color={"primary"}
                onClick={() => handleExpand(section)}
                disabled={!section.notes}
              >
                {expandedSections[section.type] ? <Info /> : <InfoOutlined />}
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Divider />
        <SectionNotes
          notes={section.notes}
          display={expandedSections[section.type]}
        />

        <Box>
          <SubSection
            projectInfo={projectInfo}
            setProjectInfo={setProjectInfo}
            newProject={newProject}
            setNewProject={setNewProject}
            optns={optns}
            projectsMetaData={projectsMetaData}
            disableEditing={disabledEditing || (section.type !== 'projectInfo' && isNewProject)}
          />
        </Box>
      </Card>
    );
  });
};

export default AIAMaterialsPledgeSurvey;
