import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Card } from "../../Common/Card";
import {
  projectEligibilityFileds,
  energyModelingFields,
  embodiedCarbonFields,
  projectPhaseFileds,
} from "../ProjectEditor.config";

import { Question } from "../../Common/Question/Question";
import { Question as QuestionV2 } from "../../Common/QuestionV2/Question";
import { Label } from "../../Common/Label/Label";
import { MultiSelect } from "../../Common/MultiSelect/MultiSelect";
import {
  FormContainer,
  FormRow,
  InputLabel,
  ProjectInfo,
} from "../Common/ProjectInfo";
import Autocomplete from "../../Common/Autocomplete";
import { ExpandCircleDown, Info, InfoOutlined } from "@mui/icons-material";
import SectionNotes from "../../Common/SectionNotes/SectionNotes";

export const ProjectPhase = (props) => {
  const { newProject, setNewProject, disableEditing } = props;

  const handleChange = (key, value) => {
    const otherFileds = {};

    setNewProject({
      ...newProject,
      ...otherFileds,
      [key]: value,
    });
  };

  return (
    <FormContainer
      container
      style={disableEditing ? { opacity: 0.4, pointerEvents: "none" } : {}}
    >
      {projectPhaseFileds.map((config, index) => (
        <FormRow item xs={12} sm={config?.sm || 4} key={config.key + index}>
          <Box
            component={"span"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <InputLabel required={config.required} htmlFor={config.key}>
              {config.label}
            </InputLabel>
            {config.type === "select" ? (
              <Select
                sx={{ width: "100%", textAlign: "start" }}
                id={config.key}
                value={newProject[config.key]}
                key={newProject[config.key]}
                onChange={(e) => handleChange(config.key, e.target.value)}
                size={"small"}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              >
                {config?.options.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            ) : config.type === "text" ? (
              <TextField
                sx={{ width: "100%" }}
                id={config.key}
                type={config.inputType || config.type}
                value={newProject[config.key]}
                onChange={(e) => handleChange(config.key, e.target.value)}
                placeholder={config.placeholder}
                required={true}
                size={"small"}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              />
            ) : (
              <></>
            )}
          </Box>
        </FormRow>
      ))}
    </FormContainer>
  );

}

const ProjectEligibility = (props) => {
  const { newProject, setNewProject, disableEditing } = props;

  const handleChange = (key, value) => {
    const otherFileds = {};
    if (key.includes("useType") && key.includes("Area")) {
      otherFileds.useTypeTotalArea = Object.values({
        useType1PrimaryArea: newProject.useType1PrimaryArea,
        useType2SecondaryArea: newProject.useType2SecondaryArea,
        useType3TertiaryArea: newProject.useType3TertiaryArea,
        useType4TertiaryArea: newProject.useType4TertiaryArea,
        useType5TertiaryArea: newProject.useType5TertiaryArea,
        [key]: value
      }).filter(Boolean).map(Number).reduce((partialSum, a) => partialSum + a, 0)
    }
    setNewProject({
      ...newProject,
      ...otherFileds,
      [key]: value,
    });
  };

  return (
    <FormContainer
      container
      style={disableEditing ? { opacity: 0.4, pointerEvents: "none" } : {}}
    >
      {projectEligibilityFileds.map((config, index) => (
        <FormRow item xs={12} sm={config?.sm || 4} key={config.key + index}>
          <Box
            component={"span"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start", ...(config?.sx || {})
            }}
          >
            <InputLabel required={config.required} htmlFor={config.key}>
              {config.label}
            </InputLabel>
            {config.type === "question" ? (
              <QuestionV2
                isChecked={newProject[config.key]}
                desc={config.desc}
                onChange={(checked) => handleChange(config.key, checked)}
              />
            ) : config.type === "text" ? (
              <TextField
                sx={{ width: "100%" }}
                id={config.key}
                type={config.inputType || config.type}
                value={newProject[config.key]}
                onChange={(e) => handleChange(config.key, e.target.value)}
                placeholder={config.placeholder}
                required={true}
                size={"small"}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              />
            ) : config.type === "label" ? (
              <Label desc={config.desc} />
            ) : config.type === "select" ? (
              <Select
                sx={{ width: "100%", textAlign: "start" }}
                id={config.key}
                value={newProject[config.key]}
                key={newProject[config.key]}
                onChange={(e) => handleChange(config.key, e.target.value)}
                size={"small"}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              >
                {config?.options.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            ) : config.type === "autocomplete" ? (
              <Autocomplete
                placeholder={config.placeholder}
                freeSolo={config.freeSolo}
                multiple={config.multiple}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
                options={config.options}
                selectedOptions={
                  config.multiple
                    ? JSON.parse(newProject[config.key] || "[]")
                    : newProject[config.key] || ""
                }
                onChange={(selectedOptions) =>
                  handleChange(
                    config.key,
                    config.multiple
                      ? JSON.stringify(selectedOptions)
                      : selectedOptions
                  )
                }
              />
            ) : (
              <></>
            )}
          </Box>
        </FormRow>
      ))}
    </FormContainer>
  );
};

const EnergyModeling = (props) => {
  const { newProject, setNewProject, disableEditing } = props;

  const handleChange = (key, value) => {
    const otherFileds = {};
    if (key === "hasEnergyModeling" && !value) {
      energyModelingFields.forEach((field) => {
        if (field.disableCondition) {
          otherFileds[field.key] = "";
        }
      });
    }
    setNewProject({
      ...newProject,
      ...otherFileds,
      [key]: value,
    });
  };

  useEffect(() => {
    if (!newProject?.hasEnergyModeling) {
      handleChange("hasEnergyModeling", false);
    }
  }, []);

  return (
    <FormContainer
      container
      style={disableEditing ? { opacity: 0.4, pointerEvents: "none" } : {}}
    >
      {energyModelingFields.map((config, index) => (
        <FormRow item xs={12} sm={config?.sm || 4} key={config.key + index}>
          <Box
            component={"span"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              ...config?.sx,
            }}
          >
            <InputLabel required={config.required} htmlFor={config.key}>
              {config.label}
            </InputLabel>
            {config.type === "question" ? (
              <Question
                isChecked={newProject[config.key]}
                desc={config.desc}
                onChange={(checked) => handleChange(config.key, checked)}
              />
            ) : config.type === "text" ? (
              <TextField
                sx={{ width: "100%" }}
                id={config.key}
                type={config.inputType || config.type}
                value={newProject[config.key]}
                onChange={(e) => handleChange(config.key, e.target.value)}
                placeholder={config.placeholder}
                required={true}
                size={"small"}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              />
            ) : config.type === "select" ? (
              <Select
                sx={{ width: "100%", textAlign: "start" }}
                id={config.key}
                key={newProject[config.key]}
                value={newProject[config.key]}
                onChange={(e) => handleChange(config.key, e.target.value)}
                size={"small"}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              >
                {config?.options.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            ) : config.type === "label" ? (
              <Label desc={config.desc} />
            ) : config.type === "autocomplete" ? (
              <Autocomplete
                placeholder={config.placeholder}
                freeSolo={config.freeSolo}
                multiple={config.multiple}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
                options={config.options}
                selectedOptions={
                  config.multiple
                    ? JSON.parse(newProject[config.key] || "[]")
                    : newProject[config.key] || ""
                }
                onChange={(selectedOptions) =>
                  handleChange(
                    config.key,
                    config.multiple
                      ? JSON.stringify(selectedOptions)
                      : selectedOptions
                  )
                }
              />
            ) : (
              <></>
            )}
          </Box>
        </FormRow>
      ))}
    </FormContainer>
  );
};

const EmbodiedCarbonModeling = (props) => {
  const { newProject, setNewProject, disableEditing } = props;

  const handleChange = (key, value) => {
    const otherFileds = {};
    if (key === "hasEmbodiedCarbonModeling" && !value) {
      embodiedCarbonFields.forEach((field) => {
        otherFileds[field.key] = null;
      });
    }
    setNewProject({
      ...newProject,
      ...otherFileds,
      [key]: value,
    });
  };

  useEffect(() => {
    if (!newProject?.hasEmbodiedCarbonModeling) {
      handleChange("hasEmbodiedCarbonModeling", false);
    }
  }, []);

  return (
    <FormContainer
      container
      style={disableEditing ? { opacity: 0.4, pointerEvents: "none" } : {}}
    >
      {embodiedCarbonFields.map((config, index) => (
        <FormRow item xs={12} sm={config?.sm || 4} key={config.key + index}>
          <Box
            component={"span"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              ...config?.sx,
            }}
          >
            <InputLabel required={config.required} htmlFor={config.key}>
              {config.label}
            </InputLabel>
            {config.type === "question" ? (
              <Question
                isChecked={newProject[config.key]}
                desc={config.desc}
                onChange={(checked) => handleChange(config.key, checked)}
              />
            ) : config.type === "text" ? (
              <TextField
                sx={{ width: "100%" }}
                id={config.key}
                type={config.inputType || config.type}
                value={newProject[config.key] || ""}
                onChange={(e) => handleChange(config.key, e.target.value)}
                placeholder={config.placeholder}
                required={true}
                size={"small"}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              />
            ) : config.type === "select" ? (
              <Select
                sx={{ width: "100%", textAlign: "start" }}
                id={config.key}
                value={newProject[config.key]}
                key={newProject[config.key]}
                onChange={(e) => handleChange(config.key, e.target.value)}
                size={"small"}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              >
                {config?.options.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            ) : config.type === "multiselect" ? (
              <MultiSelect
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
                options={config.options}
                selectedOptions={(newProject[config.key] || "")
                  .split(",")
                  .filter(Boolean)}
                onChange={(selectedOptions) =>
                  handleChange(
                    config.key,
                    selectedOptions?.filter(Boolean).join(",")
                  )
                }
              />
            ) : config.type === "autocomplete" ? (
              <Autocomplete
                placeholder={config.placeholder}
                freeSolo={config.freeSolo}
                multiple={config.multiple}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
                options={config.options}
                selectedOptions={
                  config.multiple
                    ? JSON.parse(newProject[config.key] || "[]")
                    : newProject[config.key] || ""
                }
                onChange={(selectedOptions) =>
                  handleChange(
                    config.key,
                    config.multiple
                      ? JSON.stringify(selectedOptions)
                      : selectedOptions
                  )
                }
              />
            ) : (
              <></>
            )}
          </Box>
        </FormRow>
      ))}
    </FormContainer>
  );
};

const COMP_MAPPING = {
  projectInfo: ProjectInfo,
  projectPhase: ProjectPhase,
  projectEligibility: ProjectEligibility,
  energyModeling: EnergyModeling,
  embodiedCarbonModeling: EmbodiedCarbonModeling,
};

const AIA2030 = (props) => {
  const {
    subSections,
    newProject,
    setNewProject,
    optns,
    projectsMetaData,
    isNewProject,
    projectInfo,
    setProjectInfo,
    disabledEditing,
  } = props;
  const [expandedSections, setExpandedSections] = useState({});

  const handleExpand = (section) => {
    setExpandedSections((_expandedSections) => ({
      ..._expandedSections,
      [section.type]: !_expandedSections[section.type],
    }));
  };

  return subSections?.map((section) => {
    const SubSection = COMP_MAPPING[section.type];
    return (
      <Card sx={{
        mt: 2,
        position: section.type === 'projectPhase' && 'sticky',
        top: section.type === 'projectPhase' && '50px',
        zIndex: section.type === 'projectPhase' && 1,
      }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "1rem",
              backgroundColor: "#fff5",
              width: "100%",
            }}
          >
            <Box
              component={Typography}
              variant="h6"
              sx={{ fontFamily: "Albert sans" }}
            >
              {section?.label}
              <IconButton
                color={"primary"}
                onClick={() => handleExpand(section)}
                disabled={!section.notes}
              >
                {expandedSections[section.type] ? <Info /> : <InfoOutlined />}
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Divider />
        <SectionNotes
          notes={section.notes}
          display={expandedSections[section.type]}
        />
        <Box>
          <SubSection
            projectInfo={projectInfo}
            setProjectInfo={setProjectInfo}
            newProject={newProject}
            setNewProject={setNewProject}
            optns={optns}
            projectsMetaData={projectsMetaData}
            disableEditing={disabledEditing || (section.type !== 'projectInfo' && isNewProject)}
          />
        </Box>
      </Card>
    );
  });
};

export default AIA2030;
