import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./components/Login/Login";
import React, { useEffect, useState } from "react";
import Admin from "./components/Admin/Admin";
import EditProject from "./components/EditProject/EditProject";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from "@azure/msal-react";

import AuthHandler from "./components/Auth";
// import AuthHandler from './components/Auth'

import api from "./api/api";
import jwt_decode from "jwt-decode";
import { RolesContext, useRoles } from "./components/Common/Utils";

function App() {
  const userRoles = useRoles();
  const isAdmin = userRoles?.some((role) => role.roleName === "Admin");
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} exact />
        <Route path="/auth" element={<UnauthenticatedTemplate><AuthHandler /></UnauthenticatedTemplate>} exact />
        <Route path="/dashboard" element={<Dashboard />} exact />
        {isAdmin && <Route path="/admin" element={<Admin />} exact />}
        <Route path="/dashboard/project" element={<EditProject />} exact />
      </Routes>
    </div>
  );
}

export default function MainApp() {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const navigate = useNavigate();
  const [userRoles, setUserRoles] = useState([]);

  const getUserRoles = async () => {
    const token = JSON.parse(localStorage.getItem("session") || "{}")?.token;
    if (token) {
      const decodedToken = jwt_decode(token);
      const res = await api.get("/getUserRoles", {
        params: {
          email: decodedToken.unique_name,
          displayName: decodedToken.name,
          fname: decodedToken.given_name,
          lname: decodedToken.family_name,
        },
      });
      setUserRoles(JSON.parse(res.data.roles || "[]"));
      if (location.pathname === "/") {
        navigate("/dashboard")

      }
    }
  };

  useEffect(() => {
    getUserRoles();
  }, []);

  return (
    <RolesContext.Provider value={{ userRoles, setUserRoles }}>
      <App />
    </RolesContext.Provider>
  );
}
