import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  Grid,
  TextField,
  TableRow,
  InputAdornment,
  Tooltip,
  Chip,
} from "@mui/material";
import { Edit, Search } from "@mui/icons-material";
import React, { useEffect, useMemo, useState } from "react";
import api from "../../api/api";
import UserRoleEditor from "../Common/UserRoleEditor";

const columns = [
  { key: "displayName", label: "Name" },
  { key: "email", label: "Email" },
  { key: "roles", label: "Assigned Roles" },
  { key: "action", label: "", width: "200px" },
];

const Roles = (props) => {
  const { roles } = props;
  const parsedRoles = useMemo(() => JSON.parse(roles || "[]"), [roles]);

  return (
    <>
      {parsedRoles.map((role, index) => (
        <Tooltip key={index} title={role?.description}>
          <Chip
            sx={{ marginRight: "0.5rem" }}
            size={"small"}
            label={role?.roleName}
          />
        </Tooltip>
      ))}
    </>
  );
};
const UserRoles = () => {
  const [isLoading, setIsLoading] = useState();
  const [rowsPerPage, setRowPerPage] = useState(10);
  const [page, setpage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [filteredUsersData, setFilteredUsersData] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [openEditor, setEditorOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState({});

  const getAllRoles = async () => {
    const res = await api.get("/getRoles");
    setAllRoles(res.data);
  };
  const getUsersRoles = async () => {
    const res = await api.get("/getUsers");
    setUsersData(res.data);
    setFilteredUsersData(res.data);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getAllRoles();
      await getUsersRoles();
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (searchText)
      setFilteredUsersData(
        usersData.filter((user) =>
          `${user.email}${user.displayName}${user.roles}`
            .toLowerCase()
            .includes(searchText)
        )
      );
    else setFilteredUsersData(usersData);
  }, [searchText, usersData]);

  const handleChangePage = (event, newpage) => {
    setpage(newpage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowPerPage(event.target.value);
    setpage(0);
  };

  const handleEditUserAccess = (user) => {
    setUserToEdit(user);
    setEditorOpen(true);
  };

  return (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          placeholder="Search users"
          size="small"
          id="user-search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value?.toLowerCase())}
          InputProps={{
            sx: { fontFamily: "Albert Sans" },
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Table stickyHeader aria-label="sticky table">
        {isLoading && (
          <Box
            sx={{
              width: "100%",
              display: "table-caption",
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem",
              boxSizing: "border-box",
            }}
          >
            <CircularProgress
              sx={{
                width: "2rem",
                height: "2rem",
                //   color: "white",
                mr: "0.5rem",
              }}
            />
          </Box>
        )}
        <TableHead sx={{ "& th": { fontWeight: "600" } }}>
          <TableRow>
            {columns.map((column) => (
              <TableCell sx={{ top: "58px" }}>{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredUsersData.map((row, index) => {
            return (
              <TableRow key={index}>
                {columns.map((column) => (
                  <TableCell key={column.key} width={column.width}>
                    {column.key === "roles" ? (
                      <Roles roles={row[column.key]} />
                    ) : column.key === "action" ? (
                      <Tooltip title="Edit user access">
                        <IconButton
                          variant="outlined"
                          aria-label="edit"
                          onClick={() => handleEditUserAccess(row)}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      row[column.key]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={usersData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <UserRoleEditor
        openModal={openEditor}
        setOpenModal={(display) => {
          if (display === false) {
            setEditorOpen(display);
            getUsersRoles();
            setUserToEdit({});
          }
        }}
        allRoles={allRoles}
        userToEdit={userToEdit}
      />
    </>
  );
};

export default UserRoles;
