import React, { useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Autocomplete,
    Box,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    CheckBox,
    CheckBoxOutlineBlank,
    CheckCircle,
    Edit,
    EditNoteRounded,
    EditRounded,
    Search,
} from "@mui/icons-material";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import OfflinePinIcon from "@mui/icons-material/OfflinePin";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

const Description = styled(Typography)({
    fontColor: "#444",
    fontSize: "16px",
    whiteSpace: "pre-line",
    textAlign: "start",
});

export const Label = (props) => {
    const { desc = ``, onChange, isChecked, additionalDesc } = props;
    const [checked, setChecked] = useState(isChecked);
    const prevVal = useRef();

    useEffect(() => {
        console.log("checked", checked);
        if (typeof onChange === "function" && prevVal.current !== checked) {
            onChange(checked);
        }
        prevVal.current = checked;
    }, [checked]);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: 'center' }}>
            <Description>{desc}</Description>
            {additionalDesc && <Description >{additionalDesc}</Description>}
        </Box>
    );
};
