import { CheckCircle, CheckCircleTwoTone, Contrast, Pending, PendingTwoTone } from "@mui/icons-material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

export const Progress = (props) => {
  const { progress, isDisabled } = props;
  if (progress === "Completed")
    return <CheckCircleTwoTone  sx={{ color: "#43a047" }} />;
  else if (progress === "In Progress")
    return (
      <PendingTwoTone
        sx={{
          color: isDisabled ? "lightgray" : "#1565c0",
        }}
      />
    );
  else
    return (
      <RadioButtonUncheckedIcon
        sx={{
          color: isDisabled ? "lightgray" : "grey",
        }}
      />
    );
};
