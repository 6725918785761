import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import api from "../../api/api";
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { EmailOutlined, LockOutlined } from "@mui/icons-material";
import { useMsal } from "@azure/msal-react";
import jwt_decode from "jwt-decode";

const Login = () => {
  const navigate = useNavigate();
  const [loginAs, setLoginAs] = useState("user");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { instance } = useMsal();

  const handleLogin = async () => {
    try {
      if (email.trim() !== "" && password.trim() !== "") {
        setIsLoading(true);
        const res = await api.post("/login", {
          email,
          password,
        });
        console.log("res", res);
        localStorage.setItem(
          "session",
          JSON.stringify({ token: res.data, loginAs })
        );
        navigate("/dashboard/");
      } else {
        setError("Fill all the mandatory fields!");
      }
    } catch (e) {
      console.log("Error while logging in", e);
      setIsLoading(false);
      if (e?.response?.status !== 200) {
        setError(e?.response?.data || "Please try again later");
      }
    }
  };

  // useEffect(() => {
  //   (async () => {
  //     const token = JSON.parse(localStorage.getItem("session")).token;
  //     const decodedToken = jwt_decode(token);
  //     const res = await api.get("/getUserRoles", {
  //       params: {
  //         email: decodedToken.unique_name,
  //         displayName: decodedToken.name,
  //         fname: decodedToken.given_name,
  //         lname: decodedToken.family_name,
  //       },
  //     });
  //     localStorage.setItem(
  //       "session",
  //       JSON.stringify({ token, userRoles: res.data })
  //     );
  //   })();
  // }, []);

  const handleSSOLogin = () => {
    localStorage.clear()
    instance.loginRedirect();
  };

  return (
    <>
      <Grid container sx={{ height: "100vh" }}>
        <Grid
          xl={8}
          md={7}
          xs={"0"}
          sx={{
            background:
              "linear-gradient(to top right, #009688 0%, #43a047 100%)",
            // "linear-gradient(to top right, #09203f 0%, #537895 100%)",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box
              sx={{
                marginRight: "-300px",
                marginTop: "-100px",
                boxShadow: "0 15px 25px rgba(0,0,0, 0.2)",
                height: "200px",
                width: "400px",
                borderRadius: "10px",
                backdropFilter: "blur(14px)",
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></Box>
            <Box
              sx={{
                marginRight: "-400px",
                marginTop: "100px",
                boxShadow: "0 15px 25px rgba(0,0,0, 0.2)",
                height: "200px",
                width: "400px",
                borderRadius: "10px",
                backdropFilter: "blur(14px)",
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontWeight: "700",
                fontFamily: "Montserrat",
                fontSize: "24px",
                userSelect: "none",
                letterSpacing: "1px",
              }}
            ></Box>
            <Box
              alt={"EwingCole Eco"}
              component={"img"}
              src={"/applogo.jpeg"}
              sx={{
                marginLeft: "-75px",
                width: "450px",
                fontFamily: "Montserrat",
                color: "white",
                backgroundColor: "white",
                padding: "2rem",
                borderRadius: "10px",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                userSelect: "none",
                letterSpacing: "1px",
                zIndex: "2",
              }}
            />
          </Box>
        </Grid>
        <Grid
          xl={4}
          md={5}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            "& p, h5, input": {
              fontFamily: "Albert Sans",
            },
            "& button": {
              fontFamily: "Montserrat",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              maxWidth: "300px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                width: "100%",
                marginBottom: "3rem",
              }}
            >
              <Typography variant="h5" textAlign={"start"} pb={1}>
                Welcome Back!
              </Typography>
              <Typography textAlign={"start"} color={"gray"}>
                Login to your account to get started.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                boxSizing: "border-box",
              }}
            >
              {/* <TextField
                variant="outlined"
                placeholder="Email"
                sx={{ marginBottom: "0.8rem", width: "300px" }}
                size={"small"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlined />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                variant="outlined"
                placeholder="Password"
                sx={{ marginBottom: "2rem", width: "300px" }}
                size={"small"}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleLogin();
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlined />
                    </InputAdornment>
                  ),
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  width: "300px",
                }}
              >
                <Button
                  disabled={isLoading}
                  fullWidth
                  size={"small"}
                  variant="contained"
                  className="loginButton"
                  onClick={handleLogin}
                >
                  {isLoading && (
                    <CircularProgress
                      size={"small"}
                      sx={{
                        width: "1rem",
                        height: "1rem",
                        color: "white",
                        mr: "0.5rem",
                      }}
                    />
                  )}
                  Log in
                </Button>
              </Box> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  width: "300px",
                }}
              >
                <Button
                  disabled={isLoading}
                  fullWidth
                  size={"small"}
                  variant="contained"
                  color="success"
                  className="loginButton"
                  onClick={handleSSOLogin}
                >
                  {isLoading && (
                    <CircularProgress
                      size={"small"}
                      sx={{
                        width: "1rem",
                        height: "1rem",
                        color: "white",
                        mr: "0.5rem",
                      }}
                    />
                  )}
                  Log in
                </Button>
              </Box>
              <Box sx={{ height: "3rem", width: "100%", mt: "1rem" }}>
                {error && <Typography color="error">{error}</Typography>}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
