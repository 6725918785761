export const projectFields = [
  {
    label: "Building Name",
    type: "text",
    placeholder: "provide unique Building Name within in the project",
    key: "name",
    required: true,
  },
  {
    label: "Sub Project Number",
    type: "text",
    key: "number",
  },
  {
    label: "Project Name",
    type: "text",
    key: "projectName",
    disabled: true,
  },
  {
    label: "Company Name",
    type: "text",
    key: "companyName",
    disabled: true,
  },
  {
    label: "Project Principal",
    type: "text",
    key: "projectPrincipal",
    disabled: true,
  },
  {
    label: "Market",
    type: "text",
    key: "projectMarket",
    disabled: true,
  },
  {
    label: "Office",
    type: "text",
    key: "officeLocation",
    disabled: true,
  },
  {
    label: "Street",
    type: "text",
    key: "street",
  },
  {
    label: "City",
    type: "text",
    key: "city",
  },
  {
    label: "State",
    type: "autocomplete",
    options: [
      "Alabama",
      "Alaska",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "Florida",
      "Georgia",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ],
    key: "state",
  },
  {
    label: "Country",
    type: "autocomplete",
    options: [
      "USA",
      "CANADA"
    ],
    key: "country",
  },
  {
    label: "Postal Code",
    type: "text",
    key: "postalCode",
  },
  {
    type: "gap",
    sm: 12,
  },
];

export const projectPhaseFileds = [
  {
    label: "Reporting Phase",
    type: "select",
    options: [
      {
        label: "Concept",
        value: "Concept",
      },
      {
        label: "Schematic Design",
        value: "Schematic Design",
      },
      {
        label: "Design Development",
        value: "Design Development",
      },
      {
        label: "Construction Documents",
        value: "Construction Documents",
      },
      {
        label: "Construction Administration",
        value: "Construction Administration",
      },
    ],
    key: "reportingPhase",
    sm: 4,
  },
  {
    label: "Reporting Year",
    type: "select",
    options: [
      { label: "2024", value: "2024" },
      { label: "2025", value: "2025" },
      { label: "2026", value: "2026" },
      { label: "2027", value: "2027" },
      { label: "2028", value: "2028" },
      { label: "2029", value: "2029" },
      { label: "2030", value: "2030" },
    ],
    sm: 4,
    key: "projectPhaseReportingYear",
  },
  {
    label: "Updated By",
    type: "text",
    key: "projectPhaseUpdatedBy",
    sm: 4,
  },
  {
    type: "gap",
    sm: 12,
  },
]
export const projectEligibilityFileds = [
  {
    label: "Project Eligibility",
    type: "label",
    key: "projectEligibility",
    desc: `Does the project meet the 3 following criteria?`,
    sm: 12,
  },

  {
    type: "question",
    key: "projectCompletedDesignPhase",
    desc: `1. Project completed a significant design phase (SD, DD, or CD) or was in CA in the current reporting year`,
    sm: 12,
  },
  {
    type: "question",
    key: "projectImpaactsEnergyUse",
    desc: `2. Project includes scope that impacts energy use (ie: HVAC, envelope, or lighting design)`,
    sm: 12,
  },
  {
    type: "question",
    key: "projectConsistsContigousBuildArea",
    desc: `3. Project consists of contiguous building area of at least 3,000 SF`,
    sm: 12,
  },
  {
    type: "question",
    key: "willThisProjectBeReported",
    desc: `4. Will this project be reported?`,
    sm: 12,
  },
  {
    label: "Project Type",
    type: "select",
    options: [
      {
        label: "Whole Building",
        value: "Whole Building",
      },
      {
        label: "Interior only",
        value: "Interior only",
      },
    ],
    key: "projectType",
    sm: 4,
  },
  {
    label: "Construction Type",
    type: "select",
    options: [
      {
        label: "New construction",
        value: "New construction",
      },
      {
        label: "Major renovation of an existing building",
        value: "Major renovation of an existing building",
      },
    ],
    key: "constructionType",
    sm: 4,
  },
  {
    label: "Use Type 1 -- Primary Use",
    type: "autocomplete",
    options: [
      "Bank/Financial Institution",
      "Courthouse",
      "Data Center",
      "Education - College/University (campus-level)",
      "Education - General",
      "Education - K-12 School",
      "Food Sales - Convenience Store (w/ or w/out gas station)",
      "Food Sales - General",
      "Food Sales - Supermarket/Grocery",
      "Food Service - Fast Food",
      "Food Service - General",
      "Food Service - Restaurant/Cafeteria",
      "Health Care - Clinic",
      "Health Care - Hospital Inpatient",
      "Health Care - Medical Office",
      "Health Care - Nursing/Assisted Living",
      "Health Care - Outpatient - General",
      "Laboratory",
      "Lodging - General",
      "Lodging - Hotel/Motel",
      "Lodging - Residence Hall/Dormitory",
      "Manufacturing",
      "Mixed-Use",
      "Office - Small ( < 10,000 sf)",
      "Office - Medium (< 100,000 sf)",
      "Office - Large",
      "Parking",
      "Parking Deck",
      "Public Assembly - Entertainment/Culture",
      "Public Assembly - General",
      "Public Assembly - Library",
      "Public Assembly - Recreation",
      "Public Assembly - Social/Meeting",
      "Public Safety - Fire/Police Station",
      "Public Safety - General",
      "Religious Worship",
      "Residential - Mobile Homes",
      "Residential - Multi-Family, 2 to 4 units",
      "Residential - Multi-Family, 5 or more units",
      "Residential - Single-Family Attached",
      "Residential - Single-Family Detached",
      "Residential - Mid-Rise/High-Rise",
      "Retail - Mall",
      "Retail - Non-mall, Vehicle Dealerships, misc",
      "Retail Store",
      "Service (vehicle repair/service, postal service)",
      "Storage - Distribution/Shipping Center",
      "Storage - General",
      "Storage - Non-refrigerated warehouse",
      "Storage - Refrigerated warehouse",
      "Warehouse - Self-storage",
      "Water Treatment Center"
    ],
    key: "useType1PrimaryUse",
    sm: 8,
  },
  {
    label: "Use Type 1 -- Primary Area",
    type: "text",
    inputType: "number",
    key: "useType1PrimaryArea",
    sm: 4,
  },
  {
    label: "Use Type 2 -- Secondary Use",
    type: "autocomplete",
    options: [
      "Bank/Financial Institution",
      "Courthouse",
      "Data Center",
      "Education - College/University (campus-level)",
      "Education - General",
      "Education - K-12 School",
      "Food Sales - Convenience Store (w/ or w/out gas station)",
      "Food Sales - General",
      "Food Sales - Supermarket/Grocery",
      "Food Service - Fast Food",
      "Food Service - General",
      "Food Service - Restaurant/Cafeteria",
      "Health Care - Clinic",
      "Health Care - Hospital Inpatient",
      "Health Care - Medical Office",
      "Health Care - Nursing/Assisted Living",
      "Health Care - Outpatient - General",
      "Laboratory",
      "Lodging - General",
      "Lodging - Hotel/Motel",
      "Lodging - Residence Hall/Dormitory",
      "Manufacturing",
      "Mixed-Use",
      "Office - Small ( < 10,000 sf)",
      "Office - Medium (< 100,000 sf)",
      "Office - Large",
      "Parking",
      "Parking Deck",
      "Public Assembly - Entertainment/Culture",
      "Public Assembly - General",
      "Public Assembly - Library",
      "Public Assembly - Recreation",
      "Public Assembly - Social/Meeting",
      "Public Safety - Fire/Police Station",
      "Public Safety - General",
      "Religious Worship",
      "Residential - Mobile Homes",
      "Residential - Multi-Family, 2 to 4 units",
      "Residential - Multi-Family, 5 or more units",
      "Residential - Single-Family Attached",
      "Residential - Single-Family Detached",
      "Residential - Mid-Rise/High-Rise",
      "Retail - Mall",
      "Retail - Non-mall, Vehicle Dealerships, misc",
      "Retail Store",
      "Service (vehicle repair/service, postal service)",
      "Storage - Distribution/Shipping Center",
      "Storage - General",
      "Storage - Non-refrigerated warehouse",
      "Storage - Refrigerated warehouse",
      "Warehouse - Self-storage",
      "Water Treatment Center"
    ],
    key: "useType2SecondaryUse",
    sm: 8,
  },
  {
    label: "Use Type 2 -- Secondary Area",
    type: "text",
    inputType: "number",
    key: "useType2SecondaryArea",
    sm: 4,
  },
  {
    label: "Use Type 3 -- Tertiary Use",
    type: "autocomplete",
    options: [
      "Bank/Financial Institution",
      "Courthouse",
      "Data Center",
      "Education - College/University (campus-level)",
      "Education - General",
      "Education - K-12 School",
      "Food Sales - Convenience Store (w/ or w/out gas station)",
      "Food Sales - General",
      "Food Sales - Supermarket/Grocery",
      "Food Service - Fast Food",
      "Food Service - General",
      "Food Service - Restaurant/Cafeteria",
      "Health Care - Clinic",
      "Health Care - Hospital Inpatient",
      "Health Care - Medical Office",
      "Health Care - Nursing/Assisted Living",
      "Health Care - Outpatient - General",
      "Laboratory",
      "Lodging - General",
      "Lodging - Hotel/Motel",
      "Lodging - Residence Hall/Dormitory",
      "Manufacturing",
      "Mixed-Use",
      "Office - Small ( < 10,000 sf)",
      "Office - Medium (< 100,000 sf)",
      "Office - Large",
      "Parking",
      "Parking Deck",
      "Public Assembly - Entertainment/Culture",
      "Public Assembly - General",
      "Public Assembly - Library",
      "Public Assembly - Recreation",
      "Public Assembly - Social/Meeting",
      "Public Safety - Fire/Police Station",
      "Public Safety - General",
      "Religious Worship",
      "Residential - Mobile Homes",
      "Residential - Multi-Family, 2 to 4 units",
      "Residential - Multi-Family, 5 or more units",
      "Residential - Single-Family Attached",
      "Residential - Single-Family Detached",
      "Residential - Mid-Rise/High-Rise",
      "Retail - Mall",
      "Retail - Non-mall, Vehicle Dealerships, misc",
      "Retail Store",
      "Service (vehicle repair/service, postal service)",
      "Storage - Distribution/Shipping Center",
      "Storage - General",
      "Storage - Non-refrigerated warehouse",
      "Storage - Refrigerated warehouse",
      "Warehouse - Self-storage",
      "Water Treatment Center"
    ],
    key: "useType3TertiaryUse",
    sm: 8,
  },
  {
    label: "Use Type 3 -- Tertiary Area",
    type: "text",
    inputType: "number",
    key: "useType3TertiaryArea",
    sm: 4,
  },
  {
    label: "Use Type 4 -- Quaternary Use",
    type: "autocomplete",
    options: [
      "Bank/Financial Institution",
      "Courthouse",
      "Data Center",
      "Education - College/University (campus-level)",
      "Education - General",
      "Education - K-12 School",
      "Food Sales - Convenience Store (w/ or w/out gas station)",
      "Food Sales - General",
      "Food Sales - Supermarket/Grocery",
      "Food Service - Fast Food",
      "Food Service - General",
      "Food Service - Restaurant/Cafeteria",
      "Health Care - Clinic",
      "Health Care - Hospital Inpatient",
      "Health Care - Medical Office",
      "Health Care - Nursing/Assisted Living",
      "Health Care - Outpatient - General",
      "Laboratory",
      "Lodging - General",
      "Lodging - Hotel/Motel",
      "Lodging - Residence Hall/Dormitory",
      "Manufacturing",
      "Mixed-Use",
      "Office - Small ( < 10,000 sf)",
      "Office - Medium (< 100,000 sf)",
      "Office - Large",
      "Parking",
      "Parking Deck",
      "Public Assembly - Entertainment/Culture",
      "Public Assembly - General",
      "Public Assembly - Library",
      "Public Assembly - Recreation",
      "Public Assembly - Social/Meeting",
      "Public Safety - Fire/Police Station",
      "Public Safety - General",
      "Religious Worship",
      "Residential - Mobile Homes",
      "Residential - Multi-Family, 2 to 4 units",
      "Residential - Multi-Family, 5 or more units",
      "Residential - Single-Family Attached",
      "Residential - Single-Family Detached",
      "Residential - Mid-Rise/High-Rise",
      "Retail - Mall",
      "Retail - Non-mall, Vehicle Dealerships, misc",
      "Retail Store",
      "Service (vehicle repair/service, postal service)",
      "Storage - Distribution/Shipping Center",
      "Storage - General",
      "Storage - Non-refrigerated warehouse",
      "Storage - Refrigerated warehouse",
      "Warehouse - Self-storage",
      "Water Treatment Center"
    ],
    key: "useType4TertiaryUse",
    sm: 8,
  },
  {
    label: "Use Type 4 -- Quaternary Area",
    type: "text",
    inputType: "number",
    key: "useType4TertiaryArea",
    sm: 4,
  },
  {
    label: "Use Type 5 -- Quinary Use",
    type: "autocomplete",
    options: [
      "Bank/Financial Institution",
      "Courthouse",
      "Data Center",
      "Education - College/University (campus-level)",
      "Education - General",
      "Education - K-12 School",
      "Food Sales - Convenience Store (w/ or w/out gas station)",
      "Food Sales - General",
      "Food Sales - Supermarket/Grocery",
      "Food Service - Fast Food",
      "Food Service - General",
      "Food Service - Restaurant/Cafeteria",
      "Health Care - Clinic",
      "Health Care - Hospital Inpatient",
      "Health Care - Medical Office",
      "Health Care - Nursing/Assisted Living",
      "Health Care - Outpatient - General",
      "Laboratory",
      "Lodging - General",
      "Lodging - Hotel/Motel",
      "Lodging - Residence Hall/Dormitory",
      "Manufacturing",
      "Mixed-Use",
      "Office - Small ( < 10,000 sf)",
      "Office - Medium (< 100,000 sf)",
      "Office - Large",
      "Parking",
      "Parking Deck",
      "Public Assembly - Entertainment/Culture",
      "Public Assembly - General",
      "Public Assembly - Library",
      "Public Assembly - Recreation",
      "Public Assembly - Social/Meeting",
      "Public Safety - Fire/Police Station",
      "Public Safety - General",
      "Religious Worship",
      "Residential - Mobile Homes",
      "Residential - Multi-Family, 2 to 4 units",
      "Residential - Multi-Family, 5 or more units",
      "Residential - Single-Family Attached",
      "Residential - Single-Family Detached",
      "Residential - Mid-Rise/High-Rise",
      "Retail - Mall",
      "Retail - Non-mall, Vehicle Dealerships, misc",
      "Retail Store",
      "Service (vehicle repair/service, postal service)",
      "Storage - Distribution/Shipping Center",
      "Storage - General",
      "Storage - Non-refrigerated warehouse",
      "Storage - Refrigerated warehouse",
      "Warehouse - Self-storage",
      "Water Treatment Center"
    ],
    key: "useType5TertiaryUse",
    sm: 8,
  },
  {
    label: "Use Type 5 -- Quinary Area",
    type: "text",
    inputType: "number",
    key: "useType5TertiaryArea",
    sm: 4,
  },
  {
    type: "gap",
    sm: 8,
  },
  {
    label: "Use Type -- Total Area",
    type: "text",
    inputType: "number",
    key: "useTypeTotalArea",
    disabled: true,
    sx: { "input": { backgroundColor: '#0001' } },
    sm: 4,
  },
  {
    type: "gap",
    sm: 12,
  },
];

export const energyModelingFields = [
  {
    label: "Has Energy Modeling",
    type: "question",
    key: "hasEnergyModeling",
    desc: ``,
    sm: 12,
    sx: {
      float: "right",
      width: "fit-content",
      display: "flex",
      alignItems: "center",
    },
  },
  {
    label: "Estimated occupancy year",
    type: "select",
    options: [
      { label: "2022", value: "2022" },
      { label: "2023", value: "2023" },
      { label: "2024", value: "2024" },
      { label: "2025", value: "2025" },
      { label: "2026", value: "2026" },
      { label: "2027", value: "2027" },
      { label: "2028", value: "2028" },
      { label: "2029", value: "2029" },
      { label: "2030", value: "2030" },
      { label: "2031", value: "2031" },
      { label: "2032", value: "2032" },
      { label: "2033", value: "2033" },
      { label: "2034", value: "2034" },
      { label: "2035", value: "2035" },
      { label: "2036", value: "2036" },
      { label: "2037", value: "2037" },
    ],
    key: "estimatedOccupancyYear",
    sm: 4,
  },
  {
    label: "Energy code",
    type: "select",
    options: [
      { label: "ASHRAE 90.1-1999", value: "ASHRAE 90.1-1999" },
      { label: "ASHRAE 90.1-2001", value: "ASHRAE 90.1-2001" },
      { label: "ASHRAE 90.1-2004", value: "ASHRAE 90.1-2004" },
      { label: "ASHRAE 90.1-2007", value: "ASHRAE 90.1-2007" },
      { label: "ASHRAE 90.1-2010", value: "ASHRAE 90.1-2010" },
      { label: "ASHRAE 90.1-2013", value: "ASHRAE 90.1-2013" },
      { label: "ASHRAE 90.1-2016", value: "ASHRAE 90.1-2016" },
      { label: "ASHRAE 90.1-2019", value: "ASHRAE 90.1-2019" },
      { label: "ASHRAE 189.1-2020", value: "ASHRAE 189.1-2020" },
      {
        label: "California Title 24 2005 for high rise residential",
        value: "California Title 24 2005 for high rise residential",
      },
      {
        label: "California Title 24 2005 for single family residential",
        value: "California Title 24 2005 for single family residential",
      },
      { label: "California Title 24 2008", value: "California Title 24 2008" },
      { label: "California Title 24 2010", value: "California Title 24 2010" },
      { label: "California Title 24 2013", value: "California Title 24 2013" },
      { label: "California Title 24 2016", value: "California Title 24 2016" },
      { label: "California Title 24 2019", value: "California Title 24 2019" },
      {
        label: "California Title 24 Non Residential 2008",
        value: "California Title 24 Non Residential 2008",
      },
      {
        label: "California Title 24 Residential 2005",
        value: "California Title 24 Residential 2005",
      },
      {
        label: "California Title 24 Residential 2008",
        value: "California Title 24 Residential 2008",
      },
      { label: "IECC 2003", value: "IECC 2003" },
      { label: "IECC 2006", value: "IECC 2006" },
      { label: "IECC 2009", value: "IECC 2009" },
      { label: "IECC 2012", value: "IECC 2012" },
      { label: "IECC 2015", value: "IECC 2015" },
      { label: "IECC 2018", value: "IECC 2018" },
      { label: "IECC 2021", value: "IECC 2021" },
      {
        label: "Massachusetts Stretch Code",
        value: "Massachusetts Stretch Code",
      },
      { label: "NECB 2011", value: "NECB 2011" },
      { label: "NECB 2015", value: "NECB 2015" },
      { label: "NECB 2017", value: "NECB 2017" },
      {
        label: "New York Stretch Code 2018",
        value: "New York Stretch Code 2018",
      },
      { label: "Older than 1999", value: "Older than 1999" },
      { label: "Oregon Energy Code", value: "Oregon Energy Code" },
      { label: "Oregon Energy Code 2010", value: "Oregon Energy Code 2010" },
      { label: "Oregon Energy Code 2014", value: "Oregon Energy Code 2014" },
      {
        label: "Oregon Energy Efficiency Specialty Code 2010",
        value: "Oregon Energy Efficiency Specialty Code 2010",
      },
      {
        label: "Oregon Energy Efficiency Specialty Code 2014",
        value: "Oregon Energy Efficiency Specialty Code 2014",
      },
      { label: "Washington Energy Code", value: "Washington Energy Code" },
      {
        label: "Washington Energy Code 2012",
        value: "Washington Energy Code 2012",
      },
      {
        label: "Washington Energy Code 2015",
        value: "Washington Energy Code 2015",
      },
    ],
    key: "energyCode",
    sm: 4,
  },
  {
    label: "Energy modeling party",
    type: "autocomplete",
    freeSolo: true,
    placeholder: `Select from the provided options, For "other" type-in directly`,
    options: ["Architecture Team", "Design Engineer", "Modeling Consultant"],
    key: "energyModelingParty",
    disableCondition: ["hasEnergyModeling", false],
    sm: 4,
  },
  //   {
  //     label: "Energy baseline",
  //     type: "text",
  //     key: "energyBaseline",
  //
  //   },
  {
    label: "Energy Baseline Source",
    type: "autocomplete",
    freeSolo: true,
    placeholder: `Select from the provided options, For "other" type-in directly`,
    options: [
      "Zero Tool",
      "National Average",
      "EwingCole Target EUI Matrix",
      "ASHRAE",
      "Laboratory Benchmarking Tool",
    ],
    key: "energyBaselineSource",
    sm: 4,
  },
  {
    label: "Target Certification",
    type: "autocomplete",
    freeSolo: true,
    placeholder: `Select from the provided options, For "other" type-in directly`,
    options: [
      "BREEAM",
      "Designed to meet ENERGY STAR",
      "ENERGY STAR for Homes",
      "Green Globes",
      "LEED Platinum",
      "LEED Gold",
      "LEED Silver",
      "LEED Certified",
      "Living Building Challenge",
      "Living Building Net-Zero Certification",
      "Passive Haus",
      "SITES",
      "WELL Building Standard",
      "None",
    ],
    key: "targetCertification",
    sm: 4,
  },
  {
    label: "Energy modeling tool",
    type: "autocomplete",
    options: [
      "30 AEDG AERG",
      "50 AEDG AERG",
      "Autodesk Insight 360",
      "BE Opt",
      "ClimateStudio",
      "Cove.tool",
      "DOE-2.1E Energy Pro",
      "DOE-2.1E VisualDOE",
      "EnergyPlus Other",
      "HAP",
      "HEED",
      "Home Energy Saver",
      "IES - Virtual Environment",
      "NEO Net Energy Optimizer",
      "Passive House Planning Package (PHPP)",
      "PlanITImpact",
      "DOE 2.2",
      "DOE-2.2 eQuest",
      "DOE-2.2 GreenBuildingStudio",
      "DOE-2.2 Other",
      "EnergyPlus AECOSim",
      "EnergyPlus DesignBuilder",
      "EnergyPlus Ladybug Tools",
      "EnergyPlus OpenStudio",
      "EnergyPlus Simergy",
      "REM Rate",
      "Sefaira",
      "SPEED",
      "TRACE 700",
      "TRNSYS",
      "WUFI Passive",
    ],
    key: "energyModelingTool",
    freeSolo: true,
    disableCondition: ["hasEnergyModeling", false],
    sm: 4,
  },
  {
    type: "gap",
    sm: 12,
  },
  {
    type: "gap",
    sm: 12,
  },
  {
    type: "label",
    key: "energyUseEUI",
    desc: `Energy Use (EUI)`,
    sm: 4,
    sx: {
      "& *": { fontSize: "18px" },
    },
  },
  {
    type: "label",
    key: "energyUseLPD",
    desc: `Energy Use (LPD)`,
    sm: 4,
    sx: {
      "& *": { fontSize: "18px" },
    },
  },
  {
    type: "gap",
    sm: 4,
  },
  {
    label: "EUI Baseline (kBtu/sf/yr)",
    type: "text",
    inputType: "number",
    key: "baselineEUI",
    sm: 4,
  },
  {
    label: "LPD Baseline (watts/sf)",
    type: "text",
    inputType: "number",
    key: "baselineLPD",
    sm: 4,
  },
  {
    type: "gap",
    sm: 4,
  },
  {
    label: "Predicted EUI (kBtu/sf/yr)",
    type: "text",
    inputType: "number",
    key: "predictedEUI",
    disableCondition: ["hasEnergyModeling", false],
    sm: 4,
  },
  {
    label: "Predicted LPD (watts/sf)",
    type: "text",
    inputType: "number",
    key: "predictedLPD",
    sm: 4,
  },
  {
    type: "gap",
    sm: 12,
  },
];

export const embodiedCarbonFields = [
  {
    label: "Has Embodied Carbon Modeling",
    type: "question",
    key: "hasEmbodiedCarbonModeling",
    desc: ``,
    sm: 12,
    sx: {
      float: "right",
      width: "fit-content",
      display: "flex",
      alignItems: "center",
    },
  },
  {
    label: "Carbon Modeling Tool",
    type: "text",
    options: [],
    key: "carbonModelingTool",

    disableCondition: ["hasEmbodiedCarbonModeling", false],
    sm: 4,
  },
  {
    label: "Building Lifetime Modeled",
    type: "text",
    options: [],
    key: "buildingLifetimeModeling",

    disableCondition: ["hasEmbodiedCarbonModeling", false],
    sm: 4,
  },
  {
    label: "Carbon Modeling party",
    type: "autocomplete",
    freeSolo: true,
    placeholder: `Select from the provided options, For "other" type-in directly`,
    options: [
      "Architecture Team",
      "Design Engineer",
      "Modeling Consultant",
      "Not energy modeled",
    ],
    key: "carbonModelingParty",
    disableCondition: ["hasEmbodiedCarbonModeling", false],
    sm: 4,
  },
  {
    type: "gap",
    sm: 4,
  },
  {
    type: "gap",
    sm: 4,
  },
  {
    label: "Predicted Embodied Carbon (kgCOe/m2)",
    type: "text",
    inputType: "number",
    key: "predictedEmbodiedCarbon",
    disableCondition: ["hasEmbodiedCarbonModeling", false],
    sm: 4,
  },
  {
    type: "gap",
    sm: 12,
  },
  {
    type: "gap",
    sm: 12,
  },
  {
    label: "Scope",
    type: "autocomplete",
    freeSolo: true,
    multiple: true,
    placeholder: `Select from the provided options, For "other" type-in directly`,
    options: ["Substructure", "Superstructure", "Enclosure", "Interiors"],
    key: "scope",

    disableCondition: ["hasEmbodiedCarbonModeling", false],
    sm: 12,
    sx: { "&>div.MuiBox-root": { width: "100%" } },
  },
  {
    type: "gap",
    sm: 12,
  },
  {
    label: "LCA Stage Included In the model",
    type: "multiselect",
    options: [
      "Product Stage (A1-3)",
      "Construction process stage A4",
      "Construction process stage A5",
      "Use (B1-5)",
      "End of life (C1-4)",
      "Module D",
    ],
    key: "lcaStageIncluded",

    disableCondition: ["hasEmbodiedCarbonModeling", false],
    sm: 12,
    sx: { "&>div.MuiBox-root": { width: "100%" } },
  },
  {
    type: "gap",
    sm: 12,
  },
];

export const projectPhaseAtLCAField = [
  {
    label: "Project Phase at LCA",
    type: "select",
    options: [
      { label: "Concept", value: "Concept" },
      { label: "Schematic", value: "Schematic" },
      { label: "Design Development", value: "Design Development" },
      { label: "Construction Documents", value: "Construction Documents" },
      { label: "Construction", value: "Construction" },
      { label: "Completed", value: "Completed" },
    ],
    sm: 4,
    key: "projectPhaseAtLCA",
  }, {
    label: "Reporting Year",
    type: "select",
    options: [
      { label: "2024", value: "2024" },
      { label: "2025", value: "2025" },
      { label: "2026", value: "2026" },
      { label: "2027", value: "2027" },
      { label: "2028", value: "2028" },
      { label: "2029", value: "2029" },
      { label: "2030", value: "2030" },
    ],
    sm: 4,
    key: "projectPhaseReportingYear",
  },
  {
    label: "Updated By",
    type: "text",
    key: "projectPhaseUpdatedBy",
    sm: 4,
  },
  {
    type: "gap",
    sm: 12,
  }
]

export const projectDetailsFields = [
  {
    label: "Primary Building Use Type",
    type: "autocomplete",
    placeholder: `Select from the provided options, For "other" type-in directly`,
    options: [
      "Office",
      "Public Assembly",
      "Education",
      "Industrial",
      "Mercantile",
      "Multi-Family Residential",
      "Warehouse",
      "Other",
      "Public Order and Safety",
      "Single-Family Residential",
      "Parking",
      "Lodging",
      "Health Care",
    ],
    sm: 4,
    key: "primaryBuildingUseType",
  },
  {
    label: "Construction Type",
    type: "select",
    options: [
      { label: "New Construction", value: "New Construction" },
      {
        label: "Major Renovation of Existing Building: Vertical Expansion",
        value: "Major Renovation of Existing Building: Vertical Expansion"
      },
      {
        label: "Major Renovation of Existing Building: Structural Retrofit",
        value: "Major Renovation of Existing Building: Structural Retrofit"
      },
      {
        label: "Major Renovation of Existing Building: Other",
        value: "Major Renovation of Existing Building: Other",
      },
    ],
    sm: 4,
    key: "constructionType",
  },
  {
    label: "Construction Year Completed (or Anticipated)",
    type: "text",
    inputType: "number",
    key: "constructionYearCompleted",
    sm: 4,
  },
  {
    label: "Expected Building Life (in Years)",
    type: "select",
    options: [
      { label: "20", value: "20" },
      { label: "60", value: "60" },
      { label: "75", value: "75" },
      { label: "100", value: "100" },
    ],
    sm: 4,
    key: "expectedBuildingLife",
    placeholder: 'Default is typically 60'
  },
  {
    label: "Gross Square Footage (ft2)",
    type: "text",
    inputType: "text",
    key: "grossSquareFootage",
    sm: 4,
  },
  {
    label: "Mean Roof Height (ft)",
    type: "text",
    inputType: "text",
    key: "meanRoofHeight",
    sm: 4,
  },
  {
    label: "Number of Stories, Above Grade",
    type: "text",
    inputType: "text",
    key: "noOfStoriesAboveGrade",
    sm: 4,
  },
  {
    label: "Number of Stories, Below Grade",
    type: "text",
    inputType: "text",
    key: "noOfStoriesBelowGrade",
    sm: 4,
  },
  {
    type: "gap",
    sm: 12,
  },
];

export const structuralSystemInformationFields = [
  {
    label: "Typical Column Grid, Long Direction (feet)",
    type: "text",
    inputType: "text",
    key: "typicalColumnGridLongDirection",
    sm: 4,
  },
  {
    label: "Typical Column Grid, Short Direction (feet)",
    type: "text",
    inputType: "text",
    key: "typicalColumnGridShortDirection",
    sm: 4,
  },
  {
    label: "Risk Category",
    type: "select",
    options: [
      {
        label: "I (low risk to human life)",
        value: "I (low risk to human life)",
      },
      {
        label: "II (all buildings and other structures)",
        value: "II (all buildings and other structures)",
      },
      {
        label: "III (buildings with a substantial risk to cause economic impact and/or mass disruption)",
        value: "III (buildings with a substantial risk to cause economic impact and/or mass disruption)",
      },
      {
        label:
          "IV (buildings and other structures designated as essential facilities or the failure of which could pose a substantial hazard to the community)",
        value:
          "IV (buildings and other structures designated as essential facilities or the failure of which could pose a substantial hazard to the community)",
      },
    ],
    key: "riskCategory",
    sm: 4,
  },
  {
    label: "Typical Floor Live Load (psf)",
    type: "text",
    inputType: "text",
    key: "typicalFloorLiveLoad",
    sm: 4,
  },
  {
    label: "Ground Snow Load (psf)",
    type: "text",
    inputType: "text",
    key: "groundSnowLoad",
    sm: 4,
  },
  {
    label: "Ultimate Wind Speed (mph)",
    type: "text",
    inputType: "text",
    key: "ultimateWindSpeed",
    sm: 4,
  },
  {
    label: "Seismic Site Class",
    type: "select",
    options: [
      { label: "A", value: "A" },
      { label: "B", value: "B" },
      { label: "C", value: "C" },
      { label: "D", value: "D" },
      { label: "E", value: "E" },
      { label: "F", value: "F" },
    ],
    key: "seismicSiteClass",
    sm: 4,
  },
  {
    label: "Seismic Design Category",
    type: "select",
    options: [
      { label: "A", value: "A" },
      { label: "B", value: "B" },
      { label: "C", value: "C" },
      { label: "D", value: "D" },
      { label: "E", value: "E" },
      { label: "F", value: "F" },
    ],
    key: "seismicDesignCategory",
    sm: 4,
  },
  {
    label: "Primary Horizontal Gravity System",
    type: "select",
    options: [
      { label: "Concrete: PT Framing", value: "Concrete: PT Framing" },
      { label: "Concrete: Non-PT Framing", value: "Concrete: Non-PT Framing" },
      { label: "Concrete: Precast", value: "Concrete: Precast" },
      { label: "Concrete: Other", value: "Concrete: Other" },
      {
        label: "Steel: Frame + Concrete on Metal Deck",
        value: "Steel: Frame + Concrete on Metal Deck",
      },
      {
        label: "Steel: Frame + Bare Metal Deck",
        value: "Steel: Frame + Bare Metal Deck",
      },
      { label: "Steel: Other", value: "Steel: Other" },
      {
        label: "Wood: Joists and Sheathing",
        value: "Wood: Joists and Sheathing",
      },
      { label: "Wood: Engineered Panels", value: "Wood: Engineered Panels" },
      { label: "Wood: Other", value: "Wood: Other" },
      {
        label: "Other Material (not concrete, steel, or wood)",
        value: "Other Material (not concrete, steel, or wood)",
      },
    ],
    key: "primaryHorizontalGravitySystem",
    sm: 4,
  },
  {
    label: "Primary Vertical Gravity System",
    type: "select",
    options: [
      { label: "Concrete: CIP", value: "Concrete: CIP" },
      { label: "Concrete: Precast", value: "Concrete: Precast" },
      { label: "Concrete: Other", value: "Concrete: Other" },
      { label: "Steel: Columns", value: "Steel: Columns" },
      { label: "Steel: Cold-Formed", value: "Steel: Cold-Formed" },
      { label: "Steel: Other", value: "Steel: Other" },
      { label: "Wood: Mass Timber", value: "Wood: Mass Timber" },
      { label: "Wood: Light-Frame", value: "Wood: Light-Frame" },
      { label: "Wood: Other", value: "Wood: Other" },
      { label: "Masonry", value: "Masonry" },
      {
        label: "Other Material (not concrete, steel, wood, or masonry)",
        value: "Other Material (not concrete, steel, wood, or masonry)",
      },
    ],
    key: "primaryVerticalGravitySystem",
    sm: 4,
  },
  {
    label: "Primary Lateral System",
    type: "autocomplete",
    placeholder: `Select from the provided options`,
    options: [
      "Concrete: Shear Walls",
      "Concrete: Moment Frames",
      "Concrete: Other",
      "Steel: Braced Frames",
      "Steel: Moment Frames",
      "Steel: Other",
      "Light Frame Shear Panels",
      "Masonry: Shear Walls",
      "Wood: Shear Panels",
      "Wood: Other",
      "Other"
    ],
    key: "primaryLateralSystem",
    sm: 4,
  },
  {
    label: "Podium",
    type: "select",
    options: [
      { label: "Not a podium building", value: "Not a podium building" },
      {
        label: "Primary system defined above is on a podium",
        value: "Primary system defined above is on a podium",
      },
      {
        label: "Primary system defined above is a podium",
        value: "Primary system defined above is a podium",
      },
    ],
    key: "podium",
    sm: 4,
  },
  {
    label: "Allowable Soil Bearing Pressure (psf)",
    type: "select",
    options: [
      { label: "2000", value: "2000" },
      { label: "3000", value: "3000" },
      { label: "4000", value: "4000" },
      { label: "5000", value: "5000" },
      { label: "6000", value: "6000" },
      { label: "7000", value: "7000" },
      { label: "8000", value: "8000" },
      { label: "9000", value: "9000" },
      { label: "10000", value: "10000" },
      { label: "15000", value: "15000" },
      { label: "20000", value: "20000" },
      { label: "30000", value: "30000" },
      { label: "40000", value: "40000" },
    ],
    key: "allowableSoilBearingPressure",
    sm: 4,
  },
  {
    label: "Foundation Type",
    type: "select",
    options: [
      { label: "Shallow Foundation", value: "Shallow Foundation" },
      {
        label: "Deep Foundation < 50'/15m",
        value: "Deep Foundation < 50'/15m",
      },
      {
        label: "Deep Foundation > 50'/15m",
        value: "Deep Foundation > 50'/15m",
      },
      { label: "Other Foundation System", value: "Other Foundation System" },
    ],
    key: "foundationType",
    sm: 4,
  },
  {
    type: "gap",
    sm: 12,
  },
];

const lcaExcludingStyle = { display: 'flex', flexDirection: 'row', alignItems: 'center', "label": { width: '20vw', textAlign: 'right', px: 0.5, mb: 0, fontWeight: 'bold', fontSize: '14px' } }
const lcaIncludingStyle = { display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', "label": { width: '20vw', textAlign: 'left', px: 0.5, mb: 0, fontWeight: 'bold', fontSize: '14px' } }
const lcaDisabledLeft = { display: 'flex', flexDirection: 'row', alignItems: 'center', "label": { width: '20vw', textAlign: 'right', px: 0.5, mb: 0, fontWeight: 'bold', fontSize: '14px' }, "input": { backgroundColor: '#0001' } }
const lcaDisabledRight = { display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', "label": { width: '20vw', textAlign: 'left', px: 0.5, mb: 0, fontWeight: 'bold', fontSize: '14px' }, "input": { backgroundColor: '#0001' } }
export const GlobalWarmingPotentialDataFields = [
  // {
  //   label: "Total GWP (kg-CO2e)",
  //   type: "text",
  //   inputType: "number",
  //   key: "totalGWP",
  //   sm: 4,
  // },
  {
    label: "LCA Tool",
    type: "autocomplete",
    placeholder: `Select from the provided options`,
    options: [
      "Athena IE",
      "Tally",
      "Beacon",
      "Quartz",
      "EC3",
      "One Click",
      "Embodied Carbon Order of Magnitude (ECOM)",
      "eTool",
      "Environmental Product Declarations (EPDs)",
      "Simapro",
      "Other",
    ],
    key: "lcaTool",
    sm: 4,
  },
  {
    label: "Year LCA was performed",
    type: "text",
    inputType: "number",
    key: "lcaPerformedYear",
    min: 2000,
    max: 2050,
    sm: 4,
  },
  {
    type: "gap",
    sm: 12,
  },
  {
    type: "gap",
    sm: 12,
  },
  {
    label: "Global Warming Potential (kgCO2e)",
    type: "label",
    key: "gwp",
    desc: ``,
    sm: 12,
  },
  {
    label: "",
    type: "label",
    key: "excludingBiogenic",
    desc: `Excluding Biogenic`,
    sm: 6,
    sx: { display: 'flex', flexDirection: 'row-reverse', }
  },
  {
    label: "",
    type: "label",
    key: "includedBiogenic",
    desc: `Including Biogenic`,
    sm: 6,
    sx: { display: 'flex', flexDirection: 'row', },
  },
  { label: "A1- A3 ", type: "text", inputType: "number", key: "lcaStagesExcludingA1A3", sm: 6, sx: lcaExcludingStyle },
  { label: "kgCO2e", type: "text", inputType: "number", key: "lcaStagesIncludingA1A3", sm: 6, sx: lcaIncludingStyle },
  { label: "A4", type: "text", inputType: "number", key: "lcaStagesExcludingA4", sm: 6, sx: lcaExcludingStyle },
  { label: "kgCO2e", type: "text", inputType: "number", key: "lcaStagesIncludingA4", sm: 6, sx: lcaIncludingStyle },
  { label: "A5", type: "text", inputType: "number", key: "lcaStagesExcludingA5", sm: 6, sx: lcaExcludingStyle },
  { label: "kgCO2e", type: "text", inputType: "number", key: "lcaStagesIncludingA5", sm: 6, sx: lcaIncludingStyle },
  { label: "B1-B5", type: "text", inputType: "number", key: "lcaStagesExcludingB1B5", sm: 6, sx: lcaExcludingStyle },
  { label: "kgCO2e", type: "text", inputType: "number", key: "lcaStagesIncludingB1B5", sm: 6, sx: lcaIncludingStyle },
  { label: "C1-C4", type: "text", inputType: "number", key: "lcaStagesExcludingC1C4", sm: 6, sx: lcaExcludingStyle },
  { label: "kgCO2e", type: "text", inputType: "number", key: "lcaStagesIncludingC1C4", sm: 6, sx: lcaIncludingStyle },
  { label: "D", type: "text", inputType: "number", key: "lcaStagesExcludingD", sm: 6, sx: lcaExcludingStyle },
  { label: "kgCO2e", type: "text", inputType: "number", key: "lcaStagesIncludingD", sm: 6, sx: lcaIncludingStyle },
  { label: "Total GWP (excluding D)", type: "text", inputType: "number", key: "totalGWPExcluding", sm: 6, sx: lcaDisabledLeft, disabled: true },
  { label: "kgCO2e", type: "text", inputType: "number", key: "totalGWPIncluding", sm: 6, sx: lcaDisabledRight, disabled: true },
  // { label: "GWP Intensity (excluding D)", type: "text", inputType: "number", key: "gwpIntensityExcluding", sm: 6, sx: lcaDisabledLeft, disabled: true },
  // { label: "kgCO2e/m^2", type: "text", inputType: "number", key: "gwpIntensityIncluding", sm: 6, sx: lcaDisabledRight, disabled: true },
  {
    type: "gap",
    sm: 12,
  },
  {
    type: "gap",
    sm: 12,
  },

  {
    label: "Building Substructure Components that were included in the LCA",
    type: "label",
    key: "buildingSubstructureComponents",
    desc: `* Required (if applicable to project) `,
    additionalDesc: `** Foundations must be included - Choose either 'Deep Foundation' or 'Shallow Foundation'`,
    sm: 12,
  },
  {
    type: "gap",
    sm: 12,
  },
  {
    type: "question",
    key: "buildingSubstructureCompsDeepFoundation",
    desc: `Deep Foundation**`,
    sm: 4,
  },
  {
    type: "question",
    key: "buildingSubstructureCompsShallowFoundation",
    desc: `Shallow Foundation**`,
    sm: 4,
  },
  {
    type: "question",
    key: "buildingSubstructureCompsSlabs",
    desc: `Slabs*`,
    sm: 4,
  },
  {
    type: "question",
    key: "buildingSubstructureCompsWalls",
    desc: `Walls*`,
    sm: 4,
  },
  {
    type: "question",
    key: "buildingSubstructureCompsEmbeddedSteel",
    desc: `Other: Embedded Steel Elements`,
    sm: 4,
  },
  {
    type: "gap",
    sm: 12,
  },
  {
    type: "gap",
    sm: 12,
  },
  {
    label: "Building Superstructure Components that were included in the LCA",
    type: "label",
    key: "buildingSuperstructureComponents",
    desc: `* Required (if applicable to project)`,
    sm: 12,
  },
  {
    type: "gap",
    sm: 12,
  },
  {
    type: "question",
    key: "buildingSuperstructureCompsColumns",
    desc: `Columns*`,
    sm: 4,
  },
  {
    type: "question",
    key: "buildingSuperstructureCompsBeams",
    desc: `Beams*`,
    sm: 4,
  },
  {
    type: "question",
    key: "buildingSuperstructureCompsSlabDeck",
    desc: `Slab/Deck*`,
    sm: 4,
  },
  {
    type: "question",
    key: "buildingSuperstructureCompsVerticalBracing",
    desc: `Vertical Bracing*`,
    sm: 4,
  },
  {
    type: "question",
    key: "buildingSuperstructureCompsWalls",
    desc: `Walls*`,
    sm: 4,
  },
  {
    type: "question",
    key: "buildingSuperstructureCompsConnections",
    desc: `Other: Connections`,
    sm: 4,
  },
  {
    type: "question",
    key: "buildingSuperstructureCompsMiscSteel",
    desc: `Other: Miscellaneous Steel`,
    sm: 4,
  },
  {
    type: "question",
    key: "buildingSuperstructureCompsFireproofing",
    desc: `Other: Fireproofing`,
    sm: 4,
  },

  {
    type: "gap",
    sm: 12,
  },
  {
    type: "gap",
    sm: 12,
  },
  {
    label: `Show anonymized Project GWP Intensity on dashboard*`,
    type: "question",
    key: "showAnonymizedProjectGWPIntensity",
    desc: ``,
    sm: 12,
  },
  {
    desc: "",
    type: "label",
    label: `*Note: This pertains to the SE 2050 National Database.  All data will be viewable internally, through this database.`,
    sm: 12,
  },
  {
    type: "gap",
    sm: 12,
  },
];

export const SE2050StructuralMaterialQuantities = [
  {
    label: "Structural Material Quantities",
    key: "materialQuantity",
    exportType: 'object'
  }
]

export const MEPenergyModelingFields = [
  {
    label: "Has an Energy Model been performed for the project?",
    type: "question",
    key: "hasEnergyModelingPerformed",
    desc: ``,
    sm: 12,
    sx: {
      float: "right",
      width: "fit-content",
      display: "flex",
      alignItems: "center",
      maxWidth: "300px",
      "& > label": {
        textWrap: "wrap",
      },
    },
  },
  {
    type: "gap",
    sm: 12,
  },
  {
    label: "Energy code",
    type: "select",
    options: [
      { label: "ASHRAE 90.1-1999", value: "ASHRAE 90.1-1999" },
      { label: "ASHRAE 90.1-2001", value: "ASHRAE 90.1-2001" },
      { label: "ASHRAE 90.1-2004", value: "ASHRAE 90.1-2004" },
      { label: "ASHRAE 90.1-2007", value: "ASHRAE 90.1-2007" },
      { label: "ASHRAE 90.1-2010", value: "ASHRAE 90.1-2010" },
      { label: "ASHRAE 90.1-2013", value: "ASHRAE 90.1-2013" },
      { label: "ASHRAE 90.1-2016", value: "ASHRAE 90.1-2016" },
      { label: "ASHRAE 90.1-2019", value: "ASHRAE 90.1-2019" },
      { label: "ASHRAE 189.1-2020", value: "ASHRAE 189.1-2020" },
      { label: "IECC 2003", value: "IECC 2003" },
      { label: "IECC 2006", value: "IECC 2006" },
      { label: "IECC 2009", value: "IECC 2009" },
      { label: "IECC 2012", value: "IECC 2012" },
      { label: "IECC 2015", value: "IECC 2015" },
      { label: "IECC 2018", value: "IECC 2018" },
      { label: "IECC 2021", value: "IECC 2021" },
    ],
    key: "energyCode",

    disableCondition: ["hasEnergyModelingPerformed", false],
    sm: 4,
  },
  {
    label: "Energy baseline",
    type: "text",
    key: "energyBaseline",
    inputType: "text",
    disableCondition: ["hasEnergyModelingPerformed", false],
    sm: 4,
  },
  {
    label: "Energy Model EUI (kBtu/sf/yr)",
    type: "text",
    key: "energyModelEUI",
    inputType: "number",
    disableCondition: ["hasEnergyModelingPerformed", false],
    sm: 4,
  },
  {
    label: "Energy reduction strategies that were implemented on this project?",
    type: "text",
    key: "energyReductionStrategies",
    inputType: "text",
    disableCondition: ["hasEnergyModelingPerformed", false],
    sm: 12,
  },
  {
    type: "gap",
    sm: 12,
  },
];

export const MEPequipmentEPDInformationFields = [
  {
    label:
      "Requested Environmental Product Declaration (EPD) for project equipment?",
    type: "question",
    key: "requestedEPDForProjectEquip",
    desc: ``,
    sm: 12,
    sx: {
      float: "right",
      width: "fit-content",
      display: "flex",
      alignItems: "center",
      maxWidth: "300px",
      "& > label": {
        textWrap: "wrap",
      },
    },
  },
  {
    label: "EDP Information",
    key: "epdInfo",
    exportType: 'object'
  }
  // {
  //   type: "gap",
  //   sm: 12,
  // },
  // {
  //   label: "Equipment Discipline",
  //   type: "select",
  //   options: [
  //     { label: "Mechanical", value: "Mechanical" },
  //     { label: "Electrical", value: "Electrical" },
  //     { label: "Plumbing", value: "Plumbing" },
  //   ],
  //   key: "equipmentDiscipline",
  //   disableCondition: ["requestedEPDForProjectEquip", false],
  //   sm: 4,
  // },
  // {
  //   label: "Specific Equipment with EPD Information (PDFs)",
  //   type: "text",
  //   key: "specificEquipmentEPDInfo",
  //   inputType: "text",
  //   disableCondition: ["requestedEPDForProjectEquip", false],
  //   sm: 4,
  // },
  // {
  //   label: "GWP, LCA Stages A1-A3 (kgCO2e)",
  //   type: "text",
  //   key: "embodiedCarbonGWP",
  //   inputType: "number",
  //   disableCondition: ["requestedEPDForProjectEquip", false],
  //   sm: 4,
  // },
  // {
  //   label: "Other Information about EPD or GWP from Rep or Manufacturer?",
  //   type: "text",
  //   key: "otherEPGorGWPInfo",
  //   inputType: "text",
  //   disableCondition: ["requestedEPDForProjectEquip", false],
  //   sm: 12,
  // },
  // {
  //   type: "gap",
  //   sm: 12,
  // },
];

export const MEPRefrigerantPipingInformation = [
  {
    label: "Does the project have refrigerant piping within the scope?",
    type: "question",
    key: "projectRefrigerantPipingWithinScope",
    desc: ``,
    sm: 12,
    sx: {
      float: "right",
      width: "fit-content",
      display: "flex",
      alignItems: "center",
      maxWidth: "300px",
      "& > label": {
        textWrap: "wrap",
      },
    },
  },
  {
    label: "Refrigerant Piping Information",
    key: "refrigerantPipingInfo",
    exportType: 'object'
  }
  // {
  //   type: "gap",
  //   sm: 12,
  // },
  // {
  //   label: "Refrigerant Systems used in Project",
  //   type: "autocomplete",
  //   freeSolo: true,
  //   placeholder: `Select from the provided options, For "other" type-in directly`,
  //   options: [
  //     "Split Systems",
  //     "VRF Systems",
  //     "Packaged Rooftop DX Coils",
  //     "Chillers",
  //     "Food Service Fridges",
  //   ],
  //   key: "refrigerantSystemsUsedInProject",
  //   disableCondition: ["projectRefrigerantPipingWithinScope", false],
  //   sm: 6,
  // },
  // {
  //   label:
  //     "Refrigerant Used (Maybe use column system with a 2nd column with charge per ref.)",
  //   type: "autocomplete",
  //   freeSolo: true,
  //   placeholder: `Select from the provided options, For "other" type-in directly`,
  //   options: [
  //     "R-134a",
  //     "R-32",
  //     "R-123",
  //     "R-1233zd",
  //     "R-514A",
  //     "R-513A",
  //     "R-515B",
  //     "R-1234ze",
  //     "R-22",
  //     "R-410A",
  //     "R-466A",
  //     "R-452B",
  //     "R-454B",
  //   ],
  //   key: "refrigerantUsed",
  //   disableCondition: ["projectRefrigerantPipingWithinScope", false],
  //   sm: 6,
  // },
  // {
  //   label: `Requested Low GWP Refrigerant`,
  //   type: "questionv2",
  //   key: "requestedLowGWPRefrigerant",
  //   disableCondition: ["projectRefrigerantPipingWithinScope", false],
  //   sm: 12,
  // },
  // {
  //   label: "Refrigerant Charge (kg) for each refrigerant used on the project",
  //   type: "text",
  //   key: "eachRefrigerantCharge",
  //   inputType: "text",
  //   disableCondition: ["projectRefrigerantPipingWithinScope", false],
  //   sm: 12,
  // },
  // {
  //   type: "gap",
  //   sm: 12,
  // },
];

export const MEPAdditionalEmbodiedCarbonTrackingQuestions = [
  {
    label: "Is the project tracking Embodied Carbon?",
    type: "question",
    key: "isProjectTrackingEmbodiedCarbon",
    desc: ``,
    sm: 12,
  },
  {
    label: "Project tracking Embodied Carbon",
    type: "autocomplete",
    multiple: true,
    options: [
      "A1 - Raw Materials",
      "A2 - Transport to Factory",
      "A3 - Manufacturing",
      "A4 - Transport to Site",
      "A5 - Construction / Installation",
      "B - Retrofit / Upgrade",
      "C - Decommissioning",
      "D - Recycling",
    ],
    key: "projectTrackingEmbodiedCarbon",
    sm: 12,
    disableCondition: ["isProjectTrackingEmbodiedCarbon", false],
  },
  {
    label:
      "Refrigerant Installation Leakage %? (Using MEP2040 Ref. Impact Calculator)",
    type: "text",
    key: "refrigerantInstallationLeakage",
    inputType: "number",
    sm: 6,
  },
  {
    label:
      "Refrigerant Installation Leakage Quantity?",
    type: "text",
    key: "refrigerantInstallationLeakageQuantity",
    inputType: "text",
    sm: 6,
  },
  {
    label: "Refrigerant Annual Leakage %?",
    type: "text",
    key: "refrigerantAnnualLeakage",
    inputType: "number",
    sm: 6,
  },
  {
    label: "Refrigerant Annual Leakage Quantity?",
    type: "text",
    key: "refrigerantAnnualLeakageQuantity",
    inputType: "text",
    sm: 6,
  },
  {
    label: "Refrigerant Global Warming Potential (GWP) (kgCO2e)?",
    type: "text",
    key: "refrigerantGlobalWarmingPotential",
    inputType: "number",
    sm: 12,
  },
  {
    type: "gap",
    sm: 12,
  },
];

export const AIAMaterialsPledgeSurvey_EarlyDesign = [
  {
    label: `Did your project team discuss the Materials Pledge with the client/owner?`,
    type: "question",
    key: "discussedMaterialsPledgeWithClient",
    sm: 12,
  },
  {
    label:
      "Did your project team discuss the Materials Pledge goals, as defined in the EC SAP, in early design?",
    type: "question",
    key: "discussedMaterialsPledgeGoals",
    sm: 12,
  },
  {
    label:
      "Were project-specific goals set in support of the Materials Pledge targets listed in the EC SAP?",
    type: "question",
    key: "projectSpecificGoalsSet",
    sm: 12,
  },
  {
    label:
      "Is the project pursuing a sustainability certification?",
    type: "question",
    key: "isProjectPursuingSustainabilityCertification",
    sm: 12,
  },
  {
    label: `If the project is pursuing a sustainability certification, select it from the following list`,
    type: "autocomplete",
    options: [
      "LEED",
      "WELL",
      "Fitwel",
      "Green Globes",
      "Living Building Challenge",
    ],
    key: "projectPursuingSustainabilityCertification",
    sm: 12,
    disableCondition: ["isProjectPursuingSustainabilityCertification", false],
  },
  {
    label:
      "If project-specific goals were set, please enter them in the field below",
    type: "text",
    key: "projectSpecificGoals",
    inputType: "text",
    sm: 12,
  },
  {
    label: `Did your project assign an interior design lead and and architecture lead to fill out the EC Materials Tracker?`,
    type: "autocomplete",
    options: ["No"],
    freeSolo: true,
    key: "asignedInteriorDesignAndArchitectureLead",
    inputType: "text",
    placeholder: "If yes, please enter first and last names",
    sm: 12,
  },
  {
    type: "gap",
    sm: 12,
  },
];

export const AIAMaterialsPledgeSurvey_MidDesign = [
  {
    label: `Does the project require FSC Certified wood in any specifications?`,
    desc: "FSC certification supports forest stewardship and social equity.",
    type: "question",
    key: "projectRequireFSCCertifiedWood",
    sm: 12,
  },
  {
    label: `If the project requires FSC Certified Wood, select aligned sections from the following list.`,
    type: "autocomplete",
    options: [
      "061000 Rough Carpentry",
      "061053 Misc. Rough Carpentry",
      "061715 Engineered Structural Wood",
      "062023 - Interior Finish Carpentry",
      "064100 Architectural Cabinets",
      "081416 Flush Wood Doors",
      "085200, 085213 Wood Windows",
      "095426 Linear Wood Ceilings, Walls",
      "096400, 096466 Wood Flooring",
      "Division 12 Casework",
    ],
    multiple: true,
    freeSolo: true,
    key: "selectedAlignedSections",
    placeholder:
      "Please add any additional sections by directly typing in the Field.",
    sm: 12,
  },
  {
    label: `Is Extended Producer Responsibility required in the specification for Acoustical Ceilings (095113) and/or Tile Carpeting (096813)?`,
    type: "multiselect",
    options: ["Yes, Acoustical Ceilings", "Yes, Tile Carpeting", "No"],
    yesOrNo: true,
    key: "extendedProducerResponsibilityRequired",
    sm: 12,
    sx: { "&>div.MuiBox-root": { width: "100%" } },
  },
  {
    label: "Will salvaged or reused materials be incorporated in the project?",
    type: "question",
    key: "willSalvagedReusedMaterialsInProject",
    sm: 12,
  },
  {
    label: `If salvage or reused materials are included, for which material types?`,
    type: "text",
    key: "salvagedReusedMaterialsInProject",
    inputType: "text",
    sm: 12,
    disableCondition: ["willSalvagedReusedMaterialsInProject", false],
  },
  {
    label:
      "Has a Construction and Demolition Waste Management (CDWM) diversion goal been set for the project (Section 017419)?",
    type: "autocomplete",
    options: ["Yes, 50% diversion", "Yes, 75% diversion", "No"],
    freeSolo: true,
    key: "hasCDWMDiversionGoalSet",
    placeholder: `Select from the provided options, For "other" type-in directly`,
    sm: 12,
  },
  {
    label: `Are there client design guidelines or other requirements that restrict your ability to select sustainable, responsible materials for the project?`,
    type: "text",
    key: "restrictionsToSelectSustainableMaterials",
    inputType: "text",
    sm: 12,
  },
  {
    type: "gap",
    sm: 12,
  },
];

export const AIAMaterialsPledgeSurvey_LateDesignConstruction = [
  {
    label: `Did you complete the EwingCole Materials Tracker for your project?`,
    type: "question",
    key: "completedEwingColeMaterialsTracker",
    sm: 12,
  },
  {
    label: `EwingCole Materials Tracker`,
    type: "file",
    key: "ewingColeMaterialsTracker",
    sm: 12,
    disableCondition: ['completedEwingColeMaterialsTracker', false]
  },
  {
    label: `Was material transparency prioritized through selection of products with Environmental Product Declarations (EPDs) and/or Material Ingredient Disclosure, such as Health Product Declarations (HPDs)?`,
    type: "multiselect",
    options: [
      "Yes, EPDs",
      "Yes, HPDs",
      "Yes, other Material Ingredient Disclosures",
      "No",
    ],
    yesOrNo: true,
    key: "wasMaterialTransparencyPrioritized",
    sm: 12,
    sx: { "&>div.MuiBox-root": { width: "100%" } },
  },
  {
    label: `Was material optimization prioritized through selection of products that meet any of the following certification thresholds?`,
    type: "autocomplete",
    options: [
      "Declare Label - Redlist Free",
      "Cradle to Cradle (C2C) - Silver",
      "Cradle to Cradle (C2C) - Gold",
      "Cradle to Cradle (C2C) - Platinum",
      "VOC Emissions Testing - UL Greenguard",
      "VOC Emissions Testing - SCS Indoor Advantage Gold",
    ],
    multiple: true,
    freeSolo: true,
    key: "wasMaterialOptimizationPrioritized",
    placeholder:
      "Please add any additional sections by directly typing in the Field.",
    sm: 12,
  },
  {
    label: `Were there particular resources (internal, professional organizations, manufacturer websites, etc.) that were particularly helpful in selecting sustainable, responsible materials for this project?`,
    type: "text",
    key: "resourcesHelpfulInSelectingSustainable",
    inputType: "text",
    sm: 12,
  },
  {
    label: `What material choice or process are you most excited about from this project? What was a win, big or small?`,
    type: "text",
    key: "mostExcitedMaterialChoice",
    inputType: "text",
    sm: 12,
  },
  {
    label: `Were there any materials goals that were removed or modified through the design process?`,
    type: "text",
    key: "materialsGoalsRemovedOrModified",
    inputType: "text",
    sm: 12,
  },
  {
    type: "gap",
    sm: 12,
  },
];
