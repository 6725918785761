import React, { useState, useEffect, useMemo } from "react";
import api from "../../api/api";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { Alert, Box, Button, CircularProgress, Divider, Grid, Snackbar, Typography } from "@mui/material";
import styled from "@emotion/styled";
import PageTitle from "../Common/PageTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import { Search } from "@mui/icons-material";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanel, a11yProps } from "../Common/Tab/TabPanel";
import { Card } from "../Common/Card";
import Roles from "../Roles/Roles";
import UserRoles from "../UserRoles/UserRoles";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { convertExcelToJson, jsonToExcel } from "../../utils";
import { VisuallyHiddenInput } from "../EditProject/MEP2040/MEP2040";

const styles = {
    width: "1rem",
    height: "1rem",
    color: "white",
    mr: "0.5rem",
};
const useIcon = (isLoading, Icon) => {
    return isLoading ? <CircularProgress size={"small"} sx={styles} /> : <Icon size={"small"} sx={styles} />
}

const ManageMetadata = () => {
    const [isExportLoading, setExportLoading] = useState(false);
    const [isImportLoading, setImportLoading] = useState(false);
    const ImportIcon = useIcon(isImportLoading, FileUploadIcon);
    const ExportIcon = useIcon(isExportLoading, FileDownloadIcon);
    const [snackbar, showSnackbar] = useState({});

    const handleExportMetadata = async () => {
        setExportLoading(true);
        try {
            const res = await api.get("/getProjectMetaData");
            jsonToExcel(
                {
                    "Project Metadata": res.data || [],
                },
                `Export Project Metadata - ${new Date().toLocaleString()}.xlsx`
            );
        } catch (e) {
            console.log(e);
        }
        setExportLoading(false);
    }

    const handleImportTemplateDownload = () => {
        jsonToExcel({
            "Project Metadata": [{
                "ProjectNo": "",
                "Name": "",
                "CompanyName": "",
                "MarketingName": "",
                "ProjectPrincipal": "",
                "ProjectStatus": "",
                "Market": "",
                "Team": "",
                "Office": "",
                "State": "",
                "ProjectManager": "",
                "ProjectOwner": "",
                "ProjectSalesLeader": "",
                "ZIPPostalCode": "",
                "City": "",
                "Street1": "",
                "Street2": "",
                "ACASSRatings": "",
                "OriginatingOpportunity": "",
                "Country": ""
            }],
        }, `Project_Metadata_Template.xlsx`);
    }

    const handleImportMetadata = async (e) => {
        console.log("handleImportMetadata")
        setImportLoading(true);
        const file = e.target.files?.[0];
        if (!file) {
            setImportLoading(false);
            return
        }
        try {
            const projectsMetadata = await convertExcelToJson(file);
            if (file?.name?.split(".").at(-1)?.toLowerCase() !== "xlsx") {
                throw "Only XLSX files are allowed."
            } else if (!projectsMetadata?.length) {
                throw "No data found in uploaded file."
            } else if (projectsMetadata?.some(project => !project.ProjectNo)) {
                throw "\"ProjectNo\" canot be empty"
            }
            const res = await api.post("/updateProjectMetadata", { projectsMetadata });
            if (res.status === 200) {
                showSnackbar({
                    open: true,
                    message: "Project Metadata updated successfully!",
                    variant: "success",
                });
                setImportLoading(false);
            } else {
                throw res.error;
            }
        } catch (error) {
            showSnackbar({
                open: true,
                message: error,
                variant: "error",
            });
            console.log("error", error)
            setImportLoading(false);
        }
    }

    return (
        <Card item xs={12}>
            <Grid container>
                <Grid item sx={{ p: 2 }} xs={12} sm={12} md={5.95}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2, justifyContent: 'center', height: "100%" }}>
                        <Typography variant="body1" color="GrayText" sx={{ whiteSpace: 'pre-line', mb: 2 }}>
                            {`Download all existing project metadata.`}
                        </Typography>
                        <Button
                            sx={{ marginLeft: "0.5rem" }}
                            variant={"contained"}
                            size={"small"}
                            onClick={handleExportMetadata}
                            disabled={isImportLoading || isExportLoading}
                        >
                            {ExportIcon}
                            Export
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={0} sm={0} md={0.1} sx={{ py: { sm: 0, md: 2 } }}>
                    <Divider orientation="vertical" sx={{ mr: '50%' }} />
                </Grid>
                <Grid item xs={12} sm={12} md={'0'} sx={{ px: { xs: 2, md: 0 } }}>
                    <Divider />
                </Grid>
                <Grid item sx={{ p: 2 }} xs={12} sm={12} md={5.95} >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2, justifyContent: 'center', height: "100%" }}>
                        <Typography variant="body1" color="GrayText" sx={{ whiteSpace: 'pre-line', mb: 2 }}>
                            {`Upload project metadata. 
                            Please follow the below template for column naming.
                            `}
                            <Button onClick={handleImportTemplateDownload} sx={{ mt: 1, textTransform: 'none' }} size={"small"} variant="text">Project_Metadata_Template.xlsx</Button>
                        </Typography>
                        <Button
                            sx={{ marginLeft: "0.5rem" }}
                            component="label"
                            variant={"contained"}
                            size={"small"}
                            disabled={isImportLoading || isExportLoading}
                        >
                            {ImportIcon}
                            Import
                            <VisuallyHiddenInput type="file" onChange={handleImportMetadata} />
                        </Button>
                        <Typography variant="caption" color="ActiveCaption" sx={{ whiteSpace: 'pre-line', mt: 2 }}>
                            {`*If the provided Project Number matches an existing project metadata, 
                            this action will override the metadata with the newly provided data. `}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Snackbar
                open={snackbar.open}
                // autoHideDuration={snackbar.variant !== 'error' ? 6000 : undefined}
                onClose={() => showSnackbar({})}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
            >
                <Alert
                    onClose={() => showSnackbar({})}
                    severity={snackbar.variant}
                    sx={{ width: "100%" }}
                    elevation={6}
                    variant="filled"
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Card >
    );
};

export default ManageMetadata;
