import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

import { Box, Button, Divider, Grid } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { AdminPanelSettings } from "@mui/icons-material";
// import PersonAdd from '@mui/icons-material/PersonAdd';
import { useRoles, useUserName } from "../Common/Utils";
import { useMsal } from "@azure/msal-react";

const Header = styled(Grid)(() => ({
  position: "fixed",
  display: "flex",
  width: "100%",
  height: "58px",
  flexDirection: "row",
  justifyContent: "space-between",
  background: "linear-gradient(to right, #009688 0%, #43a047 100%)",
  // background: "linear-gradient(to right, #09203f 60%, #537895 95%)",
  zIndex: 10,
  boxShadow: "0px 0px 15px 2px rgba(0,0,0,0.2)",
}));

const NavButton = styled(Button)(({ isActive }) => ({
  //   textTransform: 'none',
  height: "100%",
  padding: "0 1.5rem",
  borderRadius: 0,
  color: "white",
  borderTop: "4px solid transparent",
  borderBottom: "4px solid transparent",
  ...(isActive
    ? {
      borderBottom: "4px solid white",
      fontWeight: "700 !imortant",
      backgroundColor: "#fff2",
    }
    : {}),
}));

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userName = useUserName();
  const { instance, accounts } = useMsal();
  const userRoles = useRoles();
  const isAdmin = userRoles?.some((role) => role.roleName === "Admin");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignout = async () => {
    // The account's ID Token must contain the login_hint optional claim to avoid the account picker
    localStorage.clear();
    await instance.logoutRedirect({ account: accounts[0] });
    navigate("/");
  };
  useEffect(() => {
    if (location.pathname.includes("/dashboard")) {
      document.title = "EwingCole Eco - Dashboard";
    } else if (location.pathname.includes("/projects")) {
      document.title = "EwingCole Eco - Projects";
    } else if (location.pathname.includes("/admin")) {
      document.title = "EwingCole Eco - Admin";
    } else {
      document.title = "EwingCole Eco";
    }
  }, []);

  return (
    <Box
      sx={{
        height: "58px",
      }}
    >
      <Header>
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Box
            alt={"EwingCole Eco"}
            component={"img"}
            src={"/applogo.jpeg"}
            sx={{
              height: "100%",
              fontFamily: "Montserrat",
              color: "white",
              backgroundColor: "white",
              padding: "0.5rem 1rem",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              userSelect: "none",
              letterSpacing: "1px",
            }}
          />
          <NavButton
            variant="text"
            color="primary"
            size="small"
            onClick={() => navigate("/dashboard")}
            isActive={location.pathname.includes("/dashboard")}
          >
            Dashboard
          </NavButton>
        </Box>
        <Tooltip title="Account settings">
          <Button
            startIcon={
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  backgroundColor: "#0009",
                  color: "white",
                }}
              ></Avatar>
            }
            onClick={handleClick}
            size="small"
            variant="text"
            sx={{ px: 2, color: "white" }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            {userName}
          </Button>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 50,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {/* <MenuItem onClick={handleClose}>
                        <Avatar /> Profile
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <Avatar /> My account
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <PersonAdd fontSize="small" />
                        </ListItemIcon>
                        Add another account
                    </MenuItem> */}
          {isAdmin && (
            <MenuItem onClick={() => navigate("/admin")}>
              <ListItemIcon>
                <AdminPanelSettings fontSize="small" />
              </ListItemIcon>
              Admin
            </MenuItem>
          )}
          <MenuItem onClick={handleSignout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
        {/* <Button variant="text" color="secondary" size="small" onClick={() => signout()} >Signout</Button> */}
      </Header>
      <Divider />
    </Box>
  );
};

export default Navbar;
