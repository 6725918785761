import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Alert,

  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputLabel as MUIInputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import {
  projectFields,
  projectEligibilityFileds,
  energyModelingFields,
  embodiedCarbonFields,
} from "../ProjectEditor.config";
import Autocomplete from "../../Common/Autocomplete";

export const FormContainer = styled(Grid)(() => ({
  alignItems: "center",
  overflowY: "auto",
  height: "100%",
  padding: "1rem",
  width: "100%",
  backgroundColor: "white",
}));

export const FormRow = styled(Grid)(() => ({
  padding: "1rem 1rem 0rem 1rem",
  "& >label": {
    textAlign: "start",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "5px",
    "& > .MuiFormLabel-asterisk": {
      color: "red",
    },
  },
}));

export const PROJECT_INFO_NOTES = `This basic project
info helps us keep track of your project moving forward. Once you fill this out
once, you won't have to provide it for future reports. If there is a change to
this data after the first report, please email <a href="mailto:SustainabilityCommitments@ewingcole.com">SustainabilityCommitments@ewingcole.com</a>.
<ul>
	<li>Building Name:</li>
	<ul>
		<li>If project has multiple buildings, please include the Building Name for identification along with the Project Name (ex: WuXi, DP-1 Building)</li>
		<li>Only submit data for one building per form; multiple buildings will require a separate form for each qualifying building.</li>
	</ul>
</ul>`;

export const InputLabel = styled(MUIInputLabel)(() => ({
  marginBottom: "8px",
}));

export const ProjectInfo = (props) => {
  const {
    projectInfo,
    setProjectInfo,
    optns,
    projectsMetaData,
    disableEditing,
  } = props;

  const location = useLocation();
  const handleProjectNumberChange = (_projectNo) => {
    if (_projectNo) {
      const _project = projectsMetaData.find(
        (_p) => _p.ProjectNo === _projectNo
      );
      setProjectInfo({
        ...projectInfo,
        projectNumber: _projectNo,
        projectName: _project.Name,
        companyName: _project.CompanyName,
        projectPrincipal: _project.ProjectPrincipal,
        projectStatus: _project.ProjectStatus,
        projectMarket: _project.Market,
        officeLocation: _project.Office,
        originatingOpportunity: _project.OriginatingOpportunity,
        projectSalesLeader: _project.ProjectSalesLeader
      });
    }
  };
  return (
    <FormContainer container>
      <FormRow item xs={12} sm={4} key={"projectNumber"}>
        <Box
          component={"span"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <InputLabel required htmlFor={"projectNumber"}>
            Project Number
          </InputLabel>
          <Autocomplete
            id="combo-box-demo"
            options={optns}
            size={"small"}
            selectedOptions={
              location?.state?.project?.projectInfo?.projectNumber ||
              projectInfo?.projectNumber
            }
            onChange={handleProjectNumberChange}
            fullWidth
            disableClearable
            disabled={!location?.state?.isNewProject}
          />
        </Box>
      </FormRow>
      {projectFields.map((config, index) => (
        <FormRow item xs={12} sm={4} key={config.key + index}>
          <Box
            component={"span"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <InputLabel required={config.required} htmlFor={config.key}>
              {config.label}
            </InputLabel>
            {config.type === "text" ? (
              <TextField
                sx={{ width: "100%" }}
                id={config.key}
                type={config.type}
                value={projectInfo?.[config.key]}
                onChange={(e) =>
                  setProjectInfo({
                    ...projectInfo,
                    [config.key]: e.target.value,
                  })
                }
                placeholder={config.placeholder}
                required={true}
                size={"small"}
                disabled={
                  disableEditing || (!['name', 'number', 'city', 'street', 'postalCode'].includes(config.key) && (
                    config.disabled ||
                    !projectInfo?.projectNumber ||
                    location?.state?.project?.projectInfo?.projectNumber)
                  )}
              />
            ) : config.type === "select" ? (
              <Select
                sx={{ width: "100%" }}
                id={config.key}
                value={projectInfo[config.key]}
                onChange={(e) =>
                  setProjectInfo({
                    ...projectInfo,
                    [config.key]: e.target.value,
                  })
                }
                size={"small"}
                disabled={config.disabled || !projectInfo?.projectNumber}
              >
                {config?.options.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            ) : config.type === "autocomplete" ? (
              <Autocomplete
                placeholder={config.placeholder}
                freeSolo={config.freeSolo}
                multiple={config.multiple}
                disabled={disableEditing || (!['state', 'country'].includes(config.key) && (
                  config.disabled ||
                  !projectInfo?.projectNumber ||
                  location?.state?.project?.projectInfo?.projectNumber)
                )}
                options={config.options}
                selectedOptions={
                  config.multiple
                    ? JSON.parse(projectInfo[config.key] || "[]")
                    : projectInfo[config.key] || ""
                }
                onChange={(selectedOptions) =>
                  setProjectInfo({
                    ...projectInfo,
                    [config.key]: config.multiple
                      ? JSON.stringify(selectedOptions)
                      : selectedOptions,
                  })
                }
              />
            ) : (
              <></>
            )}
          </Box>
        </FormRow>
      ))}
    </FormContainer>
  );
};
