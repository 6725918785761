import styled from "@emotion/styled";
import { Box } from "@mui/material";

const PageTitle = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  // justifyContent: "center",
  padding: "0.5rem 1rem",
  fontFamily: "Albert Sans",
}));

export default PageTitle;
