import React, { useContext, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useLocation } from "react-router-dom";

export const useUserName = () => {
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const session = localStorage.getItem("session");
    if (session) {
      const token = JSON.parse(session).token;
      const decodedToken = jwt_decode(token);
      setUserName(decodedToken.name);
    }
  }, []);

  return userName;
};

export const useUserId = () => {
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const session = localStorage.getItem("session");
    if (session) {
      const token = JSON.parse(session).token;
      const decodedToken = jwt_decode(token);
      setUserId(decodedToken.unique_name);
    }
  }, []);

  return userId;
};

export const RolesContext = React.createContext([]);
export const useRoles = () => {
  const { userRoles } = useContext(RolesContext);
  return userRoles;
};
