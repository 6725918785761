import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'

import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'

const msalConfiguration = {
    auth: {
        clientId: 'a05dec0f-f7bd-4fce-a5bd-09e64f07354d',
        authority: 'https://login.microsoftonline.com/d140cdff-26d8-4c2d-a872-6100cc5ee93e',
        redirectUri: '/auth',
        navigateToLoginRequestUrl: false,
        postLogoutRedirectUri: '/',
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false
    }
}

export const pca = new PublicClientApplication(msalConfiguration)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <MsalProvider instance={pca}>
                <App />
            </MsalProvider>
        </BrowserRouter>
    </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
