import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Card } from "../../Common/Card";
import {
  MEPenergyModelingFields,
  MEPAdditionalEmbodiedCarbonTrackingQuestions,
} from "../ProjectEditor.config";

import { Question } from "../../Common/Question/Question";
import { Question as QuestionV2 } from "../../Common/QuestionV2/Question";
import { Label } from "../../Common/Label/Label";
import { MultiSelect } from "../../Common/MultiSelect/MultiSelect";
import {
  FormContainer,
  FormRow,
  InputLabel,
  ProjectInfo,
} from "../Common/ProjectInfo";
import Autocomplete from "../../Common/Autocomplete";
import { Add, CloudUpload, Info, InfoOutlined } from "@mui/icons-material";
import SectionNotes from "../../Common/SectionNotes/SectionNotes";
import uploadFileToBlob from "../../../services/azureBlobService";
import styled from "@emotion/styled";
import { ProjectPhase } from "../AIA2030/AIA2030";
import { getBlobLink } from "../../../services/azureBlobService";

export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});


const EnergyModeling = (props) => {
  const { newProject, setNewProject, disableEditing } = props;

  const handleChange = (key, value) => {
    const otherFileds = {};
    if (key === "hasEnergyModelingPerformed" && !value) {
      MEPenergyModelingFields.forEach((field) => {
        otherFileds[field.key] = null;
      });
    }
    setNewProject({
      ...newProject,
      ...otherFileds,
      [key]: value,
    });
  };

  useEffect(() => {
    if (!newProject?.hasEnergyModelingPerformed) {
      handleChange("hasEnergyModelingPerformed", false);
    }
  }, []);

  return (
    <FormContainer
      container
      style={disableEditing ? { opacity: 0.4, pointerEvents: "none" } : {}}
    >
      {MEPenergyModelingFields.map((config, index) => (
        <FormRow item xs={12} sm={config?.sm || 4} key={config.key + index}>
          <Box
            component={"span"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              ...config?.sx,
            }}
          >
            <InputLabel required={config.required} htmlFor={config.key}>
              {config.label}
            </InputLabel>
            {config.type === "question" ? (
              <Question
                isChecked={newProject[config.key]}
                desc={config.desc}
                onChange={(checked) => handleChange(config.key, checked)}
              />
            ) : config.type === "text" ? (
              <TextField
                sx={{ width: "100%" }}
                id={config.key}
                type={config.inputType || config.type}
                value={newProject[config.key] || ""}
                onChange={(e) => handleChange(config.key, e.target.value)}
                placeholder={config.placeholder}
                required={true}
                size={"small"}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              />
            ) : config.type === "select" ? (
              <Select
                sx={{ width: "100%", textAlign: "start" }}
                id={config.key}
                value={newProject[config.key]}
                key={newProject[config.key]}
                onChange={(e) => handleChange(config.key, e.target.value)}
                size={"small"}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              >
                {config?.options.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            ) : config.type === "label" ? (
              <Label desc={config.desc} />
            ) : config.type === "autocomplete" ? (
              <Autocomplete
                placeholder={config.placeholder}
                freeSolo={config.freeSolo}
                multiple={config.multiple}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
                options={config.options}
                selectedOptions={
                  config.multiple
                    ? JSON.parse(newProject[config.key] || "[]")
                    : newProject[config.key] || ""
                }
                onChange={(selectedOptions) =>
                  handleChange(
                    config.key,
                    config.multiple
                      ? JSON.stringify(selectedOptions)
                      : selectedOptions
                  )
                }
              />
            ) : (
              <></>
            )}
          </Box>
        </FormRow>
      ))}
    </FormContainer>
  );
};


const ChipContent = ({ label, value }) => {
  return (
    <Box display={"flex"} flexDirection={"row"} alignItems={"center"} p={0.4}>
      {label}:
      <Box
        component={"span"}
        sx={{
          ml: 1,
          border: "1px solid #4442",
          borderRadius: "5px",
          backgroundColor: "white",
          padding: "5px",
        }}
      >
        {value || <span style={{ opacity: 0.3 }}>N/A</span>}
      </Box>
    </Box>
  );
};

const EquipmentEPDInformation = (props) => {
  const { newProject, setNewProject, disableEditing, showSnackbar } = props;
  const [equipmentDiscipline, setEquipmentDiscipline] = useState("");
  const [specificEquipmentEPDInfo, setSpecificEquipmentEPDInfo] = useState("");
  const [specificEquipmentEPDInfoPDF, setSpecificEquipmentEPDInfoPDF] = useState("");
  const [embodiedCarbonGWP, setEmbodiedCarbonGWP] = useState("");
  const [otherEPGorGWPInfo, setOtherEPGorGWPInfo] = useState("");

  const handleChange = (key, value) => {
    const otherFileds = {};
    if (key === "requestedEPDForProjectEquip" && !value) {
      otherFileds["epdInfo"] = "[]";
    }
    setNewProject({
      ...newProject,
      ...otherFileds,
      [key]: value,
    });
  };

  const handleAdd = () => {
    const _epdInfo = [
      ...JSON.parse(newProject?.epdInfo || "[]"),
      {
        equipmentDiscipline,
        specificEquipmentEPDInfo,
        specificEquipmentEPDInfoPDF,
        embodiedCarbonGWP,
        otherEPGorGWPInfo,
      },
    ];
    setNewProject({
      ...newProject,
      epdInfo: JSON.stringify(_epdInfo),
    });
    setEquipmentDiscipline("");
    setSpecificEquipmentEPDInfo("");
    setSpecificEquipmentEPDInfoPDF("");
    setEmbodiedCarbonGWP("");
    setOtherEPGorGWPInfo("");
  };

  const handleRemove = (index) => {
    const _epdInfo = [...JSON.parse(newProject?.epdInfo || "[]")];
    _epdInfo.splice(index, 1);
    setNewProject({ ...newProject, epdInfo: JSON.stringify(_epdInfo) });
  };

  const handleUpload = (e) => {
    const file = e.target.files?.[0];
    if (file?.name?.split(".").at(-1)?.toLowerCase() !== "pdf") {
      showSnackbar({
        open: true,
        message: "Only PDF files are allowed currently.",
        variant: "error",
      });
      return;
    }
    if (file.name) uploadFileToBlob(file);

    setSpecificEquipmentEPDInfoPDF(`https://ecodatabase.blob.core.windows.net/meppdfs/${file.name}`);
  };

  return (
    <FormContainer
      container
      style={disableEditing ? { opacity: 0.4, pointerEvents: "none" } : {}}
    >
      <FormRow item xs={12} mb={2}>
        <Box
          component={"span"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            float: "right",
            width: "fit-content",
            maxWidth: "300px",
            "& > label": {
              textWrap: "wrap",
            },
          }}
        >
          <InputLabel>
            Requested Environmental Product Declaration (EPD) for project equipment?
          </InputLabel>
          <Question
            isChecked={newProject.requestedEPDForProjectEquip}
            // desc={config.desc}
            onChange={(checked) => handleChange('requestedEPDForProjectEquip', checked)}
          />
        </Box>
      </FormRow>

      <FormRow item xs={12} sm={4}>
        <Box
          component={"span"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <InputLabel htmlFor={"equipmentDiscipline"}>{"Equipment Discipline"}</InputLabel>
          <Select
            sx={{ width: "100%", textAlign: "start" }}
            id={"equipmentDiscipline"}
            value={equipmentDiscipline}
            onChange={(e) => setEquipmentDiscipline(e.target.value)}
            size={"small"}
            disabled={!newProject.requestedEPDForProjectEquip}
          >
            {["Mechanical",
              "Electrical",
              "Plumbing"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </Select>
        </Box>
      </FormRow>

      <FormRow item xs={12} sm={4}>
        <Box
          component={"span"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <InputLabel htmlFor={"embodiedCarbonGWP"}>
            {"GWP, LCA Stages A1-A3 (kgCO2e)"}
          </InputLabel>
          <TextField
            sx={{ width: "100%" }}
            id={"embodiedCarbonGWP"}
            type={"number"}
            value={embodiedCarbonGWP}
            onChange={(e) => setEmbodiedCarbonGWP(e.target.value)}
            placeholder={""}
            size={"small"}
            disabled={!newProject.requestedEPDForProjectEquip}
          />
        </Box>
      </FormRow>

      <FormRow item xs={12} sm={4}>
        <Box
          component={"span"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <InputLabel htmlFor={"specificEquipmentEPDInfoPDF"}>Specific Equipment with EPD Information (PDFs)</InputLabel>

          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUpload />}
            disabled={!newProject.requestedEPDForProjectEquip}
          >
            Upload file
            <VisuallyHiddenInput type="file" onChange={handleUpload} />
          </Button>

          {specificEquipmentEPDInfoPDF && (
            <Link
              href={getBlobLink(specificEquipmentEPDInfoPDF)}
              underline="hover"
              target="_blank"
              rel="noreferrer"
            >
              {specificEquipmentEPDInfoPDF.split("/").at(-1)}
            </Link>
          )}
        </Box>
      </FormRow>

      <FormRow item xs={12}>
        <Box
          component={"span"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <InputLabel htmlFor={"specificEquipmentEPDInfo"}>{"Specific Equipment with EPD Information?"}</InputLabel>

          <TextField
            sx={{ width: "100%" }}
            id={"specificEquipmentEPDInfo"}
            type={"text"}
            value={specificEquipmentEPDInfo}
            onChange={(e) => setSpecificEquipmentEPDInfo(e.target.value)}
            placeholder={""}
            size={"small"}
            disabled={!newProject.requestedEPDForProjectEquip}
          />
        </Box>
      </FormRow>
      <FormRow item xs={12} sm={10}>
        <Box
          component={"span"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <InputLabel htmlFor={"otherEPGorGWPInfo"}>{"Other Information about EPD or GWP from Rep or Manufacturer?"}</InputLabel>

          <TextField
            sx={{ width: "100%" }}
            id={"otherEPGorGWPInfo"}
            type={"text"}
            value={otherEPGorGWPInfo}
            onChange={(e) => setOtherEPGorGWPInfo(e.target.value)}
            placeholder={""}
            size={"small"}
            disabled={!newProject.requestedEPDForProjectEquip}
          />
        </Box>
      </FormRow>

      <FormRow item xs={12} sm={2}>
        <Button
          sx={{ mt: "30px" }}
          fullWidth
          startIcon={<Add />}
          variant="contained"
          color="info"
          onClick={handleAdd}
          disabled={!newProject.requestedEPDForProjectEquip}
        // disabled={
        //   !material ||
        //   !type ||
        //   !quantity ||
        //   (material === "Concrete" && !portlandCementContent)
        // }
        >
          Add
        </Button>
      </FormRow>




      <FormRow item xs={12} sm={12} sx={{ my: 2 }}>
        <Box
          component={"span"}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            maxWidth: "100%",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          {JSON.parse(newProject?.epdInfo || "[]").map(
            (_info, index) => (
              <Chip
                key={index}
                onDelete={() => handleRemove(index)}
                sx={{ height: "auto", fontSize: "14px", textAlign: "left" }}
                // color="primary"
                // variant="outlined"
                label={
                  <Box display={"flex"} flexDirection={"column"} p={2}>
                    <ChipContent label={"Equipment Discipline"} value={_info.equipmentDiscipline} />
                    <ChipContent label={"Specific Equipment with EPD Information"} value={_info.specificEquipmentEPDInfo} />
                    <ChipContent label={"Specific Equipment with EPD Information (PDF)"} value={_info.specificEquipmentEPDInfoPDF && (
                      <Link
                        href={getBlobLink(_info.specificEquipmentEPDInfoPDF)}
                        underline="hover"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {_info.specificEquipmentEPDInfoPDF.split("/").at(-1)}
                      </Link>
                    )} />
                    <ChipContent label={"GWP, LCA Stages A1-A3 (kgCO2e)"} value={_info.embodiedCarbonGWP} />
                    <ChipContent
                      label={"Other Information about EPD or GWP from Rep or Manufacturer"}
                      value={_info.otherEPGorGWPInfo}
                    />
                  </Box>
                }
              />
            )
          )}
        </Box>
      </FormRow>
    </FormContainer>
  );
};

const RefrigerantPipingInformation = (props) => {
  const { newProject, setNewProject, disableEditing } = props;
  const [refrigerantSystemsUsedInProject, setRefrigerantSystemsUsedInProject] = useState('');
  const [refrigerantUsed, setRefrigerantUsed] = useState('')
  const [requestedLowGWPRefrigerant, setRequestedLowGWPRefrigerant] = useState()
  const [eachRefrigerantCharge, setEachRefrigerantCharge] = useState('')


  const handleChange = (key, value) => {
    const otherFileds = {};
    if (key === "projectRefrigerantPipingWithinScope" && !value) {
      otherFileds["refrigerantPipingInfo"] = "[]";
    }
    setNewProject({
      ...newProject,
      ...otherFileds,
      [key]: value,
    });
  };
  const handleAdd = () => {
    const _refrigerantPipingInfo = [
      ...JSON.parse(newProject?.refrigerantPipingInfo || "[]"),
      {
        refrigerantSystemsUsedInProject,
        refrigerantUsed,
        requestedLowGWPRefrigerant,
        eachRefrigerantCharge,
      },
    ];
    setNewProject({
      ...newProject,
      refrigerantPipingInfo: JSON.stringify(_refrigerantPipingInfo),
    });
    setRefrigerantSystemsUsedInProject("");
    setRefrigerantUsed("");
    setRequestedLowGWPRefrigerant();
    setEachRefrigerantCharge("");
  };

  const handleRemove = (index) => {
    const _refrigerantPipingInfo = [...JSON.parse(newProject?.refrigerantPipingInfo || "[]")];
    _refrigerantPipingInfo.splice(index, 1);
    setNewProject({ ...newProject, refrigerantPipingInfo: JSON.stringify(_refrigerantPipingInfo) });
  };

  return (
    <FormContainer
      container
      style={disableEditing ? { opacity: 0.4, pointerEvents: "none" } : {}}
    >
      <FormRow item xs={12} mb={2}>
        <Box
          component={"span"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            float: "right",
            width: "fit-content",
            maxWidth: "300px",
            "& > label": {
              textWrap: "wrap",
            },
          }}
        >
          <InputLabel>
            Does the project have refrigerant piping within the scope?
          </InputLabel>
          <Question
            isChecked={newProject.projectRefrigerantPipingWithinScope}
            // desc={config.desc}
            onChange={(checked) => handleChange('projectRefrigerantPipingWithinScope', checked)}
          />
        </Box>
      </FormRow>

      <FormRow item xs={12} sm={6}>
        <Box
          component={"span"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <InputLabel htmlFor={"refrigerantSystemsUsedInProject"}>{"Refrigerant Systems used in Project"}</InputLabel>
          <Autocomplete
            placeholder={`Select from the provided options, For "other" type-in directly`}
            freeSolo={true}
            disabled={!newProject.projectRefrigerantPipingWithinScope}
            options={[
              "Split Systems",
              "VRF Systems",
              "Packaged Rooftop DX Coils",
              "Chillers",
              "Food Service Fridges",
            ]}
            selectedOptions={refrigerantSystemsUsedInProject}
            onChange={(_refrigerantSystemsUsedInProject) =>
              setRefrigerantSystemsUsedInProject(_refrigerantSystemsUsedInProject)
            }
          />
        </Box>
      </FormRow>

      <FormRow item xs={12} sm={6}>
        <Box
          component={"span"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <InputLabel htmlFor={"refrigerantUsed"}>{"Refrigerant Used (Maybe use column system with a 2nd column with charge per ref.)"}</InputLabel>
          <Autocomplete
            placeholder={`Select from the provided options, For "other" type-in directly`}
            freeSolo={true}
            disabled={!newProject.projectRefrigerantPipingWithinScope}
            options={[
              "R-134a",
              "R-32",
              "R-123",
              "R-1233zd",
              "R-514A",
              "R-513A",
              "R-515B",
              "R-1234ze",
              "R-22",
              "R-410A",
              "R-466A",
              "R-452B",
              "R-454B",
            ]}
            selectedOptions={refrigerantUsed}
            onChange={(_refrigerantUsed) =>
              setRefrigerantUsed(_refrigerantUsed)
            }
          />
        </Box>
      </FormRow>
      <FormRow item xs={12} sm={12}>
        <Box
          component={"span"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <InputLabel htmlFor={"requestedLowGWPRefrigerant"}>{"Requested Low GWP Refrigerant"}</InputLabel>
          <QuestionV2
            isChecked={requestedLowGWPRefrigerant}
            disabled={!newProject.projectRefrigerantPipingWithinScope}
            // desc={config.desc}
            onChange={(checked) => setRequestedLowGWPRefrigerant(checked)}
          />
        </Box>
      </FormRow>
      <FormRow item xs={12} sm={10}>
        <Box
          component={"span"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <InputLabel htmlFor={"eachRefrigerantCharge"}>
            {"Refrigerant Charge (kg) for each refrigerant used on the project"}
          </InputLabel>
          <TextField
            sx={{ width: "100%" }}
            id={"eachRefrigerantCharge"}
            type={"number"}
            value={eachRefrigerantCharge}
            onChange={(e) => setEachRefrigerantCharge(e.target.value)}
            placeholder={""}
            size={"small"}
            disabled={!newProject.projectRefrigerantPipingWithinScope}
          />
        </Box>
      </FormRow>

      <FormRow item xs={12} sm={2}>
        <Button
          sx={{ mt: "30px" }}
          fullWidth
          startIcon={<Add />}
          variant="contained"
          color="info"
          onClick={handleAdd}
          disabled={!newProject.projectRefrigerantPipingWithinScope}
        // disabled={
        //   !material ||
        //   !type ||
        //   !quantity ||
        //   (material === "Concrete" && !portlandCementContent)
        // }
        >
          Add
        </Button>
      </FormRow>




      <FormRow item xs={12} sm={12} sx={{ my: 2 }}>
        <Box
          component={"span"}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            maxWidth: "100%",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          {JSON.parse(newProject?.refrigerantPipingInfo || "[]").map(
            (_info, index) => (
              <Chip
                key={index}
                onDelete={() => handleRemove(index)}
                sx={{ height: "auto", fontSize: "14px", textAlign: "left" }}
                // color="primary"
                // variant="outlined"
                label={
                  <Box display={"flex"} flexDirection={"column"} p={2}>
                    <ChipContent label={"Refrigerant Systems used in Project"} value={_info.refrigerantSystemsUsedInProject} />
                    <ChipContent label={"Refrigerant Used"} value={_info.refrigerantUsed} />
                    <ChipContent label={"Requested Low GWP Refrigerant"} value={typeof _info.requestedLowGWPRefrigerant === 'boolean' ? _info.requestedLowGWPRefrigerant ? 'Yes' : 'No' : _info.requestedLowGWPRefrigerant} />
                    <ChipContent
                      label={"Refrigerant Charge (kg) for each refrigerant used on the project"}
                      value={_info.eachRefrigerantCharge}
                    />
                  </Box>
                }
              />
            )
          )}
        </Box>
      </FormRow>
    </FormContainer>
  );
};

const AdditionalEmbodiedCarbonTracking = (props) => {
  const { newProject, setNewProject, disableEditing } = props;

  const handleChange = (key, value) => {
    const otherFileds = {};
    if (key === "isProjectTrackingEmbodiedCarbon" && !value) {
      // MEPAdditionalEmbodiedCarbonTrackingQuestions.forEach((field) => {
      otherFileds.projectTrackingEmbodiedCarbon = '[]';
      // });
    }
    setNewProject({
      ...newProject,
      ...otherFileds,
      [key]: value,
    });
  };

  useEffect(() => {
    if (!newProject?.projectTrackingEmbodiedCarbon) {
      handleChange("projectTrackingEmbodiedCarbon", false);
    }
  }, []);

  return (
    <FormContainer
      container
      style={disableEditing ? { opacity: 0.4, pointerEvents: "none" } : {}}
    >
      {MEPAdditionalEmbodiedCarbonTrackingQuestions.map((config, index) => (
        <FormRow item xs={12} sm={config?.sm || 4} key={config.key + index}>
          <Box
            component={"span"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              ...config?.sx,
            }}
          >
            <InputLabel required={config.required} htmlFor={config.key}>
              {config.label}
            </InputLabel>
            {config.type === "question" ? (
              <QuestionV2
                isChecked={newProject[config.key]}
                desc={config.desc}
                onChange={(checked) => handleChange(config.key, checked)}
              />
            ) : config.type === "text" ? (
              <TextField
                sx={{ width: "100%" }}
                id={config.key}
                type={config.inputType || config.type}
                value={newProject[config.key] || ""}
                onChange={(e) => handleChange(config.key, e.target.value)}
                placeholder={config.placeholder}
                required={true}
                size={"small"}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              />
            ) : config.type === "select" ? (
              <Select
                sx={{ width: "100%", textAlign: "start" }}
                id={config.key}
                value={newProject[config.key]}
                key={newProject[config.key]}
                onChange={(e) => handleChange(config.key, e.target.value)}
                size={"small"}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              >
                {config?.options.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            ) : config.type === "multiselect" ? (
              <MultiSelect
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
                options={config.options}
                selectedOptions={(newProject[config.key] || "")
                  .split(",")
                  .filter(Boolean)}
                onChange={(selectedOptions) =>
                  handleChange(
                    config.key,
                    selectedOptions?.filter(Boolean).join(",")
                  )
                }
              />
            ) : config.type === "autocomplete" ? (
              <Autocomplete
                placeholder={config.placeholder}
                freeSolo={config.freeSolo}
                multiple={config.multiple}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
                options={config.options}
                selectedOptions={
                  config.multiple
                    ? JSON.parse(newProject[config.key] || "[]")
                    : newProject[config.key] || ""
                }
                onChange={(selectedOptions) =>
                  handleChange(
                    config.key,
                    config.multiple
                      ? JSON.stringify(selectedOptions)
                      : selectedOptions
                  )
                }
              />
            ) : (
              <></>
            )}
          </Box>
        </FormRow>
      ))}
    </FormContainer>
  );
};

const COMP_MAPPING = {
  projectInfo: ProjectInfo,
  projectPhase: ProjectPhase,
  energyModeling: EnergyModeling,
  equipmentEPDInformation: EquipmentEPDInformation,
  refrigerantPipingInformation: RefrigerantPipingInformation,
  additionalEmbodiedCarbonTracking: AdditionalEmbodiedCarbonTracking,
};

const MEP2040 = (props) => {
  const {
    subSections,
    newProject,
    setNewProject,
    optns,
    projectsMetaData,
    isNewProject,
    projectInfo,
    setProjectInfo,
    disabledEditing,
    showSnackbar,
  } = props;

  const [expandedSections, setExpandedSections] = useState({});

  const handleExpand = (section) => {
    setExpandedSections((_expandedSections) => ({
      ..._expandedSections,
      [section.type]: !_expandedSections[section.type],
    }));
  };

  return subSections?.map((section) => {
    const SubSection = COMP_MAPPING[section.type];
    return (
      <Card sx={{
        mt: 2,
        position: section.type === 'projectPhase' && 'sticky',
        top: section.type === 'projectPhase' && '50px',
        zIndex: section.type === 'projectPhase' && 1,
      }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "1rem",
              backgroundColor: "#fff5",
              width: "100%",
            }}
          >
            <Box
              component={Typography}
              variant="h6"
              sx={{ fontFamily: "Albert sans" }}
            >
              {section?.label}
              <IconButton
                color={"primary"}
                onClick={() => handleExpand(section)}
                disabled={!section.notes}
              >
                {expandedSections[section.type] ? <Info /> : <InfoOutlined />}
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Divider />
        <SectionNotes
          notes={section.notes}
          display={expandedSections[section.type]}
        />

        <Box>
          <SubSection
            projectInfo={projectInfo}
            setProjectInfo={setProjectInfo}
            newProject={newProject}
            setNewProject={setNewProject}
            optns={optns}
            projectsMetaData={projectsMetaData}
            disableEditing={disabledEditing || (section.type !== 'projectInfo' && isNewProject)}
            showSnackbar={showSnackbar}
          />
        </Box>
      </Card>
    );
  });
};

export default MEP2040;
