import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import api from "../api/api";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { RolesContext } from "./Common/Utils";

const AuthHandler = () => {
  const { code } = useParams();
  const { instance, inProgress, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const navigate = useNavigate();
  const { setUserRoles } = useContext(RolesContext);

  useEffect(() => {
    (async () => {
      console.log("handleRedirectPromise");
      const resp = await instance.handleRedirectPromise();
      console.log("resp", resp);
      if (resp?.account) {
        const token = resp.accessToken;

        localStorage.setItem(
          "session",
          JSON.stringify({ token, resp: resp, })
        );
        const decodedToken = jwt_decode(token);
        const res = await api.get("/getUserRoles", {
          params: {
            email: decodedToken.unique_name,
            displayName: decodedToken.name,
            fname: decodedToken.given_name,
            lname: decodedToken.family_name,
          },
        });
        localStorage.setItem(
          "session",
          JSON.stringify({ token, resp: resp, userRoles: res.data })
        );
        setUserRoles(JSON.parse(res.data.roles || "[]"));
        navigate("/dashboard");
      }
    })();
  }, []);

  // useEffect(() => {
  //     if (!accessToken && inProgress === InteractionStatus.None) {
  //         console.log("accounts", accounts)
  //         const accessTokenRequest = {
  //             scopes: ['user.read'],
  //             // account: accounts[0],
  //         }

  //         // instance.loginRedirect(accessTokenRequest)
  //         // instance
  //         //     .acquireTokenPopup(accessTokenRequest)
  //         //     .then(function (accessTokenResponse) {
  //         //         // Acquire token interactive success
  //         //         let _accessToken = accessTokenResponse.accessToken
  //         //         setAccessToken(_accessToken)
  //         //     })
  //         //     .catch(function (error) {
  //         //         console.log("acquireTokenPopup", error)
  //         //         // Acquire token interactive failure
  //         //         console.log(error)
  //         //     })
  //         // instance
  //         //     .acquireTokenSilent(accessTokenRequest)
  //         //     .then((accessTokenResponse) => {
  //         //         // Acquire token silent success
  //         //         let _accessToken = accessTokenResponse.accessToken
  //         //         setAccessToken(_accessToken)
  //         //         // Call your API with token
  //         //         // callApi(accessToken).then((response) => {
  //         //         //     setApiData(response)
  //         //         // })
  //         //     })
  //         //     .catch((error) => {
  //         //         console.log("acquireTokenSilent", error)
  //         //         if (error instanceof InteractionRequiredAuthError) {

  //         //         }
  //         //         console.log(error)
  //         //     })
  //     }
  // }, [instance, inProgress, accessToken])

  useEffect(() => {
    if (code) {
      //Handle auth
      console.log("Auth code", code);
    }
  }, [code]);
};

export default AuthHandler;
