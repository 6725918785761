import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import { Grid, Typography, Divider, Box } from "@mui/material";
import { Card } from "../Common/Card";
import { useUserName } from "../Common/Utils";
import Projects from "../Projects/Projects/Projects";
import PageTitle from "../Common/PageTitle";

const Dashboard = () => {
  const userName = useUserName();

  return (
    <>
      <Navbar />

      <Grid container p={2}>
        <Card
          item
          xs={12}
          sx={{
            fontSize: "20px",
            textAlign: "start",
            padding: "1rem 1rem",
          }}
        >
          Welcome, <strong>{userName}</strong>
        </Card>
      </Grid>
      <Grid container pb={2} px={2}>
        <Card item xs={12}>
          <PageTitle component={Typography} variant="h6">
            Your Projects
          </PageTitle>
          <Divider />
          <Box sx={{ p: 2 }}>
            <Projects />
          </Box>
        </Card>

      </Grid>

    </>
  );
};

export default Dashboard;
