import React, { useState, useEffect } from "react";
import api from "../../api/api";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import PageTitle from "../Common/PageTitle";
import { CheckCircle, Pending } from "@mui/icons-material";

import { Card } from "../Common/Card";

import Button from "@mui/material/Button";
import AIA2030 from "./AIA2030/AIA2030";
import SE2050 from "./SE2050/SE2050";
import MEP2040 from "./MEP2040/MEP2040";
import AIAMaterialsPledgeSurvey from "./AIAMaterialsPledgeSurvey/AIAMaterialsPledgeSurvey";
import { useRoles } from "../Common/Utils";
import { Progress } from "../Common/Progress/Progress";
import { PROJECT_INFO_NOTES } from "./Common/ProjectInfo";

const sections = [
  // { type: "projectInfo", label: "Project Info", progress: 'Not Started' },
  {
    type: "aia2030",
    label: "AIA 2030",
    progress: "Not Started",
    subSections: [
      {
        type: "projectInfo",
        label: "Project Info",
        progress: "Not Started",
        notes: PROJECT_INFO_NOTES,
      },
      {
        type: "projectPhase",
        label: "Project Phase",
        progress: "Not Started",
      },
      {
        type: "projectEligibility",
        label: "Project Eligibility",
        progress: "Not Started",
        notes: `<p style="vertical-align: middle;">Not all projects are eligible for reporting, and some may be reported in special
        circumstances even if they do not meet all criteria. If you are uncertain about a specific project, reach out to the
        2030 Co-Chairs before reporting.
      </p>
      <ul><li style="vertical-align: middle;">
        Project Types:<ul><li style="vertical-align: middle;"><b>Interior
              Only</b>: An Interior Only project is a renovation project that does not include envelope or HVAC scope in a way
            that would impact building energy use but does include lighting replacement or design.</li><li style="vertical-align: middle;"><b>Whole
              Building</b>: A Whole Building project is a new construction or renovation project that includes envelope and/or
            HVAC scope in a way that would impact building energy use.</li></ul></li>
      </ul>`,
      },
      {
        type: "energyModeling",
        label: "Energy Modeling",
        progress: "Not Started",
        notes: `
        An energy model is not required to report a project, but it helps the team understand and
        improve future performance during design. An energy model provides a baseline and
        predicted EUI which help us accurately measure the energy use reduction of a
        project. Ask the project engineers if there is a recent energy model for the
        project.
        <ul>
        <li>Energy Use (EUI): Baseline EUI is
        required for all projects. If project is not modeled, calculate your baseline
        using:</li>
          <ul>
            <li>ZeroTool: <a href="https://www.zerotool.org/zerotool/">https://www.zerotool.org/zerotool/</a></li>
            <li>EC Baseline EUI Matrix</li>
            <li>Laboratory Benchmarking Tool: <a href="https://lbt.i2sl.org/buildings/charts">https://lbt.i2sl.org/buildings/charts</a></li>
            <li>Predicted EUI is required for
            energy modeled projects.</li>
          </ul>            
        <li>Energy Use (LPD): Baseline and
        Predicted LPD are required for Interior Only projects. Find your baseline LPD
        for your use type at this link:<a href="https://aiaorg.atlassian.net/wiki/spaces/DDXWIK/pages/32801072/Set+a+Project+Baseline+Interiors+Only?src=search">https://aiaorg.atlassian.net/wiki/spaces/DDXWIK/pages/32801072/Set+a+Project+Baseline+Interiors+Only?src=search</a>.
        Talk to your Electrical Engineer or Lighting Designer for a predicted LPD.</li>
        </ul>`,
      },
      {
        type: "embodiedCarbonModeling",
        label: "Embodied Carbon",
        progress: "Not Started",
        notes: `Embodied carbon
        modeling is a new addition to the AIA 2030 Commitment tracking, and the
        following questions will help map out the general scope of Embodied Carbon
        modeling across our portfolio. More detailed Embodied Carbon modeling is
        required for the SE2050 commitment, so check whether your project is reporting
        to that commitment to get a head-start.`,
      },
    ],
  },
  {
    type: "mep2040",
    label: "MEP 2040",
    progress: "Not Started",
    subSections: [
      {
        type: "projectInfo",
        label: "Project Info",
        progress: "Not Started",
        notes: PROJECT_INFO_NOTES,
      },
      {
        type: "projectPhase",
        label: "Project Phase",
        progress: "Not Started",
      },
      {
        type: "energyModeling",
        label: "Energy Modeling",
        progress: "Not Started",
        notes: `This section tracks energy use and modeling data for your project. 
        Answer as many questions as you can in this section, based on the scope of your project. 
        For our MEP 2040 commitment, it is required that we strive to reduce operational and embodied 
        carbon across MEP systems and energy modeling reports are the best way to track operational data. 
        Provide a baseline EUI quantity as well as the predicted EUI quantity for comparison between code 
        requirements and proposed energy performance of the project. Ask the project engineers if there 
        is a recent energy model for the project if necessary.`,
      },
      {
        type: "equipmentEPDInformation",
        label: "Equipment EPD Information",
        progress: "Not Started",
        notes: `This section tracks data on whether Environmental Product Declarations (EPDs) were requested 
        for your project. If not, then answer no in this section. For our MEP 2040 commitment, it's 
        required that we request EPDs from manufacturers and/or reps. This effort will be a prerequisite 
        to move towards implementing environmentally friendly MEP system components and equipment into 
        our respected discipline's specifications.`,
      },
      {
        type: "refrigerantPipingInformation",
        label: "Refrigerant Piping Information",
        progress: "Not Started",
        notes: `This section tracks data involving refrigerants.  
        Answer as many questions as you can in this section, 
        based on the systems scope of your project. For our MEP 2040 commitment, 
        it is required that we request low-GWP refrigerant availability when 
        designing systems to reduce or eliminate GHG emissions from refrigerants. 
        We will use this data towards our goal of measuring and reporting progress 
        towards reducing carbon emissions.`,
      },
      {
        type: "additionalEmbodiedCarbonTracking",
        label: "Additional Embodied Carbon Tracking",
        progress: "Not Started",
      }
    ],
  },
  {
    type: "se2050",
    label: "SE 2050",
    progress: "Not Started",
    subSections: [
      {
        type: "projectInfo",
        label: "Project Info",
        progress: "Not Started",
        notes: PROJECT_INFO_NOTES,
      },
      {
        type: "projectPhase",
        label: "Project Phase",
        progress: "Not Started",
      },
      {
        type: "projectDetails",
        label: "Project Details",
        progress: "Not Started",
        notes: `<ul>
        <li>Primary Building Use Type:</li>
        <ul>
          <li>Primary Building Use Type makes up at least 51% of Building Area; Confirm with Architects if unknown.&nbsp;</li>
          <li>The building use type should be in the Code Analysis table located on either the Life Safety drawings or one of the lead drawings after the Cover Sheet.</li>
        </ul>
        <li>Construction Type: Limited to only buildings that meet the criteria described below. Small renovation projects may not be eligible for submission.</li>
        <ul>
          <li><span style="font-weight: bold;">New Construction</span>:
<ul><li>          Brand New building on a greenfield or brownfield site
</li><li>          New building in place of a demolished building if no existing elements are being reused
</li><li>Horizontal expansion of an existing building in which there is very little structural interaction between the old and new buildings (i.e. expansion joint between old and new buildings)</li></ul></li>
          <li><span style="font-weight: bold;">Major Renovation of Existing Building -</span>&nbsp;<b>Vertical Expansion:</b>&nbsp;Vertical expansion of an existing building (new stories added on top of existing stories)</li>
          <li><span style="font-weight: bold;">Major Renovation of Existing Building -</span>&nbsp;<b>Structural Retrofit:</b>
          Renovation that involves significant modifications to structural framing such that IEBC Code is triggered (i.e. increase over 5% of existing mass)</li>
          <li><span style="font-weight: bold;">Major Renovation of Existing Building -</span>&nbsp;<b>Other:</b>&nbsp;Retrofitting for seismic or other hazards<br>
          </li>
        </ul>
        <li>Gross Square Footage (ft2): Confirm with Architect if unknown.</li>
        <ul>
          <li>This info can usually be found from the Occupant Load Table on the Life Safety drawings.</li>
          <li><span style="font-weight: bold;">For renovation projects</span>: the gross area shall represent the total area of the existing building being renovated/retrofitted and any new area added.&nbsp; &nbsp;</li>
        </ul>
        <li>
        Mean Roof Height (ft):
        Shall match the height used for wind load calculations.<br>
        </li>
        <li>Number of Stories Above Grade:&nbsp;</li>
        <ul>
          <li>ASCE 7 defines a story above the grade plane as one in which "the floor or roof surface at the top of the story is more than 6 ft above grade plane or is more than 12 ft above the finished ground level at any point on the perimeter of the structure."</li>
          <li>Small mezzanine areas that have floor areas of less than 10% of that of an adjacent floor shall not be counted in the total number of stories.&nbsp;</li>
          <li><span style="font-weight: bold;">For renovation projects</span>:
          The number of stories shall be that of the total completed building, including the existing structure and any additions.</li>
        </ul>
        <li>Number of Stories Below Grade: Enter 0 if no below grade levels.<br>
        </li>
      </ul>
      `
      },
      {
        type: "structuralSystemInformation",
        label: "Structural System Information",
        progress: "Not Started",
        notes: `
        <ul>
          <li>Typical Column Grid Dimension, Long Direction (ft):</li>
          <ul>
            <li>An average span may be used if grid layout is irregular</li>
          </ul>
          <li>Typical Column Grid Dimension, Short Direction (ft):</li>
          <ul>
            <li>An average span may be used if grid layout is irregular</li>
          </ul>
          <li>Typical Floor Live Load (psf):</li>
          <ul>
            <li>If multiple Live Loads used, enter the Live Load that covers the most floor area</li>
          </ul>
          <li>Primary Horizontal Gravity System:</li>
          <ul>
            <li>(Above grade floor/roof framing)
            Choose the system type that makes up at least 2/3 of the floor/roof framing. Select one of the "Other" categories if it is a 50/50 split (will most likely only affect steel framed floors/roofs).</li>
            <li>(i.e. A 2-story steel frame building would have a 50/50 split between "Steel: Frame + Concrete on Metal Deck" for Level 2 and "Steel: Frame + Bare Metal Deck" for the Roof - therefore, choose "Steel: Other")</li>
            <li>Reference Section 3.9 in the "Database User Guide" for additional info</li>
          </ul>
          <li> Primary Vertical Gravity System:</li>
          <ul>
            <li>Gravity Columns/Bearing Walls)
            When there is a combination of systems like a combo of Cast-in-Place and Precast concrete choose the "Concrete: Other" option</li>
          </ul>
          <li>Primary Lateral System:</li>
          <ul>
            <li>When there is a combination of systems, like Steel Braced Frames and Steel Moment Frames, choose the "Steel: Other" option</li></ul><li>Allowable Soil Bearing Pressure (psf):<ul><li>Round up to the nearest value in the list if yours does not appear (i.e. yours is 2500 psf – therefore choose 3000 psf).</li><li>If using primarily deep foundations, enter the allowable soil bearing pressure for shallow footings on the site, if known. Enter 2000 if not known.</li>
          </ul></li>
        </ul>`
      },
      {
        type: "globalWarmingPotentialData",
        label: "Global Warming Potential Data",
        progress: "Not Started",
      },
      {
        type: "structuralMaterialQuantities",
        label: "Structural Material Quantities",
        progress: "Not Started",
      },
    ],
  },
  {
    type: "aiaMaterialsPledge",
    label: "AIA Materials Pledge",
    progress: "Not Started",
    subSections: [
      {
        type: "projectInfo",
        label: "Project Info",
        progress: "Not Started",
        notes: PROJECT_INFO_NOTES,
      },
      {
        type: "earlyDesign",
        label: "Early Design",
        progress: "Not Started",
        notes: `
      <span>This section collects information on early
      conversations and steps toward holistically responsible material
      selection. It should be completed by the
      end of Schematic Design for your project. The EwingCole SAP (Sustainability Action Plan) can be reviewed and the
      EC Materials Tracker downloaded from the <a href="https://ewingcole.sharepoint.com/sites/sustainability">Sustainability SharePoint
      page</a>.</span>
      `,
      },
      {
        type: "midDesign",
        label: "Mid-Design",
        progress: "Not Started",
        notes: `This section collects more detailed information on specific material 
        selection and management decisions that need to be reviewed and determined by 
        the end of Design Development.  Many of these criteria involve coordination 
        with our construction partners.`,
      },
      {
        type: "lateDesign",
        label: "Late Design/Construction",
        progress: "Not Started",
        notes: `This section collects final design data on material selection 
        criteria that were utilized on the project.  Much of this can be tracked 
        through design using the EC Materials Tracker, and is information that 
        should be incorporated into specifications.  It should be completed at the 
        end of Construction Documents, or early in Construction.`,
      },
    ],
  },
  // {
  //   type: "buildingEnvelope",
  //   label: "Building Envelope",
  //   progress: "Not Started",
  //   subSections: [
  //     { type: "projectInfo", label: "Project Info", progress: "Not Started" },
  //   ],
  // },
];

const EditProject = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [snackbar, showSnackbar] = useState({});
  const [projectsMetaData, setProjectsMetaData] = useState([]);
  const [sectionsWithProgress, setSectionsWithProgress] = useState(sections);
  const [activeSection, setActiveSection] = useState(sections[0]);
  const [newProject, setNewProject] = useState({});
  const [editMode, setEditMode] = useState(location?.state?.isNewProject);
  const [savedProjectPhases, setSavedProjectPhases] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const userRoles = useRoles();

  useEffect(() => {
    (async function () {
      try {
        const res = await api.get("/getProjectMetaData");
        const existingProjectsRes = await api.get("/getProjectNumbers");
        const existingProjects = {};
        existingProjectsRes.data.forEach((_p) => {
          existingProjects[_p.projectNumber] = true;
        });
        setProjectsMetaData(
          res.data
          // res.data.filter((_p) => !(_p.ProjectNo in existingProjects))
        );
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const optns = projectsMetaData.map((_p) => _p.ProjectNo);

  const getAllPhasesByProjectId = async (
    projectId = location?.state?.project?.projectInfo?.projectId
  ) => {
    if (projectId) {
      const res = await api.get("/getAllPhasesByProjectId", {
        params: { projectId },
      });
      setSavedProjectPhases(res.data)
    }
  };

  const getProjectById = async (
    projectId = location?.state?.project?.projectInfo?.projectId
  ) => {
    try {
      setIsLoading(true)

      if (projectId) {
        const res = await api.get("/getProjectById", {
          params: { projectId },
        });
        if (window.location.pathname === "/dashboard/project") {
          navigate(".", {
            state: {
              ...location.state,
              project: res.data,
              isNewProject: !projectId,
            },
          });
        }
      }
    } catch (e) {
      setIsLoading(false)
    }
    setIsLoading(false)

  };

  const getProjectByIdandPhase = async (
    projectId = location?.state?.project?.projectInfo?.projectId,
    phase
  ) => {
    try {
      setIsLoading(true)

      if (projectId) {
        const res = await api.post("/getProjectByIdandPhase", {
          projectId, phase
        });
        if (window.location.pathname === "/dashboard/project") {
          navigate(".", {
            state: {
              ...location.state,
              project: res.data,
              isNewProject: !projectId,
            },
          });
        }
      }
    } catch (e) {
      setIsLoading(false)
    }
    setIsLoading(false)

  };


  const handleUpdateProgress = () => {
    const _project = location?.state?.project || {};
    setSectionsWithProgress((_sections) => {
      const _modifiedSections = [..._sections];
      _modifiedSections.forEach((_section) => {
        if (_section?.type === "projectInfo" && _project.projectNumber) {
          _section.progress = 1;
        } else if (_section?.type === "aia2030") {
          _section.progress = _project?.projectInfo?.aia2030Status;
        } else if (_section?.type === "mep2040") {
          _section.progress = _project?.projectInfo?.mep2040Status;
        } else if (_section?.type === "se2050") {
          _section.progress = _project?.projectInfo?.se2050Status;
        } else if (_section?.type === "aiaMaterialsPledge") {
          _section.progress = _project?.projectInfo?.aiaMaterialsPledgeStatus;
        }
      });
      console.log(
        "_modifiedSections",
        _modifiedSections,
        JSON.stringify(_project)
      );
      return _modifiedSections;
    });
  };

  useEffect(() => {
    console.log("location?.state?.project", location?.state?.project);
    handleUpdateProgress();
    setNewProject(location?.state?.project || {});
  }, [location?.state?.project]);

  useEffect(() => {
    getProjectById();
    getAllPhasesByProjectId();
  }, []);

  const getAllPhaseOfProject = () => ({
    aia2030Phase: location?.state?.project?.aia2030?.reportingPhase,
    mep2040Phase: location?.state?.project?.mep2040?.reportingPhase,
    se2050Phase: location?.state?.project?.se2050?.projectPhaseAtLCA
  })

  const handleSubmitReport = async () => {
    const payload = { projectInfo: newProject.projectInfo };
    const isNewProject = location?.state?.isNewProject;
    if (isNewProject) {
      // if (!newProject.projectInfo.name) {
      //   showSnackbar({
      //     open: true,
      //     message: `Building Name is manditory`,
      //     variant: "error",
      //   });
      //   return;
      // } if (!newProject.projectInfo.number) {
      //   showSnackbar({
      //     open: true,
      //     message: `Sub Project Number is manditory`,
      //     variant: "error",
      //   });
      //   return;
      // }
      payload.isNewProject = true;
    } else if (activeSection?.type === "aia2030") {
      const aia2030 = newProject?.aia2030 || {};
      // payload.aia2030 = newProject?.aia2030 || {}
      if (!aia2030.reportingPhase) {
        showSnackbar({
          open: true,
          message: `Reporting phase is manditory`,
          variant: "error",
        });
        return;
      }

      payload.aia2030 = {};
      payload.aia2030.projectEligibility = {
        projectCompletedDesignPhase: aia2030.projectCompletedDesignPhase,
        projectImpaactsEnergyUse: aia2030.projectImpaactsEnergyUse,
        projectConsistsContigousBuildArea:
          aia2030.projectConsistsContigousBuildArea,
        reportingPhase: aia2030.reportingPhase,
        willThisProjectBeReported: aia2030.willThisProjectBeReported,
        projectType: aia2030.projectType,
        constructionType: aia2030.constructionType,
        useType1PrimaryUse: aia2030.useType1PrimaryUse,
        useType1PrimaryArea: aia2030.useType1PrimaryArea,
        useType2SecondaryUse: aia2030.useType2SecondaryUse,
        useType2SecondaryArea: aia2030.useType2SecondaryArea,
        useType3TertiaryUse: aia2030.useType3TertiaryUse,
        useType3TertiaryArea: aia2030.useType3TertiaryArea,
        useType4TertiaryUse: aia2030.useType4TertiaryUse,
        useType4TertiaryArea: aia2030.useType4TertiaryArea,
        useType5TertiaryUse: aia2030.useType5TertiaryUse,
        useType5TertiaryArea: aia2030.useType5TertiaryArea,
      };

      payload.aia2030.energyModeling = {
        hasEnergyModeling: aia2030.hasEnergyModeling,
        estimatedOccupancyYear: aia2030.estimatedOccupancyYear,
        energyCode: aia2030.energyCode,
        energyModelingParty: aia2030.energyModelingParty,
        energyModelingTool: aia2030.energyModelingTool,
        energyBaselineSource: aia2030.energyBaselineSource,
        targetCertification: aia2030.targetCertification,
        baselineEUI: aia2030.baselineEUI,
        baselineLPD: aia2030.baselineLPD,
        predictedEUI: aia2030.predictedEUI,
        predictedLPD: aia2030.predictedLPD,
        projectPhaseReportingYear: aia2030.projectPhaseReportingYear,
        projectPhaseUpdatedBy: aia2030.projectPhaseUpdatedBy,
      };

      payload.aia2030.embodiedCarbonModeling = {
        hasEmbodiedCarbonModeling: aia2030.hasEmbodiedCarbonModeling,
        carbonModelingTool: aia2030.carbonModelingTool,
        buildingLifetimeModeling: aia2030.buildingLifetimeModeling,
        carbonModelingParty: aia2030.carbonModelingParty,
        predictedEmbodiedCarbon: aia2030.predictedEmbodiedCarbon,
        scope: aia2030.scope,
        lcaStageIncluded: aia2030.lcaStageIncluded,
      };

      payload.projectId = location?.state?.project?.projectInfo?.projectId;
    } else if (activeSection?.type === "mep2040") {
      payload.mep2040 = newProject?.mep2040 || {};
      if (!newProject.mep2040.reportingPhase) {
        showSnackbar({
          open: true,
          message: `Reporting phase is manditory`,
          variant: "error",
        });
        return;
      }
      payload.projectId = location?.state?.project?.projectInfo?.projectId;
    } else if (activeSection?.type === "se2050") {
      payload.se2050 = newProject?.se2050 || {};
      if (!newProject.se2050.projectPhaseAtLCA) {
        showSnackbar({
          open: true,
          message: `Project Phase at LCA is manditory`,
          variant: "error",
        });
        return;
      }
      payload.projectId = location?.state?.project?.projectInfo?.projectId;
    } else if (activeSection?.type === "aiaMaterialsPledge") {
      payload.aiaMaterialsPledge = newProject?.aiaMaterialsPledge || {};
      payload.projectId = location?.state?.project?.projectInfo?.projectId;
    }

    try {
      const res = await api.post("/createOrUpdateProject", payload);
      if (res.status === 200) {
        showSnackbar({
          open: true,
          message: "Successfully Submitted Report",
          variant: "success",
        });
        if (window.location.pathname === "/dashboard/project") {
          navigate(".", { state: { ...location.state, isNewProject: false } });
        }
        if (isNewProject) {
          getProjectById(res.data.projectId)
        }
        else {
          getProjectByIdandPhase(location?.state?.project?.projectInfo?.projectId, {
            aia2030Phase: newProject?.aia2030?.reportingPhase,
            mep2040Phase: newProject?.mep2040?.reportingPhase,
            se2050Phase: newProject?.se2050?.projectPhaseAtLCA
          });
        }
        setEditMode(false);
      }
    } catch (error) {
      console.log("Error", error);
      showSnackbar({
        open: true,
        message: `Failed to Submit Report. ${error?.response?.data?.message || ''}`,
        variant: "error",
      });
    }
  };

  const handleCancel = () => {
    getProjectById();
    setEditMode(false)
  }

  const renderChildren = () => {
    const hasAccess = checkUserHasAccess(activeSection.type);
    if ("subSections" in activeSection) {
      return (
        <>
          {isLoading && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundColor: "#fff8",
                position: 'fixed',
                width: '100%',
                height: '100vh',
                zIndex: 2,
                left: 0,
                top: 0,
                boxSizing: "border-box",
              }}
            >
              <Box sx={{ backgroundColor: '#fff', padding: '5rem', boxShadow: "0px 2px 10px rgba(100,100,100,0.2)", borderRadius: '1rem' }}>
                <CircularProgress
                  sx={{
                    width: "2rem",
                    height: "2rem",
                    //   color: "white",
                    mr: "0.5rem",
                  }}
                />
              </Box>
            </Box>
          )}
          <Card
            item
            xs={12}
            sx={{
              position: "sticky",
              top: "58px",
              backgroundColor: "white",
              zIndex: 2,
              overflow: "auto",
            }}
          >
            <Box
              sx={{
                position: "sticky",
                top: "0",
                backgroundColor: "white",
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "1rem",
                  backgroundColor: "#fff5",
                  width: "100%",
                }}
              >
                <Box
                  component={Typography}
                  variant="h6"
                  sx={{ fontFamily: "Albert sans" }}
                >
                  {activeSection?.label}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {newProject?.[activeSection?.type]?.lastModifiedBy ?
                    <Box>{newProject?.[activeSection?.type]?.lastModifiedBy ? <><em>Last Modified By: </em><strong>{newProject?.projectInfo?.lastModifiedBy}</strong></> : ""}</Box>
                    : <Box>{newProject?.[activeSection?.type]?.createdBy ? <><em>Created By: </em><strong>{newProject?.projectInfo?.createdBy}</strong></> : ""}</Box>
                  }
                  <Box>{newProject?.[activeSection?.type]?.modifiedDate ? <><em>Last Modified: </em><strong>{new Date(newProject?.projectInfo?.modifiedDate).toLocaleString()}</strong></> : ""}</Box>
                </Box>
                {hasAccess ? (
                  editMode ? (
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Button
                        size={"small"}
                        variant="outlined"
                        onClick={handleCancel}
                        sx={{ marginRight: "0.5rem" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        size={"small"}
                        variant="contained"
                        onClick={handleSubmitReport}
                      >
                        Save Report
                      </Button>
                    </Box>
                  ) : (
                    <Button
                      size={"small"}
                      variant="contained"
                      onClick={() => setEditMode(true)}
                    >
                      Edit
                    </Button>
                  )
                ) : (
                  <span></span>
                )}
              </Box>
            </Box>
          </Card >
          {activeSection?.type === "aia2030" ? (
            <AIA2030
              projectInfo={newProject?.projectInfo || {}}
              setProjectInfo={(projectInfo) =>
                setNewProject((_project) => {
                  const _modifiedProject = { ..._project };
                  _modifiedProject.projectInfo = {
                    ...projectInfo,
                  };
                  return _modifiedProject;
                })
              }
              newProject={newProject?.aia2030 || {}}
              setNewProject={(aia2030Data) => {

                setNewProject((_project) => {
                  const _modifiedProject = { ..._project };
                  _modifiedProject.aia2030 = {
                    ..._modifiedProject.aia2030,
                    ...aia2030Data,
                  };
                  return _modifiedProject;
                })
                if (aia2030Data.reportingPhase !== newProject?.aia2030?.reportingPhase && savedProjectPhases?.aia2030?.includes(aia2030Data.reportingPhase)) {
                  getProjectByIdandPhase(location?.state?.project?.projectInfo?.projectId, { ...getAllPhaseOfProject(), aia2030Phase: aia2030Data.reportingPhase })
                }
              }
              }
              subSections={activeSection.subSections}
              optns={optns}
              projectsMetaData={projectsMetaData}
              isNewProject={location?.state?.isNewProject}
              disabledEditing={!hasAccess || !editMode}
            />
          ) : activeSection?.type === "mep2040" ? (
            <MEP2040
              projectInfo={newProject?.projectInfo || {}}
              setProjectInfo={(projectInfo) =>
                setNewProject((_project) => {
                  const _modifiedProject = { ..._project };
                  _modifiedProject.projectInfo = {
                    ..._modifiedProject.projectInfo,
                    ...projectInfo,
                  };
                  return _modifiedProject;
                })
              }
              newProject={newProject?.mep2040 || {}}
              setNewProject={(mep2040Data) => {
                setNewProject((_project) => {
                  const _modifiedProject = { ..._project };
                  _modifiedProject.mep2040 = {
                    ..._modifiedProject.mep2040,
                    ...mep2040Data,
                  };
                  return _modifiedProject;
                })
                if (mep2040Data.reportingPhase !== newProject?.mep2040?.reportingPhase && savedProjectPhases?.mep2040?.includes(mep2040Data.reportingPhase)) {
                  getProjectByIdandPhase(location?.state?.project?.projectInfo?.projectId, { ...getAllPhaseOfProject(), mep2040Phase: mep2040Data.reportingPhase })
                }
              }
              }
              subSections={activeSection.subSections}
              optns={optns}
              projectsMetaData={projectsMetaData}
              isNewProject={location?.state?.isNewProject}
              disabledEditing={!hasAccess || !editMode}
              showSnackbar={showSnackbar}
            />
          ) : activeSection?.type === "se2050" ? (
            <SE2050
              projectInfo={newProject?.projectInfo || {}}
              setProjectInfo={(projectInfo) =>
                setNewProject((_project) => {
                  const _modifiedProject = { ..._project };
                  _modifiedProject.projectInfo = {
                    ..._modifiedProject.projectInfo,
                    ...projectInfo,
                  };
                  return _modifiedProject;
                })
              }
              newProject={newProject?.se2050 || {}}
              setNewProject={(se2050Data) => {
                setNewProject((_project) => {
                  const _modifiedProject = { ..._project };
                  _modifiedProject.se2050 = {
                    ..._modifiedProject.se2050,
                    ...se2050Data,
                  };
                  console.log("se2050", _modifiedProject, se2050Data);
                  return _modifiedProject;
                })
                if (se2050Data.projectPhaseAtLCA !== newProject?.se2050?.projectPhaseAtLCA && savedProjectPhases?.se2050?.includes(se2050Data.projectPhaseAtLCA)) {
                  getProjectByIdandPhase(location?.state?.project?.projectInfo?.projectId, { ...getAllPhaseOfProject(), se2050Phase: se2050Data.projectPhaseAtLCA })
                }
              }
              }
              subSections={activeSection.subSections}
              optns={optns}
              projectsMetaData={projectsMetaData}
              isNewProject={location?.state?.isNewProject}
              disabledEditing={!hasAccess || !editMode}
            />
          ) : activeSection?.type === "aiaMaterialsPledge" ? (
            <AIAMaterialsPledgeSurvey
              projectInfo={newProject?.projectInfo || {}}
              setProjectInfo={(projectInfo) =>
                setNewProject((_project) => {
                  const _modifiedProject = { ..._project };
                  _modifiedProject.projectInfo = {
                    ...projectInfo,
                  };
                  return _modifiedProject;
                })
              }
              newProject={newProject?.aiaMaterialsPledge || {}}
              setNewProject={(aiaMaterialsPledgeData) =>
                setNewProject((_project) => {
                  const _modifiedProject = { ..._project };
                  _modifiedProject.aiaMaterialsPledge = {
                    ..._modifiedProject.aiaMaterialsPledge,
                    ...aiaMaterialsPledgeData,
                  };
                  return _modifiedProject;
                })
              }
              subSections={activeSection.subSections}
              optns={optns}
              projectsMetaData={projectsMetaData}
              isNewProject={location?.state?.isNewProject}
              disabledEditing={!hasAccess || !editMode}
            />
          ) : (
            <></>
          )
          }
        </>
      );
    } else {
      return <></>;
    }
  };

  const checkUserHasAccess = (sectionType) => {
    if (userRoles?.some((role) => role.roleName === "Admin")) return true;

    if (sectionType === "aia2030") {
      return userRoles?.some((role) => role.roleName === "AIA 2030 RW");
    } else if (sectionType === "mep2040") {
      return userRoles?.some((role) => role.roleName === "MEP 2040 RW");
    } else if (sectionType === "se2050") {
      return userRoles?.some((role) => role.roleName === "SE 2050 RW");
    } else if (sectionType === "aiaMaterialsPledge") {
      return userRoles?.some(
        (role) => role.roleName === "AIA Materials Pledge RW"
      );
    } else {
      return false;
    }
  };

  return (
    <>
      <Navbar />
      <Grid container p={2} sx={{}}>
        <Card
          item
          xs={2}
          sx={{ position: "sticky", top: "58px", height: "max-content" }}
        >
          <PageTitle
            component={Typography}
            variant="h6"
            sx={{ margin: "0.5rem" }}
          >
            {location?.state?.isNewProject
              ? "Create project"
              : "Project editor"}
          </PageTitle>
          <Divider />
          <Box
            sx={{
              padding: "1rem",
            }}
          >
            {sectionsWithProgress.map((section, index) => {
              const isActive = activeSection?.label === section.label;
              const isDisabled = false;
              // index !== 0 && sectionsWithProgress[0].progress !== 1;
              return (
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    isDisabled
                      ? `${sectionsWithProgress[0].label} should be completed to edit this section`
                      : section.progress
                  }
                >
                  <span>
                    <Box
                      onClick={() => setActiveSection(section)}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "1rem",
                        margin: "1rem 1rem 0 1rem",
                        border: "1px solid #bbb4",
                        borderRadius: "10px",
                        cursor: "pointer",
                        transition: "all ease 100ms",
                        ...(isActive
                          ? {
                            boxShadow: "0px 2px 10px rgba(100,100,100,0.2)",
                            transform: "scale(1.01)",
                            border: "1px solid #4444",
                          }
                          : {}),
                        "&:last-child": {
                          marginBottom: "1rem",
                        },
                        ...(isDisabled
                          ? {
                            backgroundColor: "#0002",
                            opacity: "0.6",
                            pointerEvents: "none",
                          }
                          : {}),
                        "&:last-child": {
                          marginBottom: "1rem",
                        },
                      }}
                    >
                      <Box>{section.label}</Box>
                      {/* <LinearProgressWithLabel value={section.progress * 100} /> */}
                      {/* <CircularProgressWithLabel
                        value={section.progress * 100}
                      /> */}
                      <Progress
                        progress={section.progress}
                        isDisabled={isDisabled}
                      />
                    </Box>
                  </span>
                </Tooltip>
              );
            })}
          </Box>
        </Card>
        <Grid item xs={10} sx={{ pl: "1rem" }}>
          {renderChildren()}
        </Grid>
      </Grid>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => showSnackbar({})}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={() => showSnackbar({})}
          severity={snackbar.variant}
          sx={{ width: "100%" }}
          elevation={6}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditProject;
