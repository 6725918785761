import React, { useState, useEffect, useMemo, useRef } from "react";
import api from "../../../api/api";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Grid,
  TextField,
  Button,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  InputAdornment,
  Tab,
  Tabs,
  CircularProgress,
  Box,
  Menu,
  MenuItem,
  Dialog,
  Divider,
  Checkbox,
  styled,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Edit,
  EditNote,
  FileDownload,
  Search,
  CloudUpload,
} from "@mui/icons-material";

import useMediaQuery from "@mui/material/useMediaQuery";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useRoles } from "../../Common/Utils";
import ExportProjects from "./ExportProjects";
import { Progress } from "../../Common/Progress/Progress";
import uploadFileToBlob from "../../../services/azureBlobService";

const options = [
  { label: "Edit Project", icon: <Edit sx={{ height: "20px" }} /> },
  //   { label: "Delete Project", icon: <DeleteIcon sx={{ height: "20px" }} /> },
];

const Projects = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [rowsPerPage, setRowPerPage] = useState(10);

  const [page, setpage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [displayExportModal, setDisplayExportModal] = useState(false);

  const open = Boolean(anchorEl);
  const userRoles = useRoles();
  const hasCreateProjectAccess = userRoles?.length;

  const isMobile = useMediaQuery("(max-width:600px)");

  const handleClick = (event, row) => {
    navigate("/dashboard/project", {
      state: { project: { projectInfo: row } },
    });
  };

  const filteredProjects = useMemo(() => {
    if (searchText) {
      return projects.filter((_project) => {
        const {
          projectNumber,
          projectName,
          projectStatus,
          projectType,
          projectEligibility,
        } = _project;
        return [
          projectNumber,
          projectName,
          projectStatus,
          projectType,
          projectEligibility,
        ]
          .join("")
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
    } else {
      return projects;
    }
  }, [searchText, projects, selectedRows]);

  const projectNumberIdMapping = useMemo(() => {
    const _projectNumberIdMapping = {};
    projects.forEach(
      (_project) =>
        (_projectNumberIdMapping[_project.projectNumber] = _project.projectId)
    );
    return _projectNumberIdMapping;
  }, [projects]);

  const handleChangePage = (event, newpage) => {
    setpage(newpage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowPerPage(event.target.value);
    setpage(0);
  };

  const getProjects = async () => {
    setIsLoading(true);
    try {
      const res = await api.get("/getProjects");
      setProjects(res.data);
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  const deleteProject = async (index, projectId) => {
    try {
      const res = await api.delete("/deleteProject", {
        data: {
          projectId,
        },
      });
      setProjects((pre) => {
        return [...pre.slice(0, index), ...pre.slice(index + 1, pre.length)];
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  const columns = [
    { key: "checkbox" },
    { key: "projectNumber", label: "Project No." },
    { key: "name", label: "Building Name" },
    { key: "projectName", label: "Project Name" },
    { key: "aia2030Status", label: "AIA 2030" },
    { key: "mep2040Status", label: "MEP 2040" },
    { key: "se2050Status", label: "SE 2050" },
    { key: "aiaMaterialsPledgeStatus", label: "AIA Materials Pledge" },
  ];

  const handleDisplayExportModal = () => {
    setDisplayExportModal(true);
  };
  const handleSelectAll = () => {
    if (selectedRows.length === filteredProjects.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows([...filteredProjects]);
    }
  }
  const handleProjectSelect = (row) => {
    const { projectId } = row || {}
    const modifiedRows = [...selectedRows];
    const index = modifiedRows.findIndex(
      (row) => row.projectId === projectId
    );
    if (index !== -1) {
      modifiedRows.splice(index, 1);
    } else {
      modifiedRows.push(row);
    }
    setSelectedRows(modifiedRows);
    console.log("modifiedRows", modifiedRows);
  };

  return (
    <>
      <Grid container rowGap={"1rem"}>
        <Grid item lg={10} md={9} sm={8} xs={12}>
          <TextField
            fullWidth
            placeholder="Search Projects"
            size="small"
            id="user-search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              sx: { fontFamily: "Albert Sans" },
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item lg={2} md={3} sm={4} xs={12} pl={isMobile ? 0 : 2}>
          <Tooltip
            title={
              !hasCreateProjectAccess &&
              "You don't have access to create/edit any project"
            }
          >
            <span>
              <Button
                sx={{ height: "100%", width: "calc(100% - 3rem - 1rem)", fontSize: '12px' }}
                variant="contained"
                size="small"
                disabled={!hasCreateProjectAccess}
                onClick={() =>
                  navigate("/dashboard/project", {
                    state: { isNewProject: true, project: undefined },
                  })}
              >
                Create Project
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            title={"Export selected project data to Excel"
            }
          >
            <span>

              <IconButton
                sx={{ height: "100%", width: "3rem", marginLeft: "0.5rem" }}
                color="primary"
                onClick={handleDisplayExportModal}
                disabled={!projects?.length}
              >
                <FileDownload />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      <Table stickyHeader aria-label="sticky table">
        {isLoading && (
          <Box
            sx={{
              width: "100%",
              display: "table-caption",
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem",
              boxSizing: "border-box",
            }}
          >
            <CircularProgress
              sx={{
                width: "2rem",
                height: "2rem",
                //   color: "white",
                mr: "0.5rem",
              }}
            />
          </Box>
        )}
        <TableHead sx={{ "& th": { fontWeight: "600" } }}>
          <TableRow>
            {columns.map((column) => {
              // if (column.key === "checkbox")
              //   return (
              //     <TableCell
              //       key={column.key}
              //       sx={{ padding: "0.5rem 1.5rem", width: "2rem" }}
              //     >
              //       <Checkbox
              //         checked={selectedRows.length === filteredProjects.length}
              //         onChange={handleSelectAll}
              //       />
              //     </TableCell>
              //   );
              // else 
              return <TableCell
                key={column.key}
                sx={{ top: "58px" }}>{column.label}</TableCell>
            }
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredProjects
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow key={row?.projectId}>
                  {columns.map((column) => {
                    // if (column.key === "checkbox")
                    //   return (
                    //     <TableCell
                    //       key={column.key + selectedRows.find(selectedRow => selectedRow.projectId === row.projectId)}
                    //       sx={{ padding: "0.5rem 1.5rem", width: "2rem" }}
                    //     >
                    //       <Checkbox
                    //         checked={selectedRows.find(selectedRow => selectedRow.projectId === row.projectId)}
                    //         onChange={() => handleProjectSelect(row)}
                    //       />
                    //     </TableCell>
                    //   );
                    // else 
                    if (column.key === "projectNumber")
                      return (
                        <TableCell key={column.key}>
                          <Button
                            variant="text"
                            color="primary"
                            onClick={(e) => handleClick(e, row)}
                          >
                            {row[column.key]}
                          </Button>
                        </TableCell>
                      );
                    else if (column.key.endsWith("Status"))
                      return (
                        <TableCell key={column.key}>
                          <Tooltip title={row[column.key]}>
                            <span>
                              <Progress progress={row[column.key]} />
                            </span>
                          </Tooltip>
                        </TableCell>
                      );

                    return <TableCell key={column.key}>{row[column.key]}</TableCell>;
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filteredProjects.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ExportProjects
        projects={projects}
        displayExportModal={displayExportModal}
        setDisplayExportModal={setDisplayExportModal}
        selectedRows={selectedRows}
        projectNumberIdMapping={projectNumberIdMapping}
      />
    </>
  );
};

export default Projects;
