import axios from "axios";
//import AsyncStorage from "@react-native-community/async-storage";
// const API_URL = `http://${window.location.hostname}:8000`;
const API_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: API_URL,

  responseType: "json",
  headers: {
    "Content-Type": "application/json"
  }
});

instance.interceptors.request.use(
  async (config) => {
    const session = localStorage.getItem("session")

    ///const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MzQyNWE0NDI0OTRjNTcyMTdhZmY2NDUiLCJpYXQiOjE2NjUyOTMzMjV9.z8k0lmSrLi03hQmzypC6YiyfLtdbOZywQW9ZTs82m2w"
    if (session) {
      config.headers.Authorization = `Bearer ${JSON.parse(session).token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(response => response,
  error => {
    if (error.response.status === 401 && window.location.pathname !== "/")
      window.location.pathname = "/"
    else
      throw error
  });

export default instance;