import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

const Description = styled(Typography)({
  fontColor: "#444",
  fontSize: "14px",
  whiteSpace: "pre-line",
  textAlign: "start",
});

export const MultiSelect = (props) => {
  const {
    desc = ``,
    onChange,
    options = [],
    selectedOptions = [],
    disabled,
    yesOrNo,
  } = props;

  const handleSelect = (option) => {
    const _modifiedSections = [...selectedOptions];
    const index = _modifiedSections.indexOf(option);
    if (index !== -1) {
      _modifiedSections.splice(index, 1);
    } else {
      _modifiedSections.push(option);
    }
    if (typeof onChange === "function") {
      onChange(_modifiedSections);
    }
  };

  const [disableYesOptions, disableNoOptions] = useMemo(() => {
    if (yesOrNo && selectedOptions?.length) {
      if (selectedOptions.some((_option) => _option?.toLowerCase() === "no")) {
        //disabled Yes stating options, since No stating option is selected
        return [true, false];
      } else {
        //disable No stating options, since Yes stating option is selected
        return [false, true];
      }
    } else {
      return [false, false];
    }
  }, [yesOrNo, selectedOptions]);

  const width = useMemo(() => {
    if (options?.length >= 6) return "16.66%";
    else if (options?.length) return `${100 / options?.length}%`;
    else return "0%";
  }, [options]);

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Description>{desc}</Description>
      <ButtonGroup
        key={selectedOptions.toString()}
        variant="contained"
        color="success"
        aria-label="outlined success button group"
        sx={{
          height: "50px",
          width: "100%",
          "& button": {
            padding: "0.5rem 2rem",
          },
        }}
        disabled={disabled}
      >
        {options.map((option) => (
          <Button
            key={option}
            sx={{
              padding: "1rem 0.5rem !important",
              width,
              backgroundColor: !selectedOptions.includes(option) && "#2222",
              fontSize: "14px !important",
            }}
            color={selectedOptions.includes(option) ? "success" : "grey"}
            onClick={() => handleSelect(option)}
            disabled={
              option?.toLowerCase() === "no"
                ? disableNoOptions
                : disableYesOptions
            }
          >
            {option}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};
