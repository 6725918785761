import React, { useState, useEffect, useMemo } from "react";
import api from "../../../api/api";
import {
  Dialog,
  Select,
  MenuItem,
  FormGroup,
  Grid,
  InputLabel,
  Input,
  TextField,
  Button,
  Box,
  Card,
  Paper,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  InputAdornment,
  Autocomplete,
  Chip,
  FormLabel,
  CircularProgress,
} from "@mui/material";
import styled from "@emotion/styled";
import { Email, EmailOutlined, Person2Outlined } from "@mui/icons-material";

const FormContainer = styled(Grid)(() => ({
  alignItems: "center",
  overflowY: "auto",
  width: "max(400px, 30vw)",
  backgroundColor: "white",
  padding: "1rem 2rem",
}));

const FormRow = styled(Grid)(() => ({
  padding: "1rem 1rem 0rem 1rem",
  "& >label": {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "5px",
    "& > .MuiFormLabel-asterisk": {
      color: "red",
    },
  },
}));

export const UserRoleEditor = (props) => {
  const { openModal, setOpenModal, allRoles, userToEdit } = props;
  const { roles, email, displayName } = userToEdit;
  const [modifiedRoles, setModifiedRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setModifiedRoles(JSON.parse(roles || "[]"));
  }, [roles]);

  const handleUpdateUser = async () => {
    try {
      setIsLoading(true);
      const res = await api.post("/updateUserRoles", {
        userRoles: JSON.stringify(modifiedRoles),
        email,
      });
      setOpenModal(false);
      setModifiedRoles([]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  const isUpdateDisabled = useMemo(() => {
    return false;
    // return !modifiedRoles?.length;
  }, [modifiedRoles]);

  return (
    <Dialog
      open={openModal}
      onClose={() => setOpenModal(false)}
      sx={{
        "& div.MuiPaper-root": {
          maxWidth: "80vw",
          maxHeight: "90vh",
        },
      }}
    >
      <Grid
        py={1}
        component={Typography}
        variant={"subtitle1"}
        textAlign={"center"}
      >
        Edit User Access
      </Grid>
      <Divider />
      <FormContainer container>
        <FormRow item sx={{ display: "flex", alignItems: "center" }}>
          <Person2Outlined sx={{ mr: 0.5 }} /> {displayName}
        </FormRow>
        <FormRow item sx={{ display: "flex", alignItems: "center" }}>
          <EmailOutlined sx={{ mr: 0.5 }} /> {email}
        </FormRow>
        <FormRow item xs={12} sx={{ margin: "1rem 0" }}>
          <Autocomplete
            size={"small"}
            multiple
            fullWidth
            options={allRoles}
            getOptionLabel={(option) => option?.roleName}
            // renderOption={(option) => option.roleName}
            isOptionEqualToValue={(option, value) =>
              option?.roleId === value?.roleId
            }
            value={modifiedRoles}
            onChange={(e, values) => setModifiedRoles(values)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={index}
                  size={"small"}
                  variant="outlined"
                  label={option?.roleName}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} fullWidth size={"small"} />
            )}
          />
        </FormRow>
      </FormContainer>
      <Divider />

      <Box
        p={2}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Box component={"span"}></Box>
        <Box>
          <Button variant={"text"} size={"small"} onClick={handleCancel}>
            Cancel
          </Button>
          <Tooltip
            arrow
            title={
              isUpdateDisabled && "Please select at least one role to save"
            }
            placement="top"
          >
            <span>
              <Button
                sx={{ marginLeft: "0.5rem" }}
                variant={"contained"}
                size={"small"}
                onClick={handleUpdateUser}
                disabled={isUpdateDisabled || isLoading}
              >
                {isLoading && (
                  <CircularProgress
                    size={"small"}
                    sx={{
                      width: "1rem",
                      height: "1rem",
                      color: "white",
                      mr: "0.5rem",
                    }}
                  />
                )}
                Update
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserRoleEditor;
