import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Card } from "../../Common/Card";
import {
  embodiedCarbonFields,
  structuralSystemInformationFields,
  GlobalWarmingPotentialDataFields,
  projectDetailsFields,
  projectPhaseAtLCAField,
} from "../ProjectEditor.config";

import { Question } from "../../Common/Question/Question";
import { Question as QuestionV2 } from "../../Common/QuestionV2/Question";
import { Label } from "../../Common/Label/Label";
import { MultiSelect } from "../../Common/MultiSelect/MultiSelect";
import {
  FormContainer,
  FormRow,
  InputLabel,
  ProjectInfo,
} from "../Common/ProjectInfo";
import { Add, HdrPlus, Info, InfoOutlined, PlusOneOutlined } from "@mui/icons-material";
import Autocomplete from "../../Common/Autocomplete";
import SectionNotes from "../../Common/SectionNotes/SectionNotes";

export const ProjectPhase = (props) => {
  const { newProject, setNewProject, disableEditing } = props;

  const handleChange = (key, value) => {
    const otherFileds = {};

    setNewProject({
      ...newProject,
      ...otherFileds,
      [key]: value,
    });
  };

  return (
    <FormContainer
      container
      style={disableEditing ? { opacity: 0.4, pointerEvents: "none" } : {}}
    >
      {projectPhaseAtLCAField.map((config, index) => (
        <FormRow item xs={12} sm={config?.sm || 4} key={config.key + index}>
          <Box
            component={"span"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <InputLabel required={config.required} htmlFor={config.key}>
              {config.label}
            </InputLabel>
            {config.type === "select" ? (
              <Select
                sx={{ width: "100%", textAlign: "start" }}
                id={config.key}
                value={newProject[config.key]}
                key={newProject[config.key]}
                onChange={(e) => handleChange(config.key, e.target.value)}
                size={"small"}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              >
                {config?.options.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            ) : config.type === "text" ? (
              <TextField
                sx={{ width: "100%" }}
                id={config.key}
                type={config.inputType || config.type}
                value={newProject[config.key]}
                onChange={(e) => handleChange(config.key, e.target.value)}
                placeholder={config.placeholder}
                required={true}
                size={"small"}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              />
            ) : (
              <></>
            )}
          </Box>
        </FormRow>
      ))}
    </FormContainer>
  );

}

const ProjectDetails = (props) => {
  const { newProject, setNewProject, disableEditing } = props;

  const handleChange = (key, value) => {
    const otherFileds = {};

    setNewProject({
      ...newProject,
      ...otherFileds,
      [key]: value,
    });
  };

  return (
    <FormContainer
      container
      style={disableEditing ? { opacity: 0.4, pointerEvents: "none" } : {}}
    >
      {projectDetailsFields.map((config, index) => (
        <FormRow item xs={12} sm={config?.sm || 4} key={config.key + index}>
          <Box
            component={"span"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              ...config?.sx,
            }}
          >
            <InputLabel required={config.required} htmlFor={config.key}>
              {config.label}
            </InputLabel>
            {config.type === "text" ? (
              <TextField
                sx={{ width: "100%" }}
                id={config.key}
                type={config.inputType || config.type}
                value={newProject[config.key]}
                onChange={(e) => handleChange(config.key, e.target.value)}
                placeholder={config.placeholder}
                required={true}
                size={"small"}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              />
            ) : config.type === "select" ? (
              <Select
                sx={{ width: "100%", textAlign: "start" }}
                id={config.key}
                value={newProject[config.key]}
                onChange={(e) => handleChange(config.key, e.target.value)}
                size={"small"}
                displayEmpty
                renderValue={value => value || <Box component={'span'} sx={{ opacity: 0.4 }}>{config.placeholder}</Box>}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              >
                {config?.options.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            ) : config.type === "autocomplete" ? (
              <Autocomplete
                placeholder={config.placeholder}
                freeSolo={config.freeSolo}
                multiple={config.multiple}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
                options={config.options}
                selectedOptions={
                  config.multiple
                    ? JSON.parse(newProject[config.key] || "[]")
                    : newProject[config.key] || ""
                }
                onChange={(selectedOptions) =>
                  handleChange(
                    config.key,
                    config.multiple
                      ? JSON.stringify(selectedOptions)
                      : selectedOptions
                  )
                }
              />
            ) : (
              <></>
            )}
          </Box>
        </FormRow>
      ))
      }
    </FormContainer >
  );
};

const StructuralSystemInformation = (props) => {
  const { newProject, setNewProject, disableEditing } = props;

  const handleChange = (key, value) => {
    const otherFileds = {};

    setNewProject({
      ...newProject,
      ...otherFileds,
      [key]: value,
    });
  };

  return (
    <FormContainer
      container
      style={disableEditing ? { opacity: 0.4, pointerEvents: "none" } : {}}
    >
      {structuralSystemInformationFields.map((config, index) => (
        <FormRow item xs={12} sm={config?.sm || 4} key={config.key + index}>
          <Box
            component={"span"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              ...config?.sx,
            }}
          >
            <InputLabel required={config.required} htmlFor={config.key}>
              {config.label}
            </InputLabel>
            {config.type === "question" ? (
              <Question
                isChecked={newProject[config.key]}
                desc={config.desc}
                onChange={(checked) => handleChange(config.key, checked)}
              />
            ) : config.type === "text" ? (
              <TextField
                sx={{ width: "100%" }}
                id={config.key}
                type={config.inputType || config.type}
                value={newProject[config.key]}
                onChange={(e) => handleChange(config.key, e.target.value)}
                placeholder={config.placeholder}
                required={true}
                size={"small"}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              />
            ) : config.type === "select" ? (
              <Select
                sx={{ width: "100%", textAlign: "start" }}
                id={config.key}
                value={newProject[config.key]}
                onChange={(e) => handleChange(config.key, e.target.value)}
                size={"small"}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              >
                {config?.options.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            ) : config.type === "label" ? (
              <Label desc={config.desc} />
            ) : config.type === "autocomplete" ? (
              <Autocomplete
                placeholder={config.placeholder}
                freeSolo={config.freeSolo}
                multiple={config.multiple}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
                options={config.options}
                selectedOptions={
                  config.multiple
                    ? JSON.parse(newProject[config.key] || "[]")
                    : newProject[config.key] || ""
                }
                onChange={(selectedOptions) =>
                  handleChange(
                    config.key,
                    config.multiple
                      ? JSON.stringify(selectedOptions)
                      : selectedOptions
                  )
                }
              />
            ) : (
              <></>
            )}
          </Box>
        </FormRow>
      ))}
    </FormContainer>
  );
};

const GlobalWarmingPotentialData = (props) => {
  const { newProject, setNewProject, disableEditing } = props;

  const handleChange = (key, value) => {
    const otherFileds = {};
    if (key.startsWith('lcaStagesIncluding') && key !== "lcaStagesIncludingD") {
      otherFileds.totalGWPIncluding =
        Object.values({
          lcaStagesIncludingA1A3: newProject.lcaStagesIncludingA1A3,
          lcaStagesIncludingA4: newProject.lcaStagesIncludingA4,
          lcaStagesIncludingA5: newProject.lcaStagesIncludingA5,
          lcaStagesIncludingB1B5: newProject.lcaStagesIncludingB1B5,
          lcaStagesIncludingC1C4: newProject.lcaStagesIncludingC1C4,
          [key]: value
        }).filter(Boolean).map(Number).reduce((partialSum, a) => partialSum + a, 0)
    } else if (key.startsWith('lcaStagesExcluding') && key !== "lcaStagesExcludingD") {
      otherFileds.totalGWPExcluding =
        Object.values({
          lcaStagesExcludingA1A3: newProject.lcaStagesExcludingA1A3,
          lcaStagesExcludingA4: newProject.lcaStagesExcludingA4,
          lcaStagesExcludingA5: newProject.lcaStagesExcludingA5,
          lcaStagesExcludingB1B5: newProject.lcaStagesExcludingB1B5,
          lcaStagesExcludingC1C4: newProject.lcaStagesExcludingC1C4,
          [key]: value
        }).filter(Boolean).map(Number).reduce((partialSum, a) => partialSum + a, 0)
    }

    setNewProject({
      ...newProject,
      ...otherFileds,
      [key]: value,
    });
  };

  return (
    <FormContainer
      container
      style={disableEditing ? { opacity: 0.4, pointerEvents: "none" } : {}}
    >
      {GlobalWarmingPotentialDataFields.map((config, index) => (
        <FormRow item xs={12} sm={config?.sm || 4} key={config.key + index}>
          <Box
            component={"span"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start", ...(config?.sx || {})
            }}
          >
            <InputLabel required={config.required} htmlFor={config.key}>
              {config.label}
            </InputLabel>
            {config.type === "question" ? (
              <QuestionV2
                isChecked={newProject[config.key]}
                desc={config.desc}
                additionalDesc={config.additionalDesc}
                onChange={(checked) => handleChange(config.key, checked)}
              />
            ) : config.type === "text" ? (
              <TextField
                sx={{ width: "100%", }}
                id={config.key}
                type={config.inputType || config.type}
                value={newProject[config.key]}
                onChange={(e) => handleChange(config.key, e.target.value)}
                placeholder={config.placeholder}
                required={true}
                size={"small"}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              />
            ) : config.type === "label" ? (
              <Label desc={config.desc} additionalDesc={config.additionalDesc} />
            ) : config.type === "select" ? (
              <Select
                sx={{ width: "100%", textAlign: "start" }}
                id={config.key}
                value={newProject[config.key]}
                onChange={(e) => handleChange(config.key, e.target.value)}
                size={"small"}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
              >
                {config?.options.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            ) : config.type === "autocomplete" ? (
              <Autocomplete
                placeholder={config.placeholder}
                freeSolo={config.freeSolo}
                multiple={config.multiple}
                disabled={
                  config.disabled ||
                  (config.disableCondition &&
                    !!newProject[config.disableCondition[0]] ==
                    config.disableCondition[1])
                }
                options={config.options}
                selectedOptions={
                  config.multiple
                    ? JSON.parse(newProject[config.key] || "[]")
                    : newProject[config.key] || ""
                }
                onChange={(selectedOptions) =>
                  handleChange(
                    config.key,
                    config.multiple
                      ? JSON.stringify(selectedOptions)
                      : selectedOptions
                  )
                }
              />
            ) : (
              <></>
            )}
          </Box>
        </FormRow>
      ))}
    </FormContainer>
  );
};

const materials = [
  "Concrete",
  "Steel Reinforcement",
  "Masonry",
  "Steel",
  "Timber",
];
const materialMappings = {
  Concrete: [
    "2500 PSI [Yards]",
    "3000 PSI [Yards]",
    "4000 PSI [Yards]",
    "5000 PSI [Yards]",
    "6000 PSI [Yards]",
    "8000 PSI [Yards]",
    "3000 PSI LW [Yards]",
    "4000 PSI LW [Yards]",
    "5000 PSI LW [Yards]",
  ],
  "Steel Reinforcement": [
    "Rebar [Tons]",
    "Welded Wire Reinforcement [Tons]",
    "Post Tensioning [Tons]",
  ],
  Masonry: [
    "Normal Weight Masonry Block [Tons]",
    "Light Weight Masonry Block [Tons]",
    "Masonry Grout [Cubic Yards]",
    "Mortar [Cubic Yards]",
  ],
  Steel: [
    "Rolled Steel Shapes [Tons]",
    "Plate Steel Fabrications [Tons]",
    "Tube Steel (HSS) [Tons]",
    "Open Web Steel Joists [Tons]",
    "Steel Deck [Tons]",
    "Cold Formed Metal Framing [Tons]",
  ],
  Timber: [
    "Softwood Lumber [Cubic Feet]",
    "Softwood Plywood [Cubic Feet]",
    "Glulam [Cubic Feet]",
    "Cross Laminated Timber [Cubic Feet]",
    "Laminated Veneer Lumber [Cubic Feet]",
    "Wood I Joists [LBS]",
  ],
};

const ChipContent = ({ label, value }) => {
  return (
    <Box display={"flex"} flexDirection={"row"} alignItems={"center"} p={0.4}>
      {label}:
      <Box
        component={"span"}
        sx={{
          ml: 1,
          border: "1px solid #4442",
          borderRadius: "5px",
          backgroundColor: "white",
          padding: "5px",
        }}
      >
        {value || <span style={{ opacity: 0.3 }}>N/A</span>}
      </Box>
    </Box>
  );
};

const StructuralMaterialQuantities = (props) => {
  const { newProject, setNewProject, disableEditing } = props;
  const [material, setMaterial] = useState("");
  const [type, setType] = useState("");
  const [quantity, setQuantity] = useState("");
  const [portlandCementContent, setPortlandCementContent] = useState("");

  const handleAdd = () => {
    const _materialQuantity = [
      ...JSON.parse(newProject?.materialQuantity || "[]"),
      {
        material,
        type,
        quantity,
        portlandCementContent,
      },
    ];
    setNewProject({
      ...newProject,
      materialQuantity: JSON.stringify(_materialQuantity),
    });
    setMaterial("");
    setType("");
    setQuantity("");
    setPortlandCementContent("");
  };

  const handleRemove = (index) => {
    const _materialQuantity = [...JSON.parse(newProject?.materialQuantity || "[]")];
    _materialQuantity.splice(index, 1);
    setNewProject({ ...newProject, materialQuantity: JSON.stringify(_materialQuantity) });
  };
  console.log("mq", newProject.materialQuantity);

  return (
    <FormContainer
      container
      style={disableEditing ? { opacity: 0.4, pointerEvents: "none" } : {}}
    >
      <FormRow item xs={12}>
        <Box
          component={"span"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <InputLabel>
            {
              "Note: This section is not required for submission but can be included when available."
            }
          </InputLabel>
        </Box>
      </FormRow>

      <FormRow item xs={12} sm={6}>
        <Box
          component={"span"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <InputLabel htmlFor={"material"}>{"Material"}</InputLabel>
          <Select
            sx={{ width: "100%", textAlign: "start" }}
            id={"material"}
            value={material}
            onChange={(e) => setMaterial(e.target.value)}
            size={"small"}
          >
            {materials.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </FormRow>
      <FormRow item xs={12} sm={6}>
        <Box
          component={"span"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <InputLabel htmlFor={"type"}>{"Type"}</InputLabel>
          <Select
            sx={{ width: "100%", textAlign: "start" }}
            id={"Type"}
            value={type}
            onChange={(e) => setType(e.target.value)}
            size={"small"}
          >
            {(materialMappings[material] || []).map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </FormRow>

      <FormRow item xs={12} sm={5}>
        <Box
          component={"span"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <InputLabel htmlFor={"Quantity"}>{"Quantity"}</InputLabel>

          <TextField
            sx={{ width: "100%" }}
            id={"Quantity"}
            type={"number"}
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            placeholder={"Units vary - See user guide"}
            size={"small"}
          />
        </Box>
      </FormRow>

      <FormRow item xs={12} sm={5}>
        <Box
          component={"span"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <InputLabel htmlFor={"PortlandCementContent"}>
            {"Portland Cement Content (lbs/cu yd)"}
          </InputLabel>
          <TextField
            sx={{ width: "100%" }}
            id={"PortlandCementContent"}
            type={"number"}
            value={portlandCementContent}
            onChange={(e) => setPortlandCementContent(e.target.value)}
            placeholder={"Concrete materials only"}
            size={"small"}
            disabled={material !== "Concrete"}
          />
        </Box>
      </FormRow>

      <FormRow item xs={12} sm={2}>
        <Button
          sx={{ mt: "30px" }}
          fullWidth
          startIcon={<Add />}
          variant="contained"
          color="info"
          onClick={handleAdd}
          disabled={
            !material ||
            !type ||
            !quantity ||
            (material === "Concrete" && !portlandCementContent)
          }
        >
          Add
        </Button>
      </FormRow>

      <FormRow item xs={12} sm={12} sx={{ my: 2 }}>
        <Box
          component={"span"}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            maxWidth: "100%",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          {JSON.parse(newProject?.materialQuantity || "[]").map(
            (_mq, index) => (
              <Chip
                key={index}
                onDelete={() => handleRemove(index)}
                sx={{ height: "auto", fontSize: "14px", textAlign: "left" }}
                // color="primary"
                // variant="outlined"
                label={
                  <Box display={"flex"} flexDirection={"column"} p={2}>
                    <ChipContent label={"Material"} value={_mq.material} />
                    <ChipContent label={"Type"} value={_mq.type} />
                    <ChipContent label={"Quantity"} value={_mq.quantity} />
                    <ChipContent
                      label={"Portland Cement Content (lbs/cu yd)"}
                      value={_mq.portlandCementContent}
                    />
                  </Box>
                }
              />
            )
          )}
        </Box>
      </FormRow>
    </FormContainer>
  );
};

const COMP_MAPPING = {
  projectInfo: ProjectInfo,
  projectPhase: ProjectPhase,
  projectDetails: ProjectDetails,
  structuralSystemInformation: StructuralSystemInformation,
  globalWarmingPotentialData: GlobalWarmingPotentialData,
  structuralMaterialQuantities: StructuralMaterialQuantities,
};

const SE2050 = (props) => {
  const {
    subSections,
    projectInfo,
    setProjectInfo,
    newProject,
    setNewProject,
    optns,
    projectsMetaData,
    isNewProject,
    disabledEditing,
  } = props;

  const [expandedSections, setExpandedSections] = useState({});

  const handleExpand = (section) => {
    setExpandedSections((_expandedSections) => ({
      ..._expandedSections,
      [section.type]: !_expandedSections[section.type],
    }));
  };

  return subSections?.map((section) => {
    const SubSection = COMP_MAPPING[section.type];
    return (
      <Card sx={{
        mt: 2,
        position: section.type === 'projectPhase' && 'sticky',
        top: section.type === 'projectPhase' && '50px',
        zIndex: section.type === 'projectPhase' && 1,
      }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "1rem",
              backgroundColor: "#fff5",
              width: "100%",
            }}
          >
            <Box
              component={Typography}
              variant="h6"
              sx={{ fontFamily: "Albert sans" }}
            >
              {section?.label}
              <IconButton
                color={"primary"}
                onClick={() => handleExpand(section)}
                disabled={!section.notes}
              >
                {expandedSections[section.type] ? <Info /> : <InfoOutlined />}
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Divider />
        <SectionNotes
          notes={section.notes}
          display={expandedSections[section.type]}
        />

        <Box>
          <SubSection
            projectInfo={projectInfo}
            setProjectInfo={setProjectInfo}
            newProject={newProject}
            setNewProject={setNewProject}
            optns={optns}
            projectsMetaData={projectsMetaData}
            disableEditing={disabledEditing || (section.type !== 'projectInfo' && isNewProject)}
          />
        </Box>
      </Card>
    );
  });
};

export default SE2050;
