import React, { useState, useEffect, useMemo } from "react";
import api from "../../../api/api";
import {
  Dialog,
  Grid,
  Button,
  Box,
  Typography,
  Divider,
  Tooltip,
  CircularProgress,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Table,
  Checkbox,
  Select,
  MenuItem,
  IconButton,
  TextField,
  InputAdornment,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import styled from "@emotion/styled";
import { jsonToExcel } from "../../../utils";
import {
  projectFields,
  embodiedCarbonFields,
  energyModelingFields,
  projectEligibilityFileds,
  MEPenergyModelingFields,
  MEPequipmentEPDInformationFields,
  MEPRefrigerantPipingInformation,
  MEPAdditionalEmbodiedCarbonTrackingQuestions,
  structuralSystemInformationFields,
  GlobalWarmingPotentialDataFields,
  projectDetailsFields,
  AIAMaterialsPledgeSurvey_EarlyDesign,
  AIAMaterialsPledgeSurvey_MidDesign,
  AIAMaterialsPledgeSurvey_LateDesignConstruction,
  projectPhaseFileds,
  projectPhaseAtLCAField,
  SE2050StructuralMaterialQuantities,
} from "../../EditProject/ProjectEditor.config";
import { FilterList, TrendingFlat } from "@mui/icons-material";
import { InputLabel } from "../../EditProject/Common/ProjectInfo";

const FormContainer = styled(Grid)(() => ({
  alignItems: "center",
  overflowY: "auto",
  width: "70vw",
  backgroundColor: "white",
  padding: "1rem 2rem",
}));

const FormRow = styled(Grid)(() => ({
  padding: "1rem 1rem 0rem 1rem",
  "& >label": {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "5px",
    "& > .MuiFormLabel-asterisk": {
      color: "red",
    },
  },
}));

const columns = [
  { key: "#", label: "#", type: "checkbox" },
  { key: "projectNumber", label: "Project No." },
  { key: "name", label: "Building Name" },
  // { key: "aia2030ProjectPhaseReportingYear", label: "AIA 2030" },
  // { key: "mep2040ProjectPhaseReportingYear", label: "MEP 2040" },
  // { key: "se2050ProjectPhaseReportingYear", label: "SE 2050" },
  // { key: "aiaMaterialsPledge", label: "AIA Materials Pledge" },
  { key: 'reportingYear', label: "Reporting Year" }
];

export const ExportProjects = (props) => {
  const {
    displayExportModal,
    setDisplayExportModal,
    // selectedRows,
    projectNumberIdMapping,
    projects
  } = props;


  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [selectedSections, setSelectedSections] = useState({});

  const [rowsPerPage, setRowPerPage] = useState(10);
  const [exportSection, setExportSection] = useState('aia2030');
  const [sortBy, setSortBy] = useState('desc');

  const [page, setpage] = useState(0);
  const [reportinYearFilter, setReportingYearFilter] = useState("");

  const filteredProjects = useMemo(() => {
    if (reportinYearFilter) {
      return projects.filter((_project) => {
        return _project[`${exportSection}ProjectPhaseReportingYear`]
          .toLowerCase()
          .includes(reportinYearFilter.toLowerCase());
      });
    } else {
      return projects;
    }
  }, [projects, exportSection, reportinYearFilter])
  // useEffect(() => {
  //   const _selectedSections = {};
  //   selectedRows.forEach(({ projectId }) => {
  //     _selectedSections[projectId] = columns.reduce(
  //       (prev, col) => ({ ...prev, [col.key]: true }),
  //       {}
  //     );
  //   });
  //   setSelectedSections(_selectedSections);
  // }, [selectedRows]);

  // const handleSelectSection = (projectId, key) => {
  //   setSelectedSections((_selectedSections) => ({
  //     ..._selectedSections,
  //     [projectId]: {
  //       ...(_selectedSections?.[projectId] || {}),
  //       [key]: !_selectedSections?.[projectId]?.[key],
  //     },
  //   }));
  // };

  const handleChangePage = (event, newpage) => {
    setpage(newpage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowPerPage(event.target.value);
    setpage(0);
  };

  const getFormatedDataToExport = (data) => {
    try {
      const _data = JSON.parse(data);
      if (Array.isArray(_data)) return _data.map(
        item =>
          typeof item === 'object'
            ? Object.entries(item).map(([key, value]) => `${key}: ${value || 'N/A'}`).join("\n")
            : item
      ).join(" ,\n");
      else if (typeof _data === "boolean") return _data ? "Yes" : "No";
      else return _data;
    } catch (e) {
      if (typeof data === "boolean") return data ? "Yes" : "No";
      else return data;
    }
  };

  const handleExport = async () => {
    setIsLoading(true);
    console.log("projectNumberIdMapping", projectNumberIdMapping);
    const res = selectedRows.map(({ projectId }) =>
      api.get("/getProjectById", {
        params: { projectId },
      })
    );
    const projectsResponse = await Promise.all(res);
    console.log("projectsResponse", projectsResponse);
    const exportStructure = {
      "Project Info": [],
      // "AIA 2030": [],
      // "MEP 2040": [],
      // "SE 2050": [],
      // "AIA Materials Pledge": [],
    };

    const aia2030Fields = [
      ...projectPhaseFileds,
      ...projectEligibilityFileds,
      ...energyModelingFields.filter((_) => _.label),
      ...embodiedCarbonFields,
    ];

    const mep2040Fields = [
      ...projectPhaseFileds,
      ...MEPenergyModelingFields,
      ...MEPequipmentEPDInformationFields,
      ...MEPRefrigerantPipingInformation,
      ...MEPAdditionalEmbodiedCarbonTrackingQuestions,
    ];

    const se2050Fields = [
      ...projectPhaseAtLCAField,
      ...projectDetailsFields,
      ...structuralSystemInformationFields,
      ...GlobalWarmingPotentialDataFields
        .filter(field => field.label && !['gwp', 'buildingSubstructureComponents', 'buildingSuperstructureComponents'].includes(field.key))
        .map(field => {
          if (field.key?.includes('lcaStagesExcluding')) {
            return { ...field, label: `${field.key.replace('lcaStagesExcluding', '')}-Excluding Biogenic (kgCO2e)` }
          }
          if (field.key?.includes('lcaStagesIncluding')) {
            return { ...field, label: `${field.key.replace('lcaStagesIncluding', '')}-Including Biogenic (kgCO2e)` }
          }
          if (field.key?.includes('totalGWPExcluding')) {
            return { ...field, label: `Total GWP (excluding D)-Excluding Biogenic kgCO2e` }
          }
          if (field.key?.includes('totalGWPIncluding')) {
            return { ...field, label: `Total GWP (excluding D)-Including Biogenic kgCO2e` }
          }
          if (field.key?.includes('buildingSubstructureComps')) {
            return { ...field, label: `Building Substructure Components - ${field.desc}` }
          }
          if (field.key?.includes('buildingSuperstructureComps')) {
            return { ...field, label: `Building Superstructure Components - ${field.desc}` }
          }
          return field
        }),
      ...SE2050StructuralMaterialQuantities
    ];

    const aiaMaterialsPledgeFields = [
      ...AIAMaterialsPledgeSurvey_EarlyDesign,
      ...AIAMaterialsPledgeSurvey_MidDesign,
      ...AIAMaterialsPledgeSurvey_LateDesignConstruction,
    ];

    projectsResponse.forEach((resp) => {
      const projectInfo = resp.data.projectInfo;
      const aia2030 = resp.data.aia2030;
      const mep2040 = resp.data.mep2040;
      const se2050 = resp.data.se2050;
      const aiaMaterialsPledge = resp.data.aiaMaterialsPledge;

      //Project Info Data Loading
      const projectInfoData = { "Project ID": projectInfo.projectId, "Project Number": projectInfo.projectNumber };
      projectFields
        .filter((_) => _.key)
        .forEach((field) => {
          projectInfoData[field.label] = projectInfo[field.key];
        });
      exportStructure["Project Info"].push(projectInfoData);

      // if (selectedSections[projectInfo.projectId].aia2030 && aia2030) {
      if (exportSection === "aia2030" && aia2030) {
        exportStructure["AIA 2030"] = exportStructure["AIA 2030"] || []
        //AIA 2030 Data Loading
        const aia2030Data = { "Project ID": projectInfo.projectId, "Project Number": projectInfo.projectNumber };
        aia2030Fields
          .filter((_) => _.key)
          .forEach((field) => {
            aia2030Data[field.label || field.desc] = getFormatedDataToExport(
              aia2030[field.key]
            );
          });
        delete aia2030Data["Project Eligibility"];
        exportStructure["AIA 2030"].push(aia2030Data);
      }

      // if (selectedSections[projectInfo.projectId].mep2040 && mep2040) {
      if (exportSection === "mep2040" && mep2040) {
        exportStructure["MEP 2040"] = exportStructure["MEP 2040"] || []
        //MEP 2040 Data Loading
        const mep2040Data = { "Project ID": projectInfo.projectId, "Project Number": projectInfo.projectNumber };
        mep2040Fields
          .filter((_) => _.key)
          .forEach((field) => {
            mep2040Data[field.label] = getFormatedDataToExport(
              mep2040[field.key]
            );
          });
        exportStructure["MEP 2040"].push(mep2040Data);
      }

      // if (selectedSections[projectInfo.projectId].se2050 && se2050) {
      if (exportSection === "se2050" && se2050) {
        exportStructure["SE 2050"] = exportStructure["SE 2050"] || []
        //SE 2050 Data Loading
        const se2050Data = { "Project ID": projectInfo.projectId, "Project Number": projectInfo.projectNumber };
        se2050Fields
          .filter((_) => _?.key)
          .forEach((field) => {
            se2050Data[field.label] = getFormatedDataToExport(
              se2050[field.key]
            );
          });
        exportStructure["SE 2050"].push(se2050Data);
      }

      // if (selectedSections[projectInfo.projectId].aiaMaterialsPledge && aiaMaterialsPledge) {
      if (exportSection === "aiaMaterialsPledge" && aiaMaterialsPledge) {
        exportStructure["AIA Materials Pledge"] = exportStructure["AIA Materials Pledge"] || []
        //AIA Materials Pledge Data Loading
        const aiaMaterialsPledgeData = {
          "Project ID": projectInfo.projectId, "Project Number": projectInfo.projectNumber,
        };
        aiaMaterialsPledgeFields
          .filter((_) => _.key)
          .forEach((field) => {
            aiaMaterialsPledgeData[field.label] = getFormatedDataToExport(
              aiaMaterialsPledge[field.key]
            );
          });
        exportStructure["AIA Materials Pledge"].push(aiaMaterialsPledgeData);
      }
    });
    jsonToExcel(
      exportStructure,
      `Export Data - ${new Date().toLocaleString()}.xlsx`
    );
    setIsLoading(false);
    setDisplayExportModal(false);
  };

  const sortedRows = useMemo(() => {
    return filteredProjects?.sort((rowA, rowB) => {
      if (sortBy === 'asc') {
        return rowA[`${exportSection}ProjectPhaseReportingYear`] - rowB[`${exportSection}ProjectPhaseReportingYear`]
      }
      else {
        return rowB[`${exportSection}ProjectPhaseReportingYear`] - rowA[`${exportSection}ProjectPhaseReportingYear`]
      }
    })
  }, [sortBy, exportSection, filteredProjects])

  const handleSelectAll = () => {
    if (selectedRows?.length === filteredProjects.length) {
      setSelectedRows([])
    } else {
      setSelectedRows(filteredProjects)
    }
  }

  const handleSelectRow = (selectedRow) => {
    const rowIndex = selectedRows?.findIndex(_row => _row.projectId === selectedRow.projectId)
    setSelectedRows(_selectedRows => {
      const modifiedRows = [..._selectedRows];
      if (rowIndex != -1) {
        modifiedRows.splice(rowIndex, 1);
      } else {
        modifiedRows.push(selectedRow);
      }
      return modifiedRows
    })
  }

  return (
    <Dialog
      open={displayExportModal}
      onClose={() => setDisplayExportModal(false)}
      sx={{
        "& div.MuiPaper-root": {
          maxWidth: "80vw",
          height: "90vh",
        },
      }}
    >
      <DialogTitle>
        Export Projects

      </DialogTitle>

      <Divider />
      <DialogContent>
        <FormContainer container sx={{ p: '0px  !important' }}>
          <FormRow item xs={6}>

            <InputLabel htmlFor={"export-type"}>
              Export Section
            </InputLabel>
            <Select

              sx={{ textAlign: "start" }}
              id={"export-type"}
              value={exportSection}
              onChange={(e) => {
                setExportSection(e.target.value)
                setSelectedRows([]);
                setReportingYearFilter("");
              }}
              size={"small"}
              fullWidth

            >
              {[{ value: "aia2030", label: "AIA 2030" },
              { value: "mep2040", label: "MEP 2040" },
              { value: "se2050", label: "SE 2050" },
              { value: "aiaMaterialsPledge", label: "AIA Materials Pledge" },].map((option) => (
                <MenuItem value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormRow>
          <FormRow item xs={6}>

            <InputLabel htmlFor={"export-type"}>
              Filter Reporting Year
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter year to filter"
              size="small"
              type="number"
              id="user-search"
              value={reportinYearFilter}
              onChange={(e) => setReportingYearFilter(e.target.value)}
              InputProps={{
                sx: { fontFamily: "Albert Sans" },
                startAdornment: (
                  <InputAdornment position="start">
                    <FilterList />
                  </InputAdornment>
                ),
              }}
            />
          </FormRow>
          <FormRow item xs={12}>
            <Table stickyHeader aria-label="sticky table">

              <TableHead sx={{ "& th": { fontWeight: "600" } }}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.key} width={column.type === "select" ? '300px' : undefined}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {column.type === "checkbox" ? (
                          <Checkbox
                            indeterminate={selectedRows.length > 0 && selectedRows.length !== filteredProjects.length}
                            checked={selectedRows.length === filteredProjects.length}
                            onChange={handleSelectAll}
                          />
                        ) : column.label} {column.key === "reportingYear" && <IconButton aria-label="sort" size="small" onClick={() => setSortBy(sortBy === 'asc' ? 'desc' : 'asc')}>
                          <TrendingFlat sx={{ transition: 'all ease 200ms', rotate: sortBy === 'desc' ? '90deg' : '-90deg' }} />
                        </IconButton>}
                      </Box> </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRows.map((row, index) => {
                  const { projectNumber, projectId, name, ...year } = row;
                  return <TableRow key={projectId}>
                    {columns.map((column) => (
                      <TableCell key={column.key}>
                        {column.key === "projectNumber" ? (
                          projectNumber
                        ) : column.key === "name" ? (
                          name
                        ) : column.type === "checkbox" ? (
                          <Checkbox
                            checked={
                              !!selectedRows.find(_row => _row.projectId === row.projectId)
                              // selectedSections?.[projectId]?.[column.key]
                            }
                            onChange={() =>
                              handleSelectRow(row)
                              // null// handleSelectSection(projectId, column.key
                            }
                          />
                        ) : (
                          year[`${exportSection}ProjectPhaseReportingYear`]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredProjects.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </FormRow>
        </FormContainer>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box
          p={2}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Box component={"span"}></Box>
          <Box>
            <Button
              variant={"text"}
              size={"small"}
              onClick={() => setDisplayExportModal(false)}
            >
              Cancel
            </Button>
            <Tooltip arrow title={""} placement="top">
              <span>
                <Button
                  sx={{ marginLeft: "0.5rem" }}
                  variant={"contained"}
                  size={"small"}
                  onClick={handleExport}
                  disabled={isLoading}
                >
                  {isLoading && (
                    <CircularProgress
                      size={"small"}
                      sx={{
                        width: "1rem",
                        height: "1rem",
                        color: "white",
                        mr: "0.5rem",
                      }}
                    />
                  )}
                  Export
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Box>
      </DialogActions>
    </Dialog >
  );
};

export default ExportProjects;
