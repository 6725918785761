import { Box, Divider } from "@mui/material";

export const SectionNotes = (props) => {
  const { notes, display } = props;
  if (notes && display)
    return (
      <>
        <Box
          sx={{ textAlign: "start", padding: "1rem" }}
          component={"div"}
          dangerouslySetInnerHTML={{ __html: notes }}
        ></Box>
        <Divider />
      </>
    );
  else return <></>;
};

export default SectionNotes;
