import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";

const roleData = [
  {
    roleId: "R32984",
    roleName: "Admin",
    description: "Admin access to all features",
  },
  {
    roleId: "R32983",
    roleName: "Read Only",
    description: "Read Only access to all features",
  },
  {
    roleId: "R32982",
    roleName: "Read Write",
    description: "Read Write access to all features",
  },
];
const columns = [
  { key: "roleId", label: "Role ID" },
  { key: "roleName", label: "Name" },
  { key: "description", label: "Description" },
];
const Roles = () => {
  const [isLoading, setIsLoading] = useState();
  const [rowsPerPage, setRowPerPage] = useState(10);
  const [page, setpage] = useState(0);

  const handleChangePage = (event, newpage) => {
    setpage(newpage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowPerPage(event.target.value);
    setpage(0);
  };
  return (
    <>
      <Table stickyHeader aria-label="sticky table">
        {isLoading && (
          <Box
            sx={{
              width: "100%",
              display: "table-caption",
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem",
              boxSizing: "border-box",
            }}
          >
            <CircularProgress
              sx={{
                width: "2rem",
                height: "2rem",
                //   color: "white",
                mr: "0.5rem",
              }}
            />
          </Box>
        )}
        <TableHead sx={{ "& th": { fontWeight: "600" } }}>
          <TableRow>
            {columns.map((column) => (
              <TableCell sx={{ top: "58px" }}>{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {roleData.map((row, index) => {
            return (
              <TableRow>
                {columns.map((column) => (
                  <TableCell>{row[column.key]}</TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={roleData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default Roles;
