import * as XLSX from "xlsx";

export const jsonToExcel = (jsonObj, fileName) => {
  const wb = XLSX.utils.book_new();
  Object.entries(jsonObj).forEach(([sheetName, jsonData]) => {
    const ws = XLSX.utils.json_to_sheet(jsonData);
    XLSX.utils.book_append_sheet(wb, ws, sheetName || "Data");
  });
  XLSX.writeFile(wb, fileName);
};


export const convertExcelToJson = (fileName, options) => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    let copyOfData = [];
    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: "array",
        bookVBA: true,
      });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, options);
      copyOfData = JSON.parse(JSON.stringify(data));
      resolve(copyOfData);
    };
    reader.readAsArrayBuffer(fileName);
  });
};