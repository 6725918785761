import React, { useState, useEffect, useMemo } from "react";
import api from "../../api/api";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { Box, Divider, Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";
import PageTitle from "../Common/PageTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import { Search } from "@mui/icons-material";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanel, a11yProps } from "../Common/Tab/TabPanel";
import { Card } from "../Common/Card";
import Roles from "../Roles/Roles";
import UserRoles from "../UserRoles/UserRoles";
import ManageMetadata from "../ManageMetadata/ManageMetadata";

const Admin = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Navbar />
      <Grid container p={2}>
        <Card item xs={12}>
          <PageTitle component={Typography} variant="h6">
            Admin Panel Settings
          </PageTitle>
          <Divider />
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="User Roles" {...a11yProps(0)} />
            <Tab label="Manage Metadata" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <UserRoles />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ManageMetadata />
          </TabPanel>
        </Card>
      </Grid >
    </>
  );
};

export default Admin;
